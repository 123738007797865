import { NavLink, useLocation, useNavigate } from "react-router-dom";
import type { INavLInk } from "./INavLink";
import { useAccess } from "../../hooks/useAccess";
import { useMenu } from "../../hooks/useMenu";

export default function TopNav() {
const { isCurrentItem } = useMenu();

  const links: INavLInk[] = [
    { name: 'Sales Portal', to: '/portal' },
    { name: 'Customer Order Offer', to: '/offer' },
    { name: 'Resources', to: 'https://sales-resource.automotix.com/' },
    { name: 'Admin', to: '/admin' },
  ];
 
  const { getPageAccess } = useAccess();

  return (
    <>
      {links
        .filter(({ to }) => getPageAccess(to))      
        .map(({ name, to }) => (
          <NavLink
            key={name + to}
            to={to}
            className={({ isActive }) => `
              ${(isActive || isCurrentItem(to)) ? 'border-primary' : 'border-transparent' } 
              border-b-4 text-gray-900 inline-flex items-center px-1 pt-1 font-medium text-md font-semibold
            `}
          >
            {name}
          </NavLink>
      ))}      
    </>
  );
}
