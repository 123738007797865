import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IApiError } from "../../services/api";
import { partApi } from "../../services/part.api";
import Input, { IInput } from "../form/Input";

const PartTypeSelect : FC<IInput> = ({ ...inputOptions }) => {
  const { data: partTypes, isError, error, isFetching } = partApi.useGetAllTypesQuery({});

  useEffect(() => {
    error && toast.error(`${(error as IApiError)?.status}: ${ERROR_MESSAGES['part-all-type-loading']}`);
  }, [isError]);

  return (
    <Input
      {...inputOptions}
      options={Array.isArray(partTypes)
        ? [ {
              title: 'Choose a part type',
              value: '',
            },
            ...partTypes.map(({ id, name }) => ({
              title: String(name),
              value: id,
            }))
          ]      
        : []
      }
      disabled={isFetching}
    />
  );
}

export default PartTypeSelect;