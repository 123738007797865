import { FC, useEffect, useState } from "react";
import { z } from "zod";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { geoApi } from "../../services/geo.api";
import Input, { IInput, TInputEvent } from "../form/Input";

  // -- Zip Schema
 const numericStr = z
  .string()
  .length(5)
  .regex(/^\d+$/);

export const ZipField: FC<IInput & {
  setCity?: (city: string) => unknown,
  setStateCode?: (stateCode: string) => unknown,
}> = ({
  setCity,
  setStateCode,
  ...props
}) => {
  const [searchState, setSearchState] = useState<string>(String(props.defaultValue || ''));
  const [zipError, setZipError] = useState<string>();
  const [zipDescription, setZipDescription] = useState<string>();
  const [isPending, setIsPending] = useState<boolean>(() => false);
  const [fetchZips] = geoApi.useLazyGetZipQuery();

  const deleteZip = () => {
    props?.handle && props.handle({ target: { value: '' }} as TInputEvent);
    setCity && setCity('');
    setStateCode && setStateCode('');
  }

  // --
  // -- Change the zip state according to the search string with checking
  // --
  useEffect(() => {
    if (searchState)
      if ((new RegExp('^[0-9A-Z]{5,6}$')).test(searchState))
        (async () => {
            setIsPending(() => true);
            try {
              const data = await fetchZips(searchState).unwrap();
              if (!data?.state) throw new Error;

              setZipDescription(() => `${data?.city || ''}, ${data?.stateCode || ''}`);
              setZipError(() => undefined);
              props?.handle && props.handle({ target: { value: data.zip }} as TInputEvent);
              setCity && setCity(data.city);
              setStateCode && setStateCode(data.stateCode);
            } catch (err) {
              setZipDescription(() => undefined);
              setZipError(() => ERROR_MESSAGES['zip-not-found']);
              deleteZip();
            } finally {
              setIsPending(() => false);
            }
          })()
        else {
          setZipError(() => ERROR_MESSAGES['zip-format']);
          setZipDescription(() => undefined);
          deleteZip();
        }
    else {
      setZipDescription(() => undefined);
      setZipError(() => undefined);
      deleteZip();
    }
  }, [searchState]);

  // --
  // -- Change the zip state according to the value external changes
  // --
  useEffect(() => {
    props?.value && setSearchState(() => String(props.value));
  }, [props?.value])

  return (
    <Input
      {...props}
      value={searchState || ''}
      handle={(e) => setSearchState(() => e?.target?.value)}
      disabled={isPending}
      error={zipError}
      description={zipDescription}
      maxLength={6}
    />
  );
};
