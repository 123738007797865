import { FC, Suspense } from 'react';
import Card from '../components/common/card/Card';
import HeadlineSection from '../components/common/headline/HeadlineSection';
import EnterCustoremInfo from '../components/offer/EnterCustomerInfo';
import PartSearhFlows from '../components/offer/PartSearhFlows';
import PartsByVehicle from '../components/offer/PartsByVehicle';
import MainLayout from '../layouts/Main.layout';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { getOfferSearchFlow } from '../store/offerSlice';
import PartsByInterchange from '../components/offer/PartsByInterchange';
import PartsByStock from '../components/offer/PartsByStock';
import VehiclePart from '../components/vehicle/VehiclePart';
import Button from '../components/form/Button';

const OfferPage: FC = () => {
  const searchFlow = useTypedSelector(getOfferSearchFlow());

  return (
    <MainLayout
      pageTitle="Customer Order Offer"
      title="Customer Order Offer"
    >
      <div className="grid gap-10 grid-cols-1 lg:grid-cols-3">
          <section>          
            <HeadlineSection>1. Enter Customer Information</HeadlineSection>
            <Suspense fallback={<>Loading</>}>
              <Card title="Customer Information">
                <EnterCustoremInfo />
              </Card>
            </Suspense>
          </section>
          <section>
            <HeadlineSection>2. Search Vehicle Part</HeadlineSection>
            <PartSearhFlows />
          </section>

          <section>
            <HeadlineSection>&nbsp;</HeadlineSection>
            <Card title="Vehicle Part">
              {searchFlow === 'vehicle' && 
                <VehiclePart />
              }
              {searchFlow !== 'vehicle' && 
                <div className="">No data</div>
              }
            </Card>
            <Card >
            <Button
                onClick={()=>{
                  return window.open('https://vpic.nhtsa.dot.gov/decoder/', '_blank');
                }}
                className="mt-2"
              >
                VIN Decoder
              </Button>
            </Card>
          </section>

          <section className="col-span-1 lg:col-span-3">
            <HeadlineSection>3. Select Vehicle Part</HeadlineSection>
            {searchFlow === 'vehicle' && <PartsByVehicle />}
            {searchFlow === 'interchange' && <PartsByInterchange />}
            {searchFlow === 'stock' && <PartsByStock />}
          </section>  
        </div>      
    </MainLayout>
  );
}
export default OfferPage;
