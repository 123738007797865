import { IDiscount } from '../model/IDiscount';
import { IOfferState } from '../model/IOffer';
import { IOfferPriceInfo } from '../model/IOfferPriceInfo';
import { IQuote } from '../model/IQuote';
import { IReOffer } from '../model/IReOffer';
import api from './api';

// export type TPartsSearchParams  = 
//   & IPageParams
//   & IOfferVehicle
//   & { interchangeNumber?: TOfferInterchange };

export const offerApi = api.injectEndpoints({
  endpoints: (build) => ({
    addOffer: build.mutation<IQuote, IOfferState>({
      query: (body) => ({
          url: 'quotes',          
          method: 'POST',
          body,
        }),
    }),

    reOffer: build.mutation<IQuote, IReOffer>({
      query: ({ id, discountId, comments }) => ({
          url: `quotes/${id}`,          
          method: 'POST',
          body: {
            discountId,
            comments
          },
        }),
    }),

    getOfferPriceInfo: build.query<IOfferPriceInfo, { partNumber: string, zip: string }>({
      query: (params) => ({
          url: 'price',          
          method: 'GET',
          params,
        }),
    }),

    addDiscount: build.mutation<IDiscount, Partial<IDiscount>>({
      query: (body) => ({
          url: 'discounts',          
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['Discounts']
    }),

    updateDiscount: build.mutation<IDiscount, { id: number, deleted?: boolean }>({
      query: ({ id, deleted }) => ({
          url: `discounts/${id}`,          
          method: 'POST',
          body: { deleted: deleted || false },
        }),
        invalidatesTags: ['Discounts']
    }),

    getAllDiscounts: build.query<IDiscount[], {}>({
      query: () => ({
          url: 'discounts/all',          
          method: 'GET',
        }),
        providesTags: ['Discounts'],
    }),

    getActiveDiscounts: build.query<IDiscount[], {}>({
      query: () => ({
          url: 'discounts',          
          method: 'GET',
        }),
        providesTags: ['Discounts'],
    }),
  }),  
});
