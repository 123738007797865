import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IClient } from "../../model/IClient";
import { getErrorFromCatch } from "../../services/api";
import { customerApi } from "../../services/customer.api";
import SelectSearch, { TSelectHandler } from "../common/selectSearch/SelectSearch";
import { IInput } from "../form/Input";

const ClientSearchField: FC<IInput & { selectHandler?: TSelectHandler<IClient> }> = (props) => {
  const [searchState, setSearchState] = useState<string>();
  const [searchStateDebounced] = useDebounce(searchState, 1000);
  const [isPending, setIsPending] = useState<boolean>(() => false);
  const [clients, setCustomers] = useState<IClient[]>([]);
  const [fetchCustomers] = customerApi.useLazySearchCustomerQuery();

  useEffect(() => {
    if (searchStateDebounced !== undefined)
      (async () => {
        setIsPending(() => true);
        try {
          const data = await fetchCustomers(searchStateDebounced).unwrap();
          setCustomers(() => data || []);  
        } catch (err) {          
          toast.error(`${ERROR_MESSAGES['customer-search']} \n${getErrorFromCatch(err)}`, { duration: 8000 });
        } finally {
          setIsPending(() => false);
        }
      })();
  }, [searchStateDebounced]);

  return (
    <SelectSearch
      searchState={searchState}
      setSearchState={setSearchState}
      isPending={isPending}
      searchResultList={clients.map((client) => ({
        ...client,
        value: client.email,
        title: `${client.email} ${client.name}`,
      }))}
      {...props}
    />
  );
};

export default ClientSearchField;
