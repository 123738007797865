import { IClient } from '../model/IClient';
import { IPartQuery } from '../model/IPartQuery';
import api from './api';

export const customerApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchCustomer: build.query<IClient[], string>({
      query: (searchStr) => ({
          url: 'clients/search',
          params: { email: searchStr }
        })
    }),

    lastQueries: build.query<IPartQuery[], string>({
      query: (email) => ({
          url: `clients/lastqueries/${email}`,
        })
    }),
  }),
});
