import { FC } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPart } from "../../model/IPart";
import {  getOfferVehicleParams, setOfferSearchFlow, setOfferVehicleParams } from "../../store/offerSlice";

const SelectVehicleFromPart: FC<{
  title:string, part:IPart
}> = ({
  title, 
  part
}) => {
  const dispatch = useTypedDispatch();
  const offerVehicleState = useTypedSelector(getOfferVehicleParams());

  const selectVehicleFromSearchResults = (part:IPart)=>{
    dispatch(setOfferSearchFlow('vehicle'));
    dispatch(setOfferVehicleParams({
      ...offerVehicleState,
      year:part?.year,
      makeId:part?.carMake?.id?.toString(),
      modelId:part?.carModel?.id?.toString(),
      partTypeId:part?.section?.id?.toString(),
      interchangeNumber:part?.product?.interchangeNumber
    }));
  }
 
  return (   
    <div  className="inline-flex">
      {title}
      <button  onClick={() => selectVehicleFromSearchResults(part)} 
          type="button" 
          title="Search by This Vehicle"
        >
          <div className="max-h-fit ml-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-small rounded-full text-xs p-1 text-center inline-flex items-center me-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <span className="sr-only">Select Vehicle</span>
          </div>
      </button>
  </div>
  );
}
export default SelectVehicleFromPart;
