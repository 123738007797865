import { FC, useEffect, useState } from "react";
import { templateApi } from "../../services/template.api";
import Input, { IInputTemplateHandler, TInputEvent } from "../form/Input";

const SelectTemplate : FC<IInputTemplateHandler> = ({ onSelect, ...inputOptions}) => {
  const { data: templatesList, isFetching } = templateApi.useGetTemplatesQuery({});
  const [selectedTemplate, setSelectedTemplate] = useState<number>(() => 0);

  const handle= (e: TInputEvent) =>setSelectedTemplate(+e?.target?.value || 0);

  useEffect(()=>{
    if (!selectedTemplate){
      setSelectedTemplate(templatesList?.find(t=>t.isDefault)?.id || 0);
    }
  },[templatesList])

  useEffect(()=>{
    if (selectedTemplate){
      const template= templatesList?.find(t=>t.id===selectedTemplate)?.body;
      onSelect(template || "");
    }
  },[selectedTemplate])

  return (
    <Input
      {...inputOptions}
      handle={handle}
      value={selectedTemplate}
      options={Array.isArray(templatesList)
        ? [{
              title: 'Choose template',
              value: '',
            },
            ...templatesList.map(({ id, name, isDefault }) => ({
              title: `${name}${isDefault ? ' (default)' : ''}`,
              value: +id,
            }))
          ]      
        : []
      }
      disabled={isFetching}
    />
  );
}

export default SelectTemplate;
