import React from 'react';
import { Navigate } from 'react-router';
import LoginPage from './app/pages/Login.page';
import OfferPage from './app/pages/Offer.page';
import PortalPage from './app/pages/Portal.page';
import SettingPage from './app/pages/Setting.page';
import TemplatesPage from './app/pages/Template.page';
import UserPage from './app/pages/User.page';
import UserDetailPage from './app/pages/UserDetail.page';
import UserNewPage from './app/pages/UserNew.page';
import UserEditPage from './app/pages/UserEdit.page';
import { pagesAccess } from './accessMap';
import DiscountPage from './app/pages/Discount.page';
import GoalPage from './app/pages/Goal.page';

export interface IMapItem {
  path: string;
  element: React.ReactNode;
  children?: IMapItem[];
}
// export interface IBreadcrumb {
//   name: string,
//   href: string,
// }
// export type IBreadcrumbsMap = {
//   [url in TPageUrl]: IBreadcrumb[]
// };

// const breadCrumbs : IBreadcrumbsMap = {
//   '/managers': [{ }]
// }

export const siteMap = (getPageAccess: (pageUrl: keyof typeof pagesAccess) => boolean): IMapItem[] => [
  {
    path: '/',
    element: <Navigate to='/portal' />,
  },
  {
    path: '/portal',
    element:  (getPageAccess('/portal') ? <PortalPage /> : <LoginPage />),
  },
  {
    path: '/offer',
    element: (getPageAccess('/offer') ? <OfferPage /> : <LoginPage />),
  },

  // -- Settings
  {
    path: '/setting',
    element: (getPageAccess('/setting') ? <SettingPage /> : <LoginPage />),
  },
  {
    path: '/setting/:setting',
    element: (getPageAccess('/setting/:setting') ? <SettingPage /> : <LoginPage />),
  },


  // -- Admin Panel
  {
    path: '/admin',
    element: (getPageAccess('/admin') ? <Navigate to='/admin/managers' /> : <LoginPage />),
  },
  {
    path: '/admin/discounts',
    element: (getPageAccess('/admin/discounts') ? <DiscountPage /> : <LoginPage />),
  },

  {
    path: '/admin/template',
    element: (getPageAccess('/admin/template') ? <TemplatesPage /> : <LoginPage />),
  },
  {
    path: '/admin/template/:id',
    element: (getPageAccess('/admin/template/:id') ? <TemplatesPage /> : <LoginPage />),
  },

  {
    path: '/admin/goal',
    element: (getPageAccess('/admin/goal') ? <GoalPage /> : <LoginPage />),
  },



  // -- Managers (users)
  {
    path: '/admin/managers',
    element: (getPageAccess('/admin/managers') ? <UserPage /> : <Navigate to='/' />),
  },
  {
    path: '/admin/managers/new',
    element: (getPageAccess('/admin/managers/new') ? <UserNewPage /> : <Navigate to='/' />),
  },
  {
    path: '/admin/managers/:userId',
    element: (getPageAccess('/admin/managers/:userId') ? <UserDetailPage /> : <Navigate to='/' />),
  },
  {
    path: '/admin/managers/:userId/edit',
    element: (getPageAccess('/admin/managers/:userId/edit') ? <UserEditPage /> : <Navigate to='/' />),
  },

  {
    path: '*',
    element: <Navigate to='/' />,
  },
];

