import { FC, useEffect, useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPageParams } from "../../services/IApi";
import { quoteApi } from "../../services/quote.api";
import { TQuoteFilterFields, TQuoteFilterState, getQuoteFilterCount, getQuoteFilterParams, getQuotePageParams, setQuoteFilterParams, setQuotePageParams } from "../../store/quoteSlice";
import parseISOToString from "../../utils/parseISOToString";
import ClientSearchField from "../client/ClientSearchField";
import type { IFilter, IFilterState } from "../common/filter/Filter";
import Filter from "../common/filter/Filter";
import Table, { ITableTh } from "../common/table/Table";
import UserSearchField from "../user/UserSearchField";
import WebSiteSearchField from "../website/WebSiteSearchField";
import DateRange from "../date/DateRange";
import { IQuote } from "../../model/IQuote";
import QuoteDetails from "./QuoteDetails";
import { InformationCircleIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import UserMultiSearchField from "../user/UserMultiSearchField";

const Quotes: FC = () => {
  const dispatch = useTypedDispatch();

  // 1. Get quotes
  const quotePageParams = useTypedSelector(getQuotePageParams());
  const quoteFilterParams = useTypedSelector(getQuoteFilterParams());
  const quoteFilterCount = useTypedSelector(getQuoteFilterCount());
  const { data: quotes, isFetching: isPending } = quoteApi.useGetQuotesQuery({ ...quotePageParams, ...quoteFilterParams});

  const [currentQuote, setCurrentQuote] = useState<IQuote>();

  // 2. Filter
  const filterFields: IFilter<Partial<TQuoteFilterState>> = {
    id: {
      label: 'ID'
    },
    websiteList: {
      label: 'Website',
      Component: WebSiteSearchField,
    },
    // salesReps: {
    //   label: 'Sales Rep',
    //   Component: UserSearchField,
    // },
    salesReps: {
      label: 'Sales Rep',
      Component: UserMultiSearchField,
    },
    clientEmail: {
      label: 'Client Email',
      Component: ClientSearchField,
    },
    clientPhone: {
      label: 'Client Phone',
    },
    dateRange: {
      label: 'Date Range',
      Component: DateRange,
    },
  };  
  
  // 3. Table header
  const thead: ITableTh[] = [
    { title:''},
    { title: 'Create Date', sortCol: 'created' },
    { title: 'Sales Rep', sortCol: 'salesRep' },
    { title: 'Client', sortCol: 'client' },
    { title: 'Visited', sortCol: 'isOpened' },
    { title: 'Order#', sortCol: 'orderNumber' },
    { title: 'Vehicle', sortCol: 'carSearch' },
    { title: 'Vehicle Part', sortCol: 'productTag' },
    { title: 'Discount', sortCol: 'discount' },
    { title: 'Expires', sortCol: 'expires' },
  ];

  return (
    <>
      {!!currentQuote && <QuoteDetails
        quote={currentQuote}
        unsetQuote={() => setCurrentQuote(() => undefined)}
      />}    
      <Table
        options={[
          <Filter
            className="w-[550px]"
            fields={filterFields}
            filterState={quoteFilterParams}
            setFilterState={(newState: IFilterState<Record<TQuoteFilterFields, string>>) => { dispatch(setQuoteFilterParams(newState)) }}
            filterCount={quoteFilterCount}
          />
        ]}
        isPending={isPending}
        pageParams={quotePageParams}
        pagable={quotes}
        updatePageParams={(newParams: Partial<IPageParams>) => { dispatch(setQuotePageParams({ ...quotePageParams, ...newParams })); }}
        thead={thead}
        tbody={
          quotes?.content?.length
            ? quotes.content.map((quote) => [
                <InformationCircleIcon
                  className="w-[1.6rem] h-[1.6rem] fill-slate-500 hover:fill-green-700 transition-all duration-200 cursor-pointer"
                  onClick={() => setCurrentQuote(() => quote)}
                />,
                parseISOToString(quote?.created),
                quote?.salesRep?.name,
                quote?.client?.name || quote?.client?.email,
                parseISOToString(quote?.opened || '') || '-',
                quote?.orderNumber || '-',
                `${quote?.carYear} ${quote?.carMakeXkey} ${quote?.carModelXkey}`,
                quote?.productName,
                quote?.discount?(quote?.discountType==='fixed'?new Intl.NumberFormat("en", {style: 'currency', minimumFractionDigits: 2, currency: 'USD'}).format(quote?.discount):new Intl.NumberFormat("en", {style: "percent", minimumFractionDigits: 2}).format(quote?.discount)):"-",
                parseISOToString(quote?.expires || '') || '-'
              ])
            : []
        }
      />
    </>
  );
};

export default Quotes;
