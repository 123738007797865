import { IGeoState } from '../model/IGeo';
import { IZip } from '../model/IZip';
import api from './api';

export const geoApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStates: build.query<IGeoState[], {}>({
      query: () => ({
        url: '/geo/states',
        params: { country: 'USA' }
      }),
    }),
    
    getZip: build.query<IZip, string>({
      query: (zip_code) => ({
        url: '/geo/zip',
        params: { zip_code }
      }),
    }),
  }),
});
