import { z } from "zod";
import { ERROR_MESSAGES } from "../messages/errorMessages";
import { getErrorFromCatch } from "../services/api";
import { templateApi } from "../services/template.api";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { ITemplateRequest, IUpdateTemplateRequest } from "../model/ITemplate";

export function useTemplate() {
  // -- Data checking scheme
  const templateSchema = z.object({
    name: z
      .string()
      .min(1, ERROR_MESSAGES['template-empty-name']),
    subject: z
      .string()
      .min(1, ERROR_MESSAGES['template-empty-subject']),
    body: z
      .string()
      .min(1, ERROR_MESSAGES['template-empty-body']),    
  } as Record<keyof ITemplateRequest, any>);

  // -- Methods
  const [tryToAddTemplate] = templateApi.useAddTemplateMutation();
  const [tryToDeleteTemplate] = templateApi.useDeleteTemplateMutation();
  const [tryToUpdateTemplate] = templateApi.useUpdateTemplateMutation();

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const updateTemplate = async (templateState: IUpdateTemplateRequest): Promise<IZodErrorsState<ITemplateRequest> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(templateSchema, templateState);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToUpdateTemplate(templateState).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const addTemplate = async (templateState: ITemplateRequest): Promise<IZodErrorsState<ITemplateRequest> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(templateSchema, templateState);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToAddTemplate(templateState).unwrap();
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const deleteTemplate = async (id: number): Promise<string | false> => { 
    try {
      await tryToDeleteTemplate(id).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  };

  return {
    addTemplate,
    deleteTemplate,
    updateTemplate,
  };
}