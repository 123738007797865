/* eslint-disable react-hooks/exhaustive-deps */

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { IdentificationIcon, KeyIcon } from '@heroicons/react/24/solid';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../components/common/card/Card';
import Empty from '../components/common/empty/Empty';
import Loading from '../components/common/loading/Loading';
import Tabs from '../components/common/tabs/Tabs';
import Button from '../components/form/Button';
import Checkbox from '../components/form/Checkbox';
import Input from '../components/form/Input';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { useUser } from '../hooks/useUser';
import MainLayout from '../layouts/Main.layout';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import { INFO_MESSAGES } from '../messages/infoMessages';
import { IEditUser } from '../model/IUser';
import { getErrorFromCatch } from '../services/api';
import { userApi } from '../services/user.api';
import { getCurrentUser } from '../store/authSlice';
import isUserHasRole from '../utils/isUserHasRole';

const UserEditPage: FC = () => {
  const { editMainUserInfo, editPassUserInfo } = useUser();
  const navigate = useNavigate();
  const currentUser = useTypedSelector(getCurrentUser());

  // -- Get user Id from URl params
  let { userId } = useParams();

  // -- Load user details using user id
  const { data: user, isFetching, isError, error } = userApi.useGetUserQuery(userId || '');
  
  // -- Api Error handler
  useEffect(() => {
    if (isError && error) {      
      toast.error(`${ERROR_MESSAGES['user-details']} ${getErrorFromCatch(error)}`);
      navigate('/managers');
    }
  }, [isError, error]);
  
  // -- Init form states
  const initMainState: IEditUser = {
    name: '',
    phone: '',
    isActive: false,
    isAdmin: false,
  };
  const initPassState: IEditUser = {
    password: '',
    passwordConfirm: '',
  };

  // -- States
  const [userMainState, setUserMainState] = useState<IEditUser>(initMainState);
  const [userMainErrState, setUserMainErrState] = useState<Partial<Record<keyof IEditUser, string>>>();
  const [isMainPending, setIsMainPending] = useState<boolean>(() => false);

  const [userPassState, setUserPassState] = useState<IEditUser>(initPassState);
  const [userPassErrState, setUserPassErrState] = useState<Partial<Record<keyof IEditUser, string>>>();
  const [isPassPending, setIsPassPending] = useState<boolean>(() => false);

  const [currentTab, setCurrentTab] = useState<'Main Information' | 'Password Information'>('Main Information');

  // -- Fill states, when user is loaded
  useEffect(() => {
    user && setUserMainState(() => ({
      name: user?.name || '',
      phone: user?.phone || '',
      isActive: user?.active || false,
      isAdmin: isUserHasRole('ROLE_ADMIN', user),
    }));
  }, [user]);

  const handleMainEdit = async () => {      
    if (!user?.id) return;
    setIsMainPending(() => true); 
    const result = await editMainUserInfo(user.id, userMainState);   
    setIsMainPending(() => false);

    if (!result) {
      setUserMainErrState(() => undefined);
      return toast.success(INFO_MESSAGES['user-main-updated']);
    }

    return typeof result === 'string'
      ? toast.error(ERROR_MESSAGES['user-main-updated'])        
      : setUserMainErrState(() => result);
  };

  const handlePassEdit = async () => {
    if (!user?.id) return;

    setIsPassPending(() => true);
    const result = await editPassUserInfo(user.id, {
      ...userPassState,
      isActive: !!user?.active,
      name: user?.name || '',
      phone: user?.phone || '',
    });     
    setIsPassPending(() => false);

    if (!result) {
      setUserPassErrState(() => undefined);
      return toast.success(INFO_MESSAGES['user-pass-updated']);
    }

    return typeof result === 'string'
      ? toast.error(`${ERROR_MESSAGES['user-pass-updated']} \n${result}`)
      : setUserPassErrState(() => result);
  }; 
  
  return (
    (!!isFetching && <Loading />)
    || (!user && <Empty />)
    || (
      <MainLayout
        pageTitle={`Edit ${user?.email}`}
        title={`Edit user ${user?.email}`}
        breadcrumbs={[
          { name: 'Users', href: '/managers' },
          { name: `Edit user ${user?.email}` },
        ]}
      >
        <Tabs
          className="relative top-[8px] left-[20px]"
          style={{ width: 'calc(100% - 20px)' }}
          tabs={[
            {
              name: 'Main Information', 
              icon: <IdentificationIcon className='h-6' />,
              current: currentTab === 'Main Information',
              onClick: () => setCurrentTab(() => 'Main Information'),
            },
            {
              name: 'Password Information',
              icon: <KeyIcon className='h-6' />,
              current: currentTab === 'Password Information',
              onClick: () => setCurrentTab(() => 'Password Information'),
            },
          ]}
        />

        <div className="flex flex-wrap gap-12">
          <section className={`
            w-full 
            ${isMainPending || isPassPending ? 'opacity-30' : ''}
          `}> 
            {(currentTab === 'Main Information' && 
              <Card>
                <div className="grid grid-cols-12 gap-x-10 gap-y-4">
                  <Input
                    label="Email"
                    name="email"
                    value={user?.email || ''} 
                    disabled
                    className='col-span-6'
                  />
                  <Checkbox
                    label="Enable"
                    name="isActive"
                    value={userMainState.isActive || false}
                    handleToggle={() => setUserMainState((prevState) => ({
                      ...prevState,
                      isActive: !prevState.isActive,
                    }))}
                    className='col-span-3'
                    disabled={isMainPending}
                    error={userMainErrState?.isActive}
                  />
                  <Checkbox
                    label="Admin"
                    name="isAdmin"
                    value={userMainState.isAdmin || false}
                    handleToggle={() => setUserMainState((prevState) => ({
                      ...prevState,
                      isAdmin: !prevState.isAdmin,
                    }))}
                    className='col-span-3'
                    disabled={isMainPending}
                    error={userMainErrState?.isAdmin}
                  />
                  <Input
                    label="Name"
                    name="name"
                    placeholder='Manager name'
                    value={userMainState.name}
                    handle={(e) => setUserMainState((prevState) => ({
                      ...prevState,
                      name: e ?.target?.value || '',
                    }))}
                    error={userMainErrState?.name}
                    className='col-span-6'
                  />
                  <Input
                    label="Phone"
                    name="phone"
                    placeholder='Manager phone'
                    value={userMainState.phone}
                    handle={(e) => setUserMainState((prevState) => ({
                      ...prevState,
                      phone: e ?.target?.value || '',
                    }))}
                    error={userMainErrState?.phone}
                    className='col-span-6'
                  />
                </div>
                <div className="flex justify-end mt-10 gap-4">
                  <Button
                    onClick={() => setUserMainState(initMainState)}
                    variant="outline"
                    size='small'
                    isNotFullWidth
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleMainEdit}
                    size='small'
                    isNotFullWidth
                  >
                    Apply
                  </Button>
                </div>
              </Card>
            ) || (currentTab === 'Password Information' &&
              <Card>
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    label="Password (required)"
                    name="password"
                    type="password"
                    value={userPassState.password}
                    handle={(e) => setUserPassState((prevState) => ({
                      ...prevState,
                      password: e ?.target?.value || '',
                    }))}
                    error={userPassErrState?.password}
                    disabled={isPassPending}
                  />
                  <Input
                    label="Password Confirm (required)"
                    name="passwordConform"
                    type="password"
                    value={userPassState.passwordConfirm}
                    handle={(e) => setUserPassState((prevState) => ({
                      ...prevState,
                      passwordConfirm: e ?.target?.value || '',
                    }))}
                    error={userPassErrState?.passwordConfirm}
                    disabled={isPassPending}
                  />
                </div>
                <div className="flex justify-end mt-10 gap-4">
                  <Button
                    onClick={() => setUserPassState(initPassState)}
                    variant="outline"
                    size='small'
                    isNotFullWidth
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handlePassEdit}
                    size='small'
                    isNotFullWidth
                  >
                    Apply
                  </Button>               
                </div>
              </Card>
            )}            
          </section>
        </div>
        
        {user?.id && currentUser?.id === user?.id && (
          <div className="flex gap-4 items-center bg-slate-200 text-slate-500 text-sm px-4 py-3 m-2 w-full" role="alert">
            <ExclamationCircleIcon className="w-7 h-7 fill-slate-200 stroke-slate-500" />            
            <p>Attention, you are trying to change information about yourself!</p>
          </div>
        )}
      </MainLayout>
  ));
}
export default UserEditPage;
