import { PropsWithChildren } from "react";

export default function Card({
  children,
  title,
  description,
  style,
  className,
}: PropsWithChildren<{
  title?: string,
  description?: string,
  style?: React.CSSProperties,
  className?: string,
}>) {
  return (
    <div
      style={style || {}}
      className={`
        block rounded-lg bg-white shadow-md w-full m-2 
        ${className || ''}
      `}
    >
      {(!!title || !!description) && (
        <div className="px-4 py-5 sm:px-6 border-gray-300 border-b-[1px]">
          {!!title && <h3 className=" text-lg font-semibold leading-6 text-gray-900">{title}</h3>}
          {!!description && <p className="mt-1 text-sm text-gray-500">{description}</p>}          
        </div>
      )}
      <div className={`
        ${(!!title || !!description) ? 'pt-2' : 'pt-6'}
        px-4 pb-6 sm:px-6
      `}>
        {children}
      </div>
    </div>
  );
}