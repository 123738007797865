import { TUsersFilterState } from '../components/user/UserList';
import { INewUserRequest, IUser, TUserRequest } from '../model/IUser';
import { IPageParams } from './IApi';
import { IPagesResponse } from './IPagesResponse';
import api from './api';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<IUser[], string>({
      query: (searchStr) => ({
          url: 'users/searchlist',
          params: { email: searchStr }
        }),
        providesTags: ['Users'],
    }),

    getUserList: build.query<IPagesResponse<IUser[]>, IPageParams & TUsersFilterState >({
      query: ({ sort, sortDir, ...params }) => ({
          url: 'users',
          params: {
            ...Object.entries(params).reduce((acc, [key, value]) => ({
              ...acc,
              ...(value !== '' ? { [key]: value } : {})
            }), {}),
            ...(sort ? { sort: `${sort},${sortDir}` }  : {}),
          },
        }),
        providesTags: ['Users'],
    }),

    getUser: build.query<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`
        })
    }),

    addUser: build.mutation<IUser, INewUserRequest>({
      query: (body) => ({
          url: 'users',          
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Users']
    }),

    editUser: build.mutation<IUser, TUserRequest>({
      query: ({ id, ...body }) => ({
          url: `users/${id}`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Users']
    }),

    deleteUser: build.mutation<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users']
    }),

  }),
});

// https://sales.automotix.com/api/v1/users?page=0&size=25&sort=registered%2Casc