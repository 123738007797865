import { IStatisticsItem } from "../../model/IStatiscticsItem";
import Card from "../common/card/Card";
import Loading from "../common/loading/Loading";

export default function RenderStatistics ({
  isFetchingStatistics,
  renderList = []
} : {
  isFetchingStatistics: boolean,
  renderList: IStatisticsItem[]
}) {
  return (
    <div className="flex gap-5 flex-wrap justify-evenly md:justify-start">
      {isFetchingStatistics
        ? <Loading />
        : renderList
          ?       
          Object.values(renderList).map(({ title, value, description }) => (
            <Card
              key={title}
              style={{ maxWidth: '300px', borderRadius: '20px'}}
              title={title}
              className="mx-0 my-0"
            >
              <div className="text-xl font-bold mt-2 mb-1">{value}</div>
              {!!description && <div className="text-sm">{description}</div>}
            </Card>
          ))
          : 'There isn\'t any statistics yet.'
      }
    </div>
  );
}