import { FC } from 'react';
import UserList from '../components/user/UserList';
import AdminPanelLayout from '../layouts/AdminPanel.layout';
import MainLayout from '../layouts/Main.layout';

const UserPage: FC = () => (
  <AdminPanelLayout
    pageTitle="Manage Users"
    title="Manage Users"
  >
    <div className="flex flex-wrap gap-12">
      <section className="w-full">        
        <UserList />
      </section>  
    </div>      
  </AdminPanelLayout>
);

export default UserPage;
