import { FC, useState } from 'react';
import Card from '../components/common/card/Card';
import Button from '../components/form/Button';
import Input, { TInputEvent } from '../components/form/Input';
import MainLayout from '../layouts/Main.layout';
import Checkbox from '../components/form/Checkbox';
import { IZodErrorsState } from '../utils/checkFormStateByZod';
import { INewUserRequest } from '../model/IUser';
import { useUser } from '../hooks/useUser';
import { INFO_MESSAGES } from '../messages/infoMessages';
import toast from 'react-hot-toast';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import { useNavigate } from 'react-router-dom';

const UserNewPage: FC = () => {
  const { addUser } = useUser();
  const navigate = useNavigate();
  
  // -- Init form state
  const initState: INewUserRequest = {
    email: '',
    name: '',
    phone: '',
    isActive: true,
    isAdmin: false,
    password: '',
    passwordConfirm: '',
    sendInviteEMail: false,
  };

  // -- States
  const [newUserState, setFormState] = useState<INewUserRequest>(initState);
  const [offerErrState, setOfferErrState] = useState<Partial<IZodErrorsState<INewUserRequest>>>();
  const [isPending, setIsPending] = useState<boolean>(() => false);

  const handle = (key: Partial<keyof typeof initState>) => (e: TInputEvent) => 
    setFormState((prevState) => ({
      ...prevState,
      [key]: e ?.target?.value || '',
    }));
  
  const handleCheckbox = (key: Partial<keyof typeof initState>) => () => 
    setFormState((prevState) => ({
      ...prevState,
      [key]: !!!prevState?.[key],
    }));

  const addManager = () => {
    setIsPending(() => true);

    (async () => {
      const result = await addUser(newUserState);   
      setIsPending(() => false);
      
      if (!result) {
        toast.success(INFO_MESSAGES['user-create']);
        return navigate('/managers');
      }

      return typeof result === 'string'
        ? toast.error(`${ERROR_MESSAGES['user-create-failed']} \n${result}`)        
        : setOfferErrState(() => result);
    })();
  };

  return (
    <MainLayout
      pageTitle="New User"
      title="New User"
      breadcrumbs={[
        { name: 'Users', href: '/managers' },
        { name: `New user` },
      ]}
    >
      <div className="flex flex-wrap gap-12">
        <section className={`
          w-full 
          ${isPending ? 'opacity-30' : ''}
        `}> 
          <Card>
            <div className="grid grid-cols-12 gap-x-10 gap-y-4">
              <Input
                label="Email (required)"
                name="email"
                placeholder='Manager email'
                value={newUserState.email}
                handle={handle('email')}
                className='col-span-6'
                error={offerErrState?.email}
                disabled={isPending}
              />
              <Checkbox
                label="Enable"
                name="active"
                value={newUserState.isActive}
                handleToggle={handleCheckbox('isActive')}
                className='col-span-2'
                disabled={isPending}
              />
              <Checkbox
                label="Admin"
                name="active"
                value={newUserState.isAdmin}
                handleToggle={handleCheckbox('isAdmin')}
                className='col-span-2'
                disabled={isPending}
              />
              <Checkbox
                label="Send invite"
                name="active"
                value={newUserState.sendInviteEMail}
                handleToggle={handleCheckbox('sendInviteEMail')}
                className='col-span-2'
                disabled={isPending}
              />
              <Input
                label="Name"
                name="name"
                placeholder='Manager name'
                value={newUserState.name}
                handle={handle('name')}
                className='col-span-6'
                error={offerErrState?.name}
                disabled={isPending}
              />
              <Input
                label="Phone"
                name="phone"
                placeholder='Manager phone'
                value={newUserState.phone}
                handle={handle('phone')}
                className='col-span-6'
                error={offerErrState?.phone}
                disabled={isPending}
              />
              <Input
                  label='Password (required)'
                  name="password"
                  value={newUserState.password}
                  handle={handle('password')}
                  type="password"
                  className='col-span-6'
                  error={offerErrState?.password}
                  disabled={isPending}
                />
                <Input
                  label='Confirm Password (required)'
                  name="passwordConformed"
                  value={newUserState.passwordConfirm}
                  handle={handle('passwordConfirm')}
                  type="password"
                  className='col-span-6'
                  error={offerErrState?.passwordConfirm}
                  disabled={isPending}
                />
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <Button
                onClick={() => setFormState(() => initState)}
                variant="outline"
                size='small'
                isNotFullWidth
                disabled={isPending}
              >
                Reset
              </Button>
              <Button
                onClick={() => addManager()}
                size='small'
                isNotFullWidth
                disabled={isPending}
              >
                Apply
              </Button>            
            </div>
          </Card>                
        </section>
      </div>      
    </MainLayout>
  );
}

export default UserNewPage;
