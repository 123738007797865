import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IUser } from '../model/IUser';

export type TUserState = IUser | null | undefined;
export interface IauthInitState {
  user: TUserState; // undefined - loading check auth, null - guest, IUser - user
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    user: undefined,
  } as IauthInitState,

  reducers: {
    setUser: (state: IauthInitState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    removeUser: (state: IauthInitState) => {
      state.user = null;
    },
  },
});

// Reducers
export default authSlice.reducer;
export const {
  setUser,
  removeUser,
} = authSlice.actions;

// Selectors
export const getCurrentUser = () => (state: RootState) => state.authSlice.user;
