import { IPart } from '../model/IPart';
import { IPartGroup } from '../model/IPartGroup';
import { IPartType } from '../model/IPartType';
import { IOfferVehicle } from '../store/offerSlice';
import { IPagesResponse } from './IPagesResponse';
import api from './api';

export const partApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTypes: build.query<IPartType[], {}>({
      query: () => ({
          url: 'inventory/parttypes'
        }),
    }),
    
    getParts: build.query<IPagesResponse<IPart[]>, IOfferVehicle & { zip?: string }>({
      query: ({ pageParams, ...params }) => ({
          url: 'inventory',
          params: {
            ...params,
            ...pageParams,
            sort: pageParams?.sort ? `${pageParams.sort},${pageParams.sortDir}` : '',
          },
        }),
    }),
    
    getGroupsPlaneTree: build.query<IPartGroup[], Omit<IOfferVehicle, 'makeId'>>({
      query: ({ modelId, year, partTypeId }) => ({
          url: `inventory/options/${modelId}/${year}/${partTypeId}`
        }),
    }),

  }),
});
