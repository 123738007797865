export default function Logo({
  size = 'medium',
  className,
} : {
  size?: 'small' | 'medium' | 'large',
  className?: string,
}) {
  return (
      <div className={`
        ${className || ''}
        inline-flex relative items-center 
        ${{
          'small': 'w-[172px] h-[33px] gap-2',
          'medium': 'w-[172px] h-[33px] gap-2',
          'large': 'w-[197px] h-[38px] gap-3',
        }[size]}     
      `}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={{ small: 25, medium: 34, large: 40 }[size]}
          height={{ small: 25, medium: 34, large: 40 }[size]}
          viewBox="0 0 40 39"
          fill="none"
        >
          <path d="M23.3831 29.9993C20.211 28.8211 16.9482 28.6398 13.9574 29.4555C11.8728 29.9993 9.87892 31.0869 8.06627 32.4464L6.7974 33.534L6.43488 33.8965L7.25056 34.6215C7.34119 34.6215 7.3412 34.7122 7.43184 34.8028C7.6131 34.9841 7.79438 35.0747 8.06627 35.256L8.61005 35.6185L9.15384 35.1653C9.24448 35.0747 9.42574 34.8934 9.60701 34.8028C11.2384 33.4433 13.051 32.537 14.9543 32.0838C17.4014 31.4494 20.0297 31.54 22.6581 32.537C24.8332 33.3527 29.2742 34.3496 34.6215 32.0838L34.8028 31.9932L35.0747 31.7213C35.0747 31.6307 35.1653 31.6307 35.256 31.54C35.7091 30.9962 36.1623 30.4525 36.5248 29.818L38.3375 27.0084L35.4372 28.6398C31.1775 30.9962 27.2803 31.4494 23.3831 29.9993Z" fill="black"/>
          <path d="M10.9665 20.211C13.4136 19.5766 16.0419 19.6672 18.6703 20.6642C21.5705 21.7517 29.0024 23.2925 37.3405 15.77L38.7 14.4105L38.7906 14.3199L39.1532 13.9574L38.9719 13.5042C38.8813 13.1417 38.7 12.6885 38.5187 12.2354L38.0656 10.9665L37.1592 11.8728C37.0686 12.0541 36.8874 12.1447 36.7061 12.326C32.7183 16.4044 26.5553 20.5735 19.4859 18.0358C16.3138 16.8576 13.0511 16.6763 10.0602 17.492C7.15996 18.3077 4.62225 19.8485 2.26581 22.0236V21.933L0.906325 23.3831C0.906325 23.4738 0.815709 23.4738 0.725077 23.5644L0.453163 23.8363L0.543801 24.1988C0.634433 24.652 0.815692 25.1051 0.906325 25.5583L1.45012 27.0084L2.35645 25.8302C2.44708 25.6489 2.62834 25.5583 2.71897 25.3771C4.62225 23.655 7.25059 21.208 10.9665 20.211Z" fill="black"/>
          <path d="M2.1752 18.5796C4.25974 16.4045 6.61616 15.045 9.15387 14.3199C11.6009 13.6855 14.2293 13.7761 16.8576 14.7731C25.0145 17.7639 32.0839 12.8698 35.1654 10.2414L36.3436 9.06323L36.7967 8.70068L36.4342 8.15691C36.1623 7.88501 35.9811 7.52248 35.7092 7.15995L35.1654 6.43488L34.5309 7.06931C34.4403 7.15995 34.259 7.3412 34.1684 7.43184C30.3618 11.0571 24.4707 14.7731 17.7639 12.2354C14.5918 11.0571 11.3291 10.8759 8.33818 11.6916C6.43491 12.2354 4.62225 13.1417 2.80961 14.3199L0.906323 15.8607C0.725059 16.0419 0.634426 16.1326 0.453162 16.3138L0.271914 16.4951V16.767C0.181281 17.4014 0.18127 18.0358 0.0906378 18.5796L0 20.8454L1.54076 19.214C1.72203 19.0328 1.9033 18.7609 2.1752 18.5796Z" fill="black"/>
          <path d="M26.0115 36.1623L25.8302 36.7061L26.0115 36.1623C25.7396 36.0717 25.5583 35.981 25.2864 35.8904C22.1143 34.7122 18.8515 34.5309 15.8607 35.3466C15.2262 35.5279 14.5918 35.7091 13.9574 35.981L11.7822 36.978L11.2384 37.2499L13.0511 37.8843C13.5949 38.0656 14.1386 38.2468 14.6824 38.4281L14.9543 38.5187L15.2262 38.4281C15.4981 38.3375 15.77 38.1562 16.0419 38.1562C16.2232 38.1562 16.4045 38.0656 16.5857 37.9749C18.7609 37.4312 21.1173 37.4311 23.655 38.2468C23.9269 38.3375 24.1082 38.4281 24.3801 38.5187L24.8333 38.7L25.1052 38.6094C25.7396 38.4281 26.4646 38.2468 27.0084 37.9749L29.5461 37.0686L26.9178 36.4342C26.6459 36.3436 26.2834 36.2529 26.0115 36.1623Z" fill="black"/>
          <path d="M39.8783 17.4014L38.5188 18.7609C38.3375 18.9421 38.1563 19.1234 37.975 19.3047C34.2591 22.8393 28.2773 26.6459 21.5705 24.1082C18.3984 22.9299 15.1356 22.7487 12.1448 23.5644C9.33518 24.3801 6.79745 25.8302 4.53165 28.0054L3.35344 29.2742L2.99091 29.7274L3.35344 30.2712C3.62533 30.6337 3.80661 30.9056 3.98787 31.2681L4.53165 32.0838L5.16608 31.2681C5.25672 31.1775 5.43797 30.9962 5.52861 30.9056C7.70378 28.5492 10.0602 27.099 12.7792 26.374C15.2263 25.7396 17.8546 25.8302 20.4829 26.8271C22.7487 27.6428 25.1052 27.9147 27.4616 27.6429C31.6307 27.1897 35.7998 24.8332 38.8813 22.2049C39.0626 22.0236 39.2438 21.933 39.4251 21.7518L39.697 21.4798V21.208C39.697 20.6642 39.7876 20.0297 39.7876 19.4859L39.8783 17.4014Z" fill="black"/>
          <path d="M5.43794 9.06323L5.16606 8.51943L5.43794 9.06323C5.98174 8.79133 6.61618 8.61005 7.15998 8.42879C9.60705 7.79436 12.2354 7.88499 14.8637 8.88195C16.5857 9.51638 18.3077 9.78827 20.211 9.78827C24.0176 9.78827 27.8241 8.42879 31.3588 5.80046L32.7183 4.71286L33.1714 4.35033L32.3558 3.7159C32.0839 3.444 31.7213 3.17211 31.3588 2.99085L30.815 2.62833L30.3618 2.99085C30.1806 3.08148 30.0899 3.26274 29.9087 3.35337C25.1052 6.97867 20.3017 7.97563 15.77 6.25362C12.5073 5.0754 9.2445 4.8035 5.98174 5.80046C5.70984 5.89109 5.43795 5.98171 5.07542 6.07234L4.89417 6.16298L4.62226 6.43487C4.62226 6.5255 4.53164 6.52551 4.44101 6.61614C4.07848 7.15994 3.6253 7.61311 3.26277 8.1569L1.54076 10.7852L4.35037 9.33511C4.80353 9.33511 5.16605 9.15386 5.43794 9.06323Z" fill="black"/>
          <path d="M12.8698 2.90022L13.1417 2.99086C14.3199 3.44402 16.1326 3.89718 18.3984 3.89718C20.7548 3.89718 23.0206 3.35338 25.2864 2.44706L28.096 1.08757L26.1928 0.453162C25.649 0.271897 25.1052 0.0906323 24.4707 0H24.1988L23.927 0.0906102C23.6551 0.181242 23.3831 0.362529 23.1112 0.453162C20.3923 1.45012 17.6733 1.63138 15.045 0.906323C14.7731 0.815691 14.5012 0.725042 14.2293 0.63441L13.9574 0.543772L13.6855 0.63441C13.1417 0.815674 12.5979 0.99695 12.0541 1.26885L9.96956 2.17517L12.1448 2.80958C12.326 2.71895 12.5979 2.80959 12.8698 2.90022Z" fill="black"/>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={{ small: 130, medium: 130, large: 149 }[size]}
          height={{ small: 22, medium: 22, large: 24 }[size]}
          viewBox="0 0 150 26"
          fill="none"
        >
          <path d="M0.173691 1.72726H5.33278L12.2419 18.5909H12.5146L19.4237 1.72726H24.5828V25H20.5373V9.01136H20.3214L13.8896 24.9318H10.8669L4.43505 8.97726H4.21915V25H0.173691V1.72726Z" fill="black"/>
          <path d="M35.6455 25.3409C33.941 25.3409 32.4637 24.9659 31.2137 24.2159C29.9637 23.4659 28.994 22.4167 28.3046 21.0682C27.6228 19.7197 27.2819 18.1439 27.2819 16.3409C27.2819 14.5379 27.6228 12.9583 28.3046 11.6023C28.994 10.2462 29.9637 9.19317 31.2137 8.44317C32.4637 7.69317 33.941 7.31817 35.6455 7.31817C37.3501 7.31817 38.8273 7.69317 40.0773 8.44317C41.3273 9.19317 42.2932 10.2462 42.9751 11.6023C43.6644 12.9583 44.0091 14.5379 44.0091 16.3409C44.0091 18.1439 43.6644 19.7197 42.9751 21.0682C42.2932 22.4167 41.3273 23.4659 40.0773 24.2159C38.8273 24.9659 37.3501 25.3409 35.6455 25.3409ZM35.6682 22.0454C36.5925 22.0454 37.3652 21.7917 37.9864 21.2841C38.6076 20.7689 39.0697 20.0795 39.3728 19.2159C39.6834 18.3523 39.8387 17.3901 39.8387 16.3295C39.8387 15.2614 39.6834 14.2954 39.3728 13.4318C39.0697 12.5606 38.6076 11.8674 37.9864 11.3523C37.3652 10.8371 36.5925 10.5795 35.6682 10.5795C34.7213 10.5795 33.9334 10.8371 33.3046 11.3523C32.6834 11.8674 32.2175 12.5606 31.9069 13.4318C31.6038 14.2954 31.4523 15.2614 31.4523 16.3295C31.4523 17.3901 31.6038 18.3523 31.9069 19.2159C32.2175 20.0795 32.6834 20.7689 33.3046 21.2841C33.9334 21.7917 34.7213 22.0454 35.6682 22.0454Z" fill="black"/>
          <path d="M55.2565 7.54545V10.7273H45.2224V7.54545H55.2565ZM47.6997 3.36363H51.8134V19.75C51.8134 20.303 51.8967 20.7273 52.0634 21.0227C52.2376 21.3106 52.4649 21.5076 52.7452 21.6136C53.0255 21.7197 53.3361 21.7727 53.677 21.7727C53.9346 21.7727 54.1694 21.7538 54.3815 21.7159C54.6012 21.678 54.7679 21.6439 54.8815 21.6136L55.5747 24.8295C55.355 24.9053 55.0406 24.9886 54.6315 25.0795C54.23 25.1704 53.7376 25.2235 53.1543 25.2386C52.124 25.2689 51.1959 25.1136 50.3702 24.7727C49.5444 24.4242 48.8891 23.8864 48.4043 23.1591C47.927 22.4318 47.6921 21.5227 47.6997 20.4318V3.36363Z" fill="black"/>
          <path d="M57.7369 25V7.54545H61.8505V25H57.7369ZM59.8051 5.06817C59.1535 5.06817 58.5929 4.85226 58.1232 4.42045C57.6535 3.98105 57.4187 3.45454 57.4187 2.8409C57.4187 2.21969 57.6535 1.69317 58.1232 1.26135C58.5929 0.821961 59.1535 0.602264 59.8051 0.602264C60.4641 0.602264 61.0247 0.821961 61.4869 1.26135C61.9566 1.69317 62.1914 2.21969 62.1914 2.8409C62.1914 3.45454 61.9566 3.98105 61.4869 4.42045C61.0247 4.85226 60.4641 5.06817 59.8051 5.06817Z" fill="black"/>
          <path d="M68.4843 7.54545L72.007 13.9886L75.5865 7.54545H79.9388L74.6774 16.2727L80.0297 25H75.7002L72.007 18.7159L68.3479 25H63.9843L69.3024 16.2727L64.1206 7.54545H68.4843Z" fill="black"/>
          <path d="M102.263 9.57954H98.0129C97.8917 8.88257 97.6682 8.26514 97.3424 7.72726C97.0167 7.18181 96.6114 6.71969 96.1265 6.3409C95.6417 5.96211 95.0887 5.67802 94.4674 5.48863C93.8538 5.29166 93.1909 5.19317 92.4788 5.19317C91.2136 5.19317 90.0924 5.51136 89.1152 6.14772C88.1379 6.77651 87.3727 7.70075 86.8197 8.92045C86.2667 10.1326 85.9902 11.6136 85.9902 13.3636C85.9902 15.1439 86.2667 16.6439 86.8197 17.8636C87.3803 19.0758 88.1455 19.9924 89.1152 20.6136C90.0924 21.2273 91.2099 21.5341 92.4674 21.5341C93.1644 21.5341 93.8159 21.4432 94.422 21.2614C95.0356 21.072 95.5849 20.7954 96.0697 20.4318C96.5621 20.0682 96.975 19.6212 97.3083 19.0909C97.6493 18.5606 97.8841 17.9545 98.0129 17.2727L102.263 17.2954C102.104 18.4015 101.759 19.4394 101.229 20.4091C100.706 21.3788 100.02 22.2348 99.172 22.9773C98.3235 23.7121 97.3311 24.2879 96.1947 24.7045C95.0583 25.1136 93.797 25.3182 92.4106 25.3182C90.3652 25.3182 88.5394 24.8447 86.9333 23.8977C85.3273 22.9508 84.0621 21.5833 83.1379 19.7954C82.2137 18.0076 81.7515 15.8636 81.7515 13.3636C81.7515 10.8561 82.2174 8.71211 83.1493 6.93181C84.0811 5.14393 85.35 3.77651 86.9561 2.82954C88.5621 1.88257 90.3803 1.40908 92.4106 1.40908C93.7061 1.40908 94.9106 1.5909 96.0243 1.95454C97.1379 2.31817 98.1303 2.85227 99.0015 3.55681C99.8727 4.25378 100.589 5.10984 101.149 6.12499C101.717 7.13257 102.089 8.28408 102.263 9.57954Z" fill="black"/>
          <path d="M105.164 25V1.72726H113.891C115.679 1.72726 117.179 2.03787 118.391 2.65908C119.611 3.28029 120.531 4.15151 121.152 5.27272C121.781 6.38636 122.096 7.6856 122.096 9.17045C122.096 10.6629 121.777 11.9583 121.141 13.0568C120.512 14.1477 119.584 14.9924 118.357 15.5909C117.13 16.1818 115.622 16.4773 113.834 16.4773H107.618V12.9773H113.266C114.311 12.9773 115.167 12.8333 115.834 12.5454C116.501 12.25 116.993 11.822 117.311 11.2614C117.637 10.6932 117.8 9.9962 117.8 9.17045C117.8 8.34469 117.637 7.64014 117.311 7.05681C116.986 6.4659 116.489 6.01893 115.823 5.7159C115.156 5.40529 114.296 5.24999 113.243 5.24999H109.38V25H105.164ZM117.186 14.4545L122.948 25H118.243L112.584 14.4545H117.186Z" fill="black"/>
          <path d="M125.016 1.72726H130.175L137.084 18.5909H137.357L144.266 1.72726H149.425V25H145.38V9.01136H145.164L138.732 24.9318H135.709L129.278 8.97726H129.062V25H125.016V1.72726Z" fill="black"/>
        </svg>
      </div>

  );
}