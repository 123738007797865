import { FC, Suspense } from 'react';
import HeadlineSection from '../components/common/headline/HeadlineSection';
import Quotes from '../components/quote/Quotes';
import Statistics from '../components/statistics/Statistics';
import MainLayout from '../layouts/Main.layout';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { getCurrentUser } from '../store/authSlice';
import { useUser } from '../hooks/useUser';
import AllStatistics from '../components/statistics/AllStatistics';

const PortalPage: FC = () => { 
  const { hasRoles } = useUser();

  return (
    <MainLayout
      pageTitle="Sales Portal"
      title="Sales Portal"
    >
      <div className="flex flex-wrap gap-12">
        <section className="w-full">
          <HeadlineSection>Dashboard</HeadlineSection>
          <Statistics />
        </section>
        {hasRoles(['ROLE_ADMIN']) && (
          <section className="w-full">
            <HeadlineSection>Overall statistics</HeadlineSection>
            <AllStatistics />
          </section> 
        )}
        <section className="w-full">
          <HeadlineSection>Recent Activity</HeadlineSection>
          <Suspense fallback={<p>Loading...</p>}>
            <Quotes />
          </Suspense>
        </section>  
      </div>      
    </MainLayout>
  );
};

export default PortalPage;
