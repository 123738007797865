import React, { FC } from "react";
import { IApiPagable } from "../../../services/IPagesResponse";
import { TUpdatePageParams } from "./Table";

const TablePagination : FC<{
  updatePageParams: TUpdatePageParams,
  pagable: IApiPagable,
}> = ({
  updatePageParams,
  pagable,
}) => {
  if (pagable.totalPages <= 1) return null;

  const handleToPage = (page: number) => () => { updatePageParams({ page })}

  const renderPageLink = (page: number): React.ReactNode =>
    page < 0 || page >= pagable.totalPages
      ? null
      : (
        <span
          key={`${page}of${pagable.totalPages}`}
          onClick={handleToPage(page)}
          role="button"
          tabIndex={-1}
          className={`
            ${pagable.number === page
              ? 'text-primary border-primary hover:border-primary hover:text-primary'
              : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
            }
            inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium 
          `}
        >
          {page + 1}
        </span>
      );

  const renderSeparator = () => <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>;

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        {!!!pagable?.first && (
          <span
            onClick={handleToPage(pagable.number - 1)}
            role="button"
            tabIndex={-1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <svg className="mr-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clipRule="evenodd" />
            </svg>
            Previous
          </span>
        )}
      </div>

      <div className="hidden md:-mt-px md:flex">
        {pagable.number >= 2 && pagable.totalPages > 3 && renderPageLink(0)}
        {pagable.number > 2 && pagable.totalPages > 4 && renderSeparator()}
        {[...Array(3)].map((el, i) => {
          if (pagable.number - 1 < 0) return renderPageLink(pagable.number + i)
          if (pagable.number + 1 >= pagable.totalPages) return renderPageLink(pagable.number + i - 2)
          return renderPageLink(pagable.number + i - 1)
        })}
        {pagable.number < pagable.totalPages - 3 && pagable.totalPages > 4 && renderSeparator()}
        {pagable.number < pagable.totalPages - 2 && pagable.totalPages > 3 && renderPageLink(pagable.totalPages - 1)}
      </div>

      <div className="-mt-px flex w-0 flex-1 justify-end">
        {!!!pagable?.last && (
          <span
            onClick={handleToPage(pagable.number + 1)}
            role="button"
            tabIndex={-1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >        
            Next
            <svg className="ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
            </svg>
          </span>
        )}
      </div>
    </nav>

  );
}

export default TablePagination;
