import { IAdminSettings, IAdminSettingsRequest, IUpdateAdminSettingsRequest } from '../model/IAdminSettings';
import api from './api';

export const adminSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminSettings: build.query<IAdminSettings[], {}>({
      query: () => ({
          url: 'settings',
        }),
        providesTags: ['AdminSettings'],
    }),

    addAdminSetting: build.mutation<IAdminSettings, IAdminSettingsRequest>({
      query: (body) => ({
          url: 'settings',          
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['AdminSettings']
    }),
    updateAdminSetting: build.mutation<string, IUpdateAdminSettingsRequest>({
      query: ({ name, ...body }) => ({
          url: `settings/${name}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['AdminSettings']
    }),
  }),
});
