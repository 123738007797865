import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { RootState } from '.';
import { IFilterState } from '../components/common/filter/Filter';
import convertDateFromDateStr from '../utils/convertDateFromDateStr';
import convertDateStrFromDate from '../utils/convertDateStrFromDate';

export type TStatisticFilterFields =
| 'salesRepIds'
| 'dateRange'
| 'from'
| 'till';

export type TStatisticFilterState = IFilterState<Record<TStatisticFilterFields, string>>;

export interface IStatisticInitState {
  filterParams: TStatisticFilterState,
}

const initialFilterState: TStatisticFilterState = {
  salesRepIds: '',
  dateRange: '',
  from: '',
  till: '',
};

// --
// -- 'yyyy-MM-dd:yyyy-MM-dd' -> [yyyy-MM-dd, yyyy-MM-dd + 1]
// --
const getFromAndTillByRange = (dateRange: string): [string, string] => {
  const dates = dateRange.split(':');
  const from = dates?.[0] || '';

  let till = '';
  const tillDate = convertDateFromDateStr(dates?.[1]);
  if (tillDate) {
    const tillDateIncrement = addDays(tillDate, 1);    
    till = convertDateStrFromDate(tillDateIncrement);
  }

  return [from, till];
}

const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState: {
    filterParams: initialFilterState,
  } as IStatisticInitState,

  reducers: {
    setStatisticFilterParams: (state: IStatisticInitState, action: PayloadAction<TStatisticFilterState>) => {
      state.filterParams = action.payload;
      // -- Get createdFrom and createdTill from dateRange 
      [state.filterParams.from = '', state.filterParams.till = ''] = getFromAndTillByRange(action.payload.dateRange);
    },
    unsetStatisticFilterParams: (state: IStatisticInitState) => {
      state.filterParams = initialFilterState;
    },
  },
});

// Reducers
export default statisticSlice.reducer;
export const {
  setStatisticFilterParams,
  unsetStatisticFilterParams,
} = statisticSlice.actions;

// Selectors

export const getStatisticFilterParams = () => (state: RootState) => state.statisticSlice.filterParams;

// -- Get active statistic option exclude till and from instead of dataRange
export const getStatisticFilterCount = () => (state: RootState): number => Object.entries(state.statisticSlice.filterParams)
  .filter(([option, value]) => !!value.trim() && !['till', 'from'].includes(option))
  .length;
