import randomIntFromInterval from "../../../utils/randomIntFromInterval";

export default function TableSkeloton({ rows = 3, cols = 10 } : { rows?: number, cols?: number }) {

  const strClassname = '';

  const string = (className = '', leftWidth = randomIntFromInterval(15, 85)): React.ReactNode => 
    <>
      <div style={{ width: `${leftWidth}%` }} className={`${strClassname} ${className}`}> </div>
      <div style={{ width: `${100 - leftWidth}%` }} className={`${strClassname} ${className}`}> </div>
    </>
  

  const string2 = (className = '', leftWidth = randomIntFromInterval(15, 85)): React.ReactNode => 
    <div style={{ width: `${leftWidth}%` }} className={`${strClassname} ${className}`}> </div>
  

  const rowContent = (className = ''): React.ReactNode[] => [...Array(cols)].map(() => [string, string2]?.[Math.round(Math.random())](className));

  return (
    <table className="min-w-full border-separate border-spacing-0 shadow-md">
      <thead>
        <tr>
          {rowContent('h-2 bg-gray-400 rounded-md').map((node) => (
            <th key={Math.random()} className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
              <div className="animate-pulse flex gap-3">
                {node}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(rows)].map(() => (
          <tr key={Math.random()}>
            {rowContent('h-3 bg-gray-200 rounded-md').map((node) => (
            <td key={Math.random()} className="whitespace-pre-line border-b bg-white border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 lg:pl-8">
              <div className="animate-pulse flex gap-3">
                {node}
              </div>
            </td>
          ))}
          </tr>
        ))}
        
      </tbody>
    </table>
  );


  // return (
  //   <div className="w-full h-24 border-0 rounded-md mx-auto">
  //     <div className="flex flex-col gap-1 px-2 animate-pulse items-center h-full">        
  //       {[...Array(3)].map(() => <div key={Math.random()} className="flex gap-3 mx-6 my-2 w-full">{string()}</div>)}
  //     </div>
  //   </div>
  // );
}