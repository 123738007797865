import { FC } from 'react';
import DiscountList from '../components/discount/DiscountList';
import AdminPanelLayout from '../layouts/AdminPanel.layout';

const DiscountPage: FC = () => (
  <AdminPanelLayout
    pageTitle="Discounts"
    title="Discounts"
  >
    <div className="flex flex-wrap gap-12">
      <section className="w-full">
        <DiscountList />
      </section>  
    </div>      
  </AdminPanelLayout>
);

export default DiscountPage;
