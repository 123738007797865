import { HashtagIcon, TruckIcon } from '@heroicons/react/24/solid';
import { FC } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { TPartSearchFlow, getOfferSearchFlow, setOfferSearchFlow } from "../../store/offerSlice";
import Card from "../common/card/Card";
import Tabs, { ITab } from "../common/tabs/Tabs";
import EnterInterchange from "./EnterInterchange";
import EnterVehicleInfo from "./EnterVehicleInfo";
import EnterStock from './EnterStock';



const PartSearhFlows : FC  = () => {
  const dispatch = useTypedDispatch();
  const searchFlow = useTypedSelector(getOfferSearchFlow());

  const handleChangeSearchFlow = (flow: TPartSearchFlow) => () => dispatch(setOfferSearchFlow(flow));

  const tabs: ITab[] = [
    {
      name: 'by VEHICLE',
      onClick: handleChangeSearchFlow('vehicle'),
      icon: <TruckIcon className="h-6 w-6" />,
      current: searchFlow === 'vehicle',
    },
    {
      name: 'IC',
      onClick: handleChangeSearchFlow('interchange'),
      icon: <HashtagIcon className="h-6 w-6" />,
      current: searchFlow === 'interchange',
    },
    {
      name: 'Stock',
      onClick: handleChangeSearchFlow('stock'),
      icon: <HashtagIcon className="h-6 w-6" />,
      current: searchFlow === 'stock',
    },
  ];

  return (
    <>
      <Tabs
        tabs={tabs}
        className="relative top-[8px] mx-4"
      />
      {searchFlow === 'vehicle' && (
          <Card>            
            <EnterVehicleInfo />
          </Card>)
      || searchFlow === 'interchange' && (
          <Card>            
            <EnterInterchange />
          </Card>)            
      || searchFlow === 'stock' && (
          <Card>            
            <EnterStock />
          </Card>)   
      }
    </>
  );

}

export default PartSearhFlows;