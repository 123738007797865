
import { statisticsApi } from "../../services/statistics.api";
import RenderStatistics from "./RenderStatistics";


// --
// -- This Component is abailable only for admin roles
// --
export default function AllStatistics() {
  const { data: statistics, isFetching: isFetchingStatistics } = statisticsApi.useGetAllStatisticsQuery({});

  return <RenderStatistics
    isFetchingStatistics={isFetchingStatistics}
    renderList={statistics || []}    
  />;
}
