import { useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOfferInterchange, getOfferInterchange, setOfferIntechange } from "../../store/offerSlice";
import Button from "../form/Button";
import Input from "../form/Input";

// --
// -- Fill the interchange number of global offer state
// --
export default function EnterInterchange() {
  const dispatch = useTypedDispatch();

  const offerInterchange = useTypedSelector(getOfferInterchange());
  const [localInterchangeNumber, setLocalInterchangeNumber] = 
    useState<IOfferInterchange['interchangeNumber']>(() => offerInterchange.interchangeNumber || '');

  return (
    <div className="grid gap-2">
      <Input
        label="Interchange Number"
        name="interchangeNumber"
        value={localInterchangeNumber}
        handle={(e) => setLocalInterchangeNumber(() => e.target.value)}
      />
      <Button
        className="mt-2"
        onClick={() => 
          dispatch(setOfferIntechange({
            ...offerInterchange,
            interchangeNumber: localInterchangeNumber,
          }))
        }
      >
        Search
      </Button>
    </div>
  );
}
