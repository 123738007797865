import { CheckCircleIcon, EyeIcon, EyeSlashIcon, SquaresPlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useConfirm } from "../../hooks/useConfirm";
import { useDiscount } from "../../hooks/useDiscount";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { INFO_MESSAGES } from "../../messages/infoMessages";
import { getErrorFromCatch } from "../../services/api";
import { offerApi } from "../../services/offer.api";
import { IZodErrorsState } from "../../utils/checkFormStateByZod";
import Table, { ITableTh } from "../common/table/Table";
import Button from "../form/Button";
import Input from "../form/Input";

export interface IDiscountRow {
  amount: number,
  limitAmount: number,
};

const DiscountList : FC = () => {
  const {
    data: discounts,
    isFetching: isDiscountFetching,
    isError: isDiscountError,
    error
  } = offerApi.useGetAllDiscountsQuery({});

  const { addDiscount, updateDiscount } = useDiscount();
  const confirm = useConfirm();

  const [newDiscountsState, setNewDiscountsState] = useState<IDiscountRow[]>([]);
  const [newDiscountsStateErrors, setNewDiscountsStateErrors] = useState<IZodErrorsState<IDiscountRow>[]>([]);

  // -- Table header
  const thead: ITableTh[] = [
    { title: 'Amount' /*, sortCol: 'amount'*/ },
    { title: 'Limit' /*, sortCol: 'limitAmount'*/ },
    { title: '' },
  ];

  // -- Api Error handler
  useEffect(() => {
    isDiscountError && error && toast.error(`${ERROR_MESSAGES['discount-list']} ${getErrorFromCatch(error)}`);
  }, [isDiscountError]);  

  const setErrorByIndx = (indx: number, error: IZodErrorsState<IDiscountRow>) => setNewDiscountsStateErrors((prevErrState) => {
    const tmpState = [...prevErrState];  
    tmpState[indx] = error;
    return [...tmpState];
  })

  const unsetErrorByIndx = (indx: number) => setNewDiscountsStateErrors((prevErrState) => [
    ...prevErrState.slice(0, indx),
    ...prevErrState.slice(indx + 1),
  ]);

  // -- Handlers
  const handleDeleteDiscount = (id: number) => () =>   
    confirm.on({
      text: 'Please confirm that you want to delete this discount',
      action: async () => { 
        const result = await updateDiscount({ id, deleted: true });
        if (!result)
          return toast.success(INFO_MESSAGES['discount-delete']);
  
        return typeof result === 'string'
          ? toast.error(`${ERROR_MESSAGES['discount-delete']} \n${result}`)              
          : false;
      }
    });  

  const handleRecoverDiscount = (id: number) => async () => {   
    const result = await updateDiscount({ id, deleted: false });
  
    return !!result && typeof result === 'string'
      ? toast.error(`${ERROR_MESSAGES['discount-recover']} \n${result}`)              
      : toast.success(INFO_MESSAGES['discount-recover']);
  }  

  const handleDeleteNewDiscount = (indx: number) => () => {
    setNewDiscountsState((prevState) => [
      ...prevState.slice(0, indx),
      ...prevState.slice(indx + 1),
    ]);
  }

  const handleAddDiscountRow = () => () => 
    setNewDiscountsState((prevState) => [
      { limitAmount: 0, amount: 0 },
      ...prevState,      
    ]);

  const handleDiscount = (indx: number, key: keyof IDiscountRow, value: number ) =>
    !Number.isNaN(+value) && setNewDiscountsState((prevState) => {
      const tmpState = [...prevState];
      tmpState?.[indx]?.[key] !== undefined && (tmpState[indx][key] = value);
      return [...tmpState]
    });

  // -- Add discount
  const onAddDiscount = async (indx: number) => {
    if (!newDiscountsState?.[indx]) return false;
    
    unsetErrorByIndx(indx);

    const result = await addDiscount(newDiscountsState?.[indx]);  

    // -- Success 
    if (!result) {
      handleDeleteNewDiscount(indx)();
      return toast.success(INFO_MESSAGES['discount-add']);
    }

    // -- Front cheking error
    if (typeof result !== 'string' && result) {
      setErrorByIndx(indx, result);
      return toast.error(`${ERROR_MESSAGES['discount-add']}`);
    }
    
    // -- API error
    return toast.error(`${ERROR_MESSAGES['discount-add']} \n${result}`);
  }

  // -- Add discount with checking for the limitAmount
  const handleAddDiscount = (indx: number) => () => {
    if (!newDiscountsState?.[indx]) return false;

    // -- Check if there is a discount with such limitAmount
    if ((discounts || []).find(({ limitAmount }) => limitAmount === newDiscountsState[indx]?.limitAmount))
      confirm.on({
        text: `There is a discount with such limitAmount (${newDiscountsState[indx]?.limitAmount})`,
        action: async () => { 
          await onAddDiscount(indx);          
        },
        applyText: 'Add anyway'
      });
    else
      (async () => onAddDiscount(indx))();
  }

  return (
    <>
      <div className="flex justify-end">
        <Button
          onClick={handleAddDiscountRow()}
          size="small"
          isNotFullWidth
          className="flex gap-2 items-center"
        >
          <SquaresPlusIcon className="w-[1.1rem] h-[1.1rem]" /> Add discount
        </Button>
      </div>

      {/* <div className="flex justify-end">
        <div> */}
          {!!newDiscountsState?.length && newDiscountsState.map(({ limitAmount, amount }, indx) => (
            <div
              key={`newDiscount${indx}`}
              className="grid gap-6 grid-cols-12 py-2 pl-4"
            >
              <Input
                name={`amount${indx}`}
                label="Amount"
                placeholder="amount"
                handle={(e) => handleDiscount(indx, 'amount', +e?.target?.value)}
                value={amount}
                error={newDiscountsStateErrors?.[indx]?.['amount']}
                size="small"
                className="col-span-5"
              />
              <Input
                name={`limitAmount${indx}`}
                label="Limit"
                placeholder="limitAmount"
                handle={(e) => handleDiscount(indx, 'limitAmount', +e?.target?.value)}
                value={limitAmount}
                error={newDiscountsStateErrors?.[indx]?.['limitAmount']}
                size="small"
                className="col-span-6"
              />
              <div className="flex gap-2 col-span-1 pt-8">
                <CheckCircleIcon
                  className="w-[1.5rem] h-[1.5rem] fill-slate-500 hover:fill-green-700 transition-all duration-200 cursor-pointer"
                  onClick={handleAddDiscount(indx)}
                />
                <TrashIcon
                  className="w-[1.5rem] h-[1.5rem] fill-slate-500 hover:fill-red-700 transition-all duration-200 cursor-pointer"
                  onClick={handleDeleteNewDiscount(indx)}
                />
              </div>
            </div>
          ))}
        {/* </div>
      </div> */}

      <Table
        thead={thead}
        isPending={isDiscountFetching}
        tbody={!discounts?.length
          ? []
          : discounts.map(({ id, amount, limitAmount, deleted }) => [
              <div className={deleted ? `opacity-40` : ''}>
                {amount}
              </div>,
              <div className={deleted ? `opacity-40` : ''}>
                {limitAmount}
              </div>,                
              <div className="flex gap-2 justify-end pr-4">
                {deleted
                  ? 
                    <EyeIcon
                      className="w-[1.3rem] h-[1.3rem] fill-slate-500 hover:fill-green-500 transition-all duration-200 cursor-pointer"
                      onClick={handleRecoverDiscount(id)}                  
                    />
                  : 
                    <EyeSlashIcon
                      className="w-[1.3rem] h-[1.3rem] fill-slate-500 hover:fill-red-500 transition-all duration-200 cursor-pointer"
                      onClick={handleDeleteDiscount(id)}
                    />                
                }                
              </div>,
          ])
        } 
      />
    </>
  );
};

export default DiscountList;
