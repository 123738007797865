import { FC, ReactNode } from "react";
import { IPart } from "../../model/IPart";
import { IPageParams } from "../../services/IApi";
import { IApiPagable, IPagesResponse } from "../../services/IPagesResponse";
import Table, { ITableTh, TUpdatePageParams } from "../common/table/Table";
import OfferForm from "./OfferForm";
import { IOfferCustomer } from "../../store/offerSlice";

const PartListForOffer : FC<{
  isPending: boolean,
  pageParams: IPageParams,
  pagable?: IApiPagable,
  updatePageParams? : TUpdatePageParams,
  thead?: ITableTh[],
  tbody?: ReactNode[][],
  currentPart?: IPart,
  customer: IOfferCustomer,
  unsetPart: () => unknown,
}> = ({
  isPending,
  pageParams,
  pagable,
  updatePageParams,
  thead,
  tbody,
  currentPart,
  customer,
  unsetPart,
}) => (
  <>
    {!!currentPart && <OfferForm
      part={currentPart}
      customer={customer}
      unsetPart={unsetPart}
    />}    
    <Table
      isPending={isPending}
      pageParams={pageParams}
      pagable={pagable}
      updatePageParams={updatePageParams}
      thead={thead || [
        { title: '#Part', sortCol: 'product.partNumber' },
        { title: '#Interchange', sortCol: 'product.interchangeNumber' },
        { title: '#Stock', sortCol: 'product.stockNumber' },
        { title: 'Year' },
        { title: 'Make' },
        { title: 'Model' },
        { title: 'Part Type', sortCol: 'product.name' },
        { title: 'Mileage', sortCol: 'product.condition' },
        { title: 'Location' },
        // If there isn't any distance we need remove sorting function
        // { title: 'Distance', ...(tbody?.[0]?.[8] !== '-' ? { sortCol: 'distance' } : {})},
        { title: 'Distance', sortCol: 'distance'  },
        { title: 'Local Discount' },
        { title: 'Price', sortCol: 'product.price' },
        { title: 'Shipping' },
        // { title: 'Total' },
      ]}
      tbody={tbody}
    />
  </> 
);

export default PartListForOffer;
