import { FC, useEffect } from "react";
import Input, { IInput, TInputEvent, TInputEventHandle } from "../form/Input";
import { vehicleApi } from "../../services/vehicle.api";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IApiError } from "../../services/api";

const VehicleModelSelect : FC<IInput & { year: string, makeId: string }> = ({
  year,
  value,
  handle,
  makeId,
  ...inputOptions
}) => {
  const { data: models, isError, error, isFetching, isSuccess } = vehicleApi.useGetModelsQuery({ year, makeId }); 

  useEffect(() => {
    error && toast.error(`${(error as IApiError)?.status}: ${ERROR_MESSAGES['vehicle-makes-loading']}`);
  }, [isError]);

  useEffect(() => {
    isSuccess
    && Array.isArray(models)
    && value
    && !models.find(({ id }) => +id === +value)
    && handle
    && handle({ target: { value: '' }} as TInputEvent);
  }, [models]);

  return (
    <Input
      {...inputOptions}
      options={Array.isArray(models)
        ? [ {
              title: 'Choose a model',
              value: '',
            },
            ...models.map(({ id, name }) => ({
              title: name,
              value: +id,
            }))
          ]        
        : []
      }
      disabled={isFetching}
      value={isFetching ? '' : value}
      handle={handle}
    />
  );
}

export default VehicleModelSelect;
