import { ITemplate, ITemplateRequest, IUpdateTemplateRequest } from '../model/ITemplate';
import api from './api';

export const templateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<ITemplate[], {}>({
      query: () => ({
          url: 'template',
        }),
        providesTags: ['Templates'],
    }),

    addTemplate: build.mutation<ITemplate, ITemplateRequest>({
      query: (body) => ({
          url: 'template',          
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Templates']
    }),

    deleteTemplate: build.mutation<string, number | string>({
      query: (id) => ({
          url: `template/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Templates']
    }),

    updateTemplate: build.mutation<string, IUpdateTemplateRequest>({
      query: ({ id, ...body }) => ({
          url: `template/${id}`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Templates']
    }),

    // getUserList: build.query<IPagesResponse<IUser[]>, IPageParams & TUsersFilterState >({
    //   query: ({ sort, sortDir, ...params }) => ({
    //       url: 'users',
    //       params: {
    //         ...params,
    //         sort: sort ? `${sort},${sortDir}` : '',
    //       },
    //     }),
    //     providesTags: ['Users'],
    // }),

    // getUser: build.query<IUser, string >({
    //   query: (userId) => ({
    //       url: `users/${userId}`
    //     })
    // }),

    // addUser: build.mutation<IUser, INewUserRequest>({
    //   query: (body) => ({
    //       url: 'users',          
    //       method: 'POST',
    //       body,
    //     }),
    //     invalidatesTags: ['Users']
    // }),

    // editUser: build.mutation<IUser, TUserRequest>({
    //   query: ({ id, ...body }) => ({
    //       url: `users/${id}`,
    //       method: 'PATCH',
    //       body,
    //     }),
    //     invalidatesTags: ['Users']
    // }),

    // deleteUser: build.mutation<IUser, string>({
    //   query: (userId) => ({
    //       url: `users/${userId}`,
    //       method: 'DELETE',
    //     }),
    //     invalidatesTags: ['Users']
    // }),

  }),
});
