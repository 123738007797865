import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { CONFIG } from '../config/config';
import { IPageParams } from '../services/IApi';

export interface IOfferCustomer {
  email: string,
  name: string,
  zip: string,
  phone: string,
  city: string,
  address: string,
  state: string,
  company: string,
  website: string,
}
export interface IOfferVehicle {
  year?: string,
  makeId?: string,
  modelId?: string,
  partTypeId?: string,
  interchangeNumber?: string,
  stockNumber?: string,
  pageParams: IPageParams,
}
export interface IOfferInterchange {
  interchangeNumber?: string,
  pageParams: IPageParams,
}
export interface IOfferStockNumber {
  stockNumber?: string,
  pageParams: IPageParams,
}
export type TOfferInterchange = string;
export type TPartSearchFlow = 'vehicle' | 'interchange' | 'stock';
export interface IOfferInitState {
  customer: IOfferCustomer,
  vehicle: IOfferVehicle,
  searchFlow: TPartSearchFlow,
  interchange: IOfferInterchange,
  stock:IOfferStockNumber
}

export const defaultOfferPartsPageParams: IPageParams = {
  page: 0,
  size: CONFIG.offer.partsPerPage,
  q: '',
  sort: '',
  sortDir: 'asc',
};

const offerSlice = createSlice({
  name: 'offerSlice',
  
  initialState: {
    customer: {
      email: '',
      name: '',
      zip: '',
      phone: '',
      city: '',
      address: '',
      state: '',
      company: '',
      website: '',
    },
    vehicle: {
      year: undefined,
      makeId: undefined,
      modelId: undefined,
      partTypeId: undefined,
      interchangeNumber: undefined,
      pageParams: defaultOfferPartsPageParams,
    },
    interchange: {
      interchangeNumber: undefined,
      pageParams: defaultOfferPartsPageParams,
    },
    stock: {
      stockNumber: undefined,
      pageParams: defaultOfferPartsPageParams,
    },
    searchFlow: 'vehicle',
  } as IOfferInitState,

  reducers: {
    setOfferCustomerParams: (state: IOfferInitState, action: PayloadAction<IOfferCustomer>) => {
      state.customer = action.payload;
    },
      setOfferCustomerWebsite: (state: IOfferInitState, action: PayloadAction<string>) => {        
        state.customer.website = action.payload;
      },
      setOfferCustomerZip: (state: IOfferInitState, action: PayloadAction<string>) => {        
        state.customer.zip = action.payload;
      },
    setOfferVehicleParams: (state: IOfferInitState, action: PayloadAction<IOfferVehicle>) => {
      state.vehicle = action.payload;
    },
    setOfferVehiclePageParams: (state: IOfferInitState, action: PayloadAction<IPageParams>) => {
      state.vehicle.pageParams = action.payload;
    },
    setOfferIntechange: (state: IOfferInitState, action: PayloadAction<IOfferInterchange>) => {
      state.interchange = action.payload;
    },
    setOfferStockNumber: (state: IOfferInitState, action: PayloadAction<IOfferStockNumber>) => {
      state.stock = action.payload;
    },
    setOfferSearchFlow: (state: IOfferInitState, action: PayloadAction<TPartSearchFlow>) => {
      state.searchFlow = action.payload;
    },
  },
});

// Reducers
export default offerSlice.reducer;
export const {
  setOfferCustomerParams,
    setOfferCustomerWebsite,
    setOfferCustomerZip,
  setOfferVehicleParams,
  setOfferIntechange,
  setOfferStockNumber,
  setOfferSearchFlow,

} = offerSlice.actions;

// Selectors
export const getOfferVehicleFlowPageParams = () => (state: RootState) => state.offerSlice.vehicle.pageParams;
export const getOfferCustomerParams = () => (state: RootState) => state.offerSlice.customer;
export const getOfferVehicleParams = () => (state: RootState) => state.offerSlice.vehicle;
export const getOfferSearchFlow = () => (state: RootState) => state.offerSlice.searchFlow;
export const getOfferInterchange = () => (state: RootState) => state.offerSlice.interchange;
export const getOfferStockNumber = () => (state: RootState) => state.offerSlice.stock;