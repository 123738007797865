import { TrashIcon } from '@heroicons/react/20/solid';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Card from '../components/common/card/Card';
import Button from '../components/form/Button';
import Checkbox from '../components/form/Checkbox';
import Input, { TInputEvent } from '../components/form/Input';
import { useConfirm } from '../hooks/useConfirm';
import { useTemplate } from '../hooks/useTemplate';
import AdminPanelLayout from '../layouts/AdminPanel.layout';
import BodyWithSidebar from '../layouts/content/BodyWithSidebar';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import { INFO_MESSAGES } from '../messages/infoMessages';
import { ITemplate } from '../model/ITemplate';
import { templateApi } from '../services/template.api';

// -- State for template adding
const defaultTemplate : ITemplate = {
  name: '',
  subject: '',
  body: '',
  isDefault: false,
  id: 0
};

const TemplatePage: FC = () => {
  // -- Get current template id from URL params
  let { id : paramsId = 0 } = useParams();

  const navigate = useNavigate();
  
  const confirm = useConfirm()
  const { addTemplate, deleteTemplate, updateTemplate } = useTemplate();

  // -- Load data
  const { data: templatesList } = templateApi.useGetTemplatesQuery({});

  // -- States
  const [templateState, setTemplateState] = useState<ITemplate>(defaultTemplate)
  const [templateErrState, setTemplateErrState] = useState<Partial<Record<keyof ITemplate, string>>>();
  const [isPending, setIsPending] = useState<boolean>(() => false);

  const findTemplateByCol = (col: keyof ITemplate, value: number | string ): ITemplate | undefined =>
    (templatesList || []).find((tpl) => tpl?.[col] === value);

  useEffect(() => {
    templatesList && paramsId && setTemplateState(() => findTemplateByCol('id', +paramsId) || defaultTemplate);
    setTemplateErrState(() => undefined);
  }, [templatesList, paramsId]);


  const handleInput = (key: keyof ITemplate) => (e: TInputEvent) => setTemplateState((prevState) => ({
    ...prevState,
    [key]: e?.target?.value || '',
  }));

  const handleDelete = async () => {
    if (!templateState?.id) return false;   

    confirm.on({
      text: 'Please confirm that you want to delete this template',
      action: async () => { 
        setIsPending(() => true);
        const result = await deleteTemplate(templateState.id);
        setIsPending(() => false);   
    
        if (!result) {
          setTemplateErrState(() => undefined);      
          setTemplateState(() => defaultTemplate);
          toast.success(INFO_MESSAGES['template-delete']);
          return navigate('/admin/template');
        } 
          
        return typeof result === 'string'
          ? toast.error(`${ERROR_MESSAGES['template-delete']} \n${result}`)
          : setTemplateErrState(() => result);
      }
    });    
  };

  const onAddTemplate = async () => {
    // Check if this name already exists
    if (findTemplateByCol('name', templateState.name))
      return setTemplateErrState((prevState) => ({
        ...prevState,
        name: ERROR_MESSAGES['template-name-exists'],
      }));      
    
    setIsPending(() => true);
    // - Delete id (when we add template id equal 0)
    const { id, ...addParams } = templateState;
    const result = await addTemplate(addParams);     
    setIsPending(() => false);

    if (!result) {
      setTemplateErrState(() => undefined);
      setTemplateState(() => defaultTemplate);
      return toast.success(INFO_MESSAGES['template-added']);
    }
    
    return typeof result === 'string'
      ? toast.error(`${ERROR_MESSAGES['template-add']} \n${result}`)
      : setTemplateErrState(() => result);        
  };
  
  const onUpdateTemplate = async () => { 
    if (!templateState?.id) return false;
    
    // Check if this name already exists
    if (findTemplateByCol('id', templateState.id)?.name !== templateState.name
    && findTemplateByCol('name', templateState.name))
      return setTemplateErrState((prevState) => ({
          ...prevState,
          name: ERROR_MESSAGES['template-name-exists'],
        }));

    setIsPending(() => true);
    const result = await updateTemplate(templateState);     
    setIsPending(() => false);

    if (!result) {
      setTemplateErrState(() => undefined);
      setTemplateState(() => defaultTemplate);
      toast.success(INFO_MESSAGES['template-update']);
      return navigate('/admin/template');
    }
    
    return typeof result === 'string'
      ? toast.error(`${ERROR_MESSAGES['template-update']} \n${result}`)
      : setTemplateErrState(() => result);        
  }; 

  return (
    <AdminPanelLayout
      pageTitle="Custom Templates"
      title="Custom Templates"
    >
      <BodyWithSidebar
        sidebar={[
            ...(templatesList || []),
            defaultTemplate
          ].map(({ id, isDefault, name }, i) => (
            <NavLink
              key={id}
              to={`/admin/template/${id}`}
              className={`
                rounded-lg px-4 py-2 w-full flex gap-3 items-center
                ${paramsId !== undefined && (+paramsId === id) ? 'bg-primary text-white' : ''}
              `}
            >
              {id ? name : '+ Add New Template'}
              {!!isDefault && (
                <span className='bg-blue-100 text-primary rounded-xl h-[18px] px-[6px] py-[2px] text-xs flex items-center justify-center'>
                  Default
                </span>)
              }
            </NavLink>
        ))}
      >
        <div className="flex justify-between mb-4">
          <div className="text-xl font-semibold ml-2">
            {paramsId && +paramsId !== 0 && templateState?.name
              ? findTemplateByCol('id', templateState?.id)?.name
              : 'New template'}
          </div>
          <div>
            {!!(paramsId && templateState?.id && !findTemplateByCol('id', templateState?.id)?.isDefault) && (
              <span
                role='button'
                tabIndex={-1}
                onClick={handleDelete}
                className='flex gap-2 items-center fill-slate-400 text-slate-400 hover:text-slate-700 hover:fill-slate-700 duration-100'
              >
                <TrashIcon className='w-4 h-4' /> Delete Template
              </span>
            )}
          </div>
        </div>
        <Card>
          <div className="grid gap-4">
            <Input
              label="Name"
              name="name"
              value={templateState?.name || ''} 
              className='col-span-12'
              handle={handleInput('name')}
              disabled={isPending}
              error={templateErrState?.name}
            />
            <Input
              label="Subject"
              name="subject"
              value={templateState?.subject || ''} 
              className='col-span-12'                
              handle={handleInput('subject')}
              disabled={isPending}
              error={templateErrState?.subject}
            />
            {/* <Input
              label="Email template"
              name="body"
              type="textarea"
              value={templateState?.body || ''} 
              className='col-span-12'
              handle={handleInput('body')}
              disabled={isPending}
              error={templateErrState?.body}
            /> */}
            <Input
              label="Text"
              name="body"
              type="redactor"
              value={templateState?.body || ''} 
              className='col-span-12 h-[400px]'
              setState={(value: string) => setTemplateState((prevState) => ({
                ...prevState,
                body: value || '',
              }))}
              disabled={isPending}
              error={templateErrState?.body}
            />
            <span className='text-primary text-xs'>
                Available variables: &#123;customer_name&#125;
             </span>
            <Checkbox
              label="Default template"
              name="isDefault"
              value={templateState?.isDefault || false}
              handleToggle={() => setTemplateState((prevState) => ({
                ...prevState,
                isDefault: !prevState?.isDefault,
              }))}
              className='col-span-12'
              disabled={isPending}
              error={templateErrState?.isDefault}
              isNoLabelArea
            />           
            <div className="flex gap-4">
              <Button
                onClick={templateState?.id
                  ? onUpdateTemplate
                  : onAddTemplate
                }
                isNotFullWidth
              >
                {paramsId && templateState?.id ? 'Update Template' : 'Add Template'}
              </Button>
              <Button
                onClick={() => templateState?.id
                  ? setTemplateState(() => findTemplateByCol('id', templateState.id) || defaultTemplate)
                  : setTemplateState(() => defaultTemplate)
                }
                variant='outline'
                isNotFullWidth
              >
                Reset
              </Button>             
            </div>
        </div>
        </Card>
      </BodyWithSidebar>  
    </AdminPanelLayout>
  );
};

export default TemplatePage;
