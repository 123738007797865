import React, { Children, FC, PropsWithChildren, useLayoutEffect } from 'react';
// import { ILayoutComponent } from './types';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import PreHeader from '../components/PreHeader';
// import useMeta from '../hooks/useMeta';
import { ILayout } from './ILayout';
import useMeta from '../hooks/useMeta';
import Header from '../components/Header';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';

const MainLayout: FC<PropsWithChildren<ILayout>> = ({
  children,
  isNoHeader = false,
  pageTitle,
  title,
  breadcrumbs = [],
  topMainNav,
}) => {
  useMeta({ pageTitle: pageTitle || '' });

  // Open links at top of page
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="h-full bg-pale">
      {!!!isNoHeader && <Header />}
      {!!topMainNav && (
        <div className="border-gray-300 border-b-[1px] pt-3 bg-gray-200">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            {topMainNav}
          </div>          
        </div>        
      )}
      <main className="py-10">        
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">          
          {!!breadcrumbs?.length && <Breadcrumbs
            className='mb-6'
            breadcrumbs={breadcrumbs}
          />}
          {!!title && <h1 className="text-3xl sm:pb-2 lg:pb-4">{title}</h1>}        
          {children}
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
