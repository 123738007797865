import { statisticsApi } from "../../services/statistics.api";
import RenderStatistics from "./RenderStatistics";
import Filter, { IFilter, IFilterState } from "../common/filter/Filter";
import UserMultiSearchField from "../user/UserMultiSearchField";
import DateRange from "../date/DateRange";
import { TStatisticFilterFields, TStatisticFilterState, getStatisticFilterCount, getStatisticFilterParams, setStatisticFilterParams, unsetStatisticFilterParams } from "../../store/statisticSlice";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import FilterOptionsRow from "../common/filter/FilterOptionsRow";
import { useUser } from "../../hooks/useUser";
import { userApi } from "../../services/user.api";
import { useAccess } from "../../hooks/useAccess";

export default function Statistics() {
  const dispatch = useTypedDispatch();
  const statisticFilterParams = useTypedSelector(getStatisticFilterParams());
  const statisticFilterParamsCount = useTypedSelector(getStatisticFilterCount());
  const { convertIdsToUsers } = useUser();
  const { getSettingAccess } = useAccess();

  const { data: statistics, isFetching: isFetchingStatistics } = statisticsApi.useGetStatisticsQuery(statisticFilterParams);
  const { data: users } = userApi.useGetUsersQuery('');

  const filterFields: IFilter<Partial<TStatisticFilterState>> = {
    ...(getSettingAccess('otherUserStatistic')
      ? { 
        salesRepIds: {
          label: 'Sales Rep',
          Component: UserMultiSearchField,
        }
      } : {}
    ),
    dateRange: {
      label: 'Date Range',
      Component: DateRange,
    },
  };  


  return (
    <div className="grid gap-6">
      <div className="flex gap-4">
        <Filter
          className="w-[550px]"
          fields={filterFields}
          filterState={statisticFilterParams}
          setFilterState={(newState: IFilterState<Record<TStatisticFilterFields, string>>) => { dispatch(setStatisticFilterParams(newState)); }}
          filterCount={statisticFilterParamsCount}
        />
        <FilterOptionsRow
          unsetAll={statisticFilterParamsCount
            ? {
              label: 'Clear Filters',
              onClick: () => dispatch(unsetStatisticFilterParams())
            }
            : undefined
          }
          options={users?.length && statisticFilterParams.salesRepIds
            ? convertIdsToUsers(statisticFilterParams.salesRepIds, users)
              .map(({ id, name, email }) => ({
                label: name || email || 'no Data',
                onClick: () => {},
              }))
            : []         
          }
        />
      </div>
      <RenderStatistics
        isFetchingStatistics={isFetchingStatistics}
        renderList={statistics || []}
      />
    </div>
  );
}
