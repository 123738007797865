import { useEffect } from 'react';

interface IMeta {
  pageTitle?: string;
  description?: string;
  keywords?: string;
}

export default function useMeta(meta: IMeta) {
  useEffect(() => {
    const { pageTitle, description, keywords } = meta;

    const prevTitle = document.title;

    document.title = `${pageTitle ? ` ${pageTitle}` : ''} - ${process.env.TITLE || 'MotixCRM'}`;
    if (description)
      document.querySelector('meta[name="description"]')?.setAttribute('content', description);
    if (keywords)
      document.querySelector('meta[name="keywords"]')?.setAttribute('content', keywords);

    return () => {
      document.title = prevTitle;
    };
  }, []);
}
