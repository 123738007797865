import { IStatisticsItem } from '../model/IStatiscticsItem';
import { TStatisticFilterState } from '../store/statisticSlice';
import api from './api';

export const statisticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStatistics: build.query<IStatisticsItem[], Partial<TStatisticFilterState>>({
      query: (filterState) => ({
          url: `/statistics/?${(new URLSearchParams(filterState)).toString()}`,
        })
    }),

    getAllStatistics: build.query<IStatisticsItem[], {}>({
      query: () => ({
          url: '/statistics/all',
        })
    }),
  }),
});
