import { z } from "zod";
import { ERROR_MESSAGES } from "../messages/errorMessages";
import { getErrorFromCatch } from "../services/api";
import { userApi } from "../services/user.api";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { IEditUser, INewUserRequest, IUser, TUserRequest } from "../model/IUser";
import { useTypedSelector } from "./useTypedSelector";
import { TUserState, getCurrentUser } from "../store/authSlice";
import { TRole } from "../model/IRole";

export function useUser() {
  const currentUser = useTypedSelector(getCurrentUser());

  // --
  // -- Data checking scheme
  // --
  const newUserRequestSchema = z.object({
    email: z
      .string()
      .email(ERROR_MESSAGES['user-email-unsuitable'])
      .min(1, ERROR_MESSAGES['user-no-email']),
    password: z
      .string()
      .min(4, ERROR_MESSAGES['user-short-password']),
    passwordConfirm: z
      .string()
      .min(4, ERROR_MESSAGES['user-short-password']),
  } as Record<keyof INewUserRequest, any>)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: ERROR_MESSAGES['user-password-no-match'],
        path: ['passwordConfirm'],
      });
    }
  });

  // -- scheme to update main user information by admin
  const editUserMainRequestSchema = z.object({});

  // -- scheme to update passwords user by admin
  const editUserPassRequestSchema = z.object({
    password: z
      .string()
      .min(4, ERROR_MESSAGES['user-short-password']),
    passwordConfirm: z
      .string()
      .min(4, ERROR_MESSAGES['user-short-password']),
  } as Record<keyof INewUserRequest, any>)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: ERROR_MESSAGES['user-password-no-match'],
        path: ['passwordConfirm'],
      });
    }
  });

  // -- scheme to update current user information
  const editUserRequestSchema = z.object({
    password: z.string(),
    passwordConfirm: z.string(),
    currentPassword: z.string(),
  } as Record<keyof IEditUser, any>)
  .superRefine(({ password, passwordConfirm, currentPassword }, ctx) => {
    // - there isn't a new password
    if (!passwordConfirm && !password) return;

    // - there isn't the current password
    !currentPassword && ctx.addIssue({
        code: "custom",
        message: ERROR_MESSAGES['user-no-current-password'], // no current
        path: ['currentPassword'],
      });
    
    // - the passwords is too short
    currentPassword.length < 4 && ctx.addIssue({
        code: "custom",
        message: ERROR_MESSAGES['user-short-password'],
        path: ['currentPassword'],
      });
    password.length < 4 && ctx.addIssue({
      code: "custom",
      message: ERROR_MESSAGES['user-short-password'],
      path: ['password'],
    });
    passwordConfirm.length < 4 && ctx.addIssue({
      code: "custom",
      message: ERROR_MESSAGES['user-short-password'],
      path: ['passwordConfirm'],
    });

    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: ERROR_MESSAGES['user-password-no-match'],
        path: ['passwordConfirm'],
      });
    }
  });

  // --
  // -- Data Getting Methods
  // --
  const [tryToAddUser] = userApi.useAddUserMutation();
  const [tryToDeleteUser] = userApi.useDeleteUserMutation();
  const [tryToEditUser] = userApi.useEditUserMutation();

  // -- 
  // -- Methods
  // -- 

  const convertIdsToUsers = (ids?: string | number, users?: IUser[]): IUser[] => (String(ids) || '')
    .split(',')
    .map((id) => (users || []).find(({ id: originalId }) => originalId === id ))
    .filter((user) => !!user) as IUser[];

  // -- 
  // -- Get User Roles
  // -- 
  const getUserRoles = (user: TUserState = currentUser): TRole[] => (user?.userRole || [])
    .map(({ role }) => role);

  // -- 
  // -- Check if user has one of roles
  // -- 
  const hasRoles = (roles: TRole[], user: TUserState = currentUser): boolean => 
    new Set(...([...getUserRoles(user), ...roles])).size !== [...getUserRoles(user), ...roles].length;

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const addUser = async (userState: INewUserRequest): Promise<IZodErrorsState<INewUserRequest> | string | false> => {
  
    // 1. Check data
    const errs = checkFormStateByZod(newUserRequestSchema, userState);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToAddUser(userState).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const deleteUser = async (userId: string): Promise<string | false> => {
  
    // 1. Check data
    if (!userId) return ERROR_MESSAGES['not-enough-data'];  
    
    // - 2. Send data
    try {
      await tryToDeleteUser(userId).unwrap();;
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const editMainUserInfo = async (id: string, userState: IEditUser): Promise<IZodErrorsState<TUserRequest> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserMainRequestSchema, userState);
    if (Object.values(errs)?.length) return errs;   
    
    // - 2. Send data
    try {
      await tryToEditUser({ id, ...userState }).unwrap();;
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };
  
  const editPassUserInfo = async (id: string, userState: IEditUser): Promise<IZodErrorsState<TUserRequest> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserPassRequestSchema, userState);
    if (Object.values(errs)?.length) return errs;   
    
    // - 2. Send data
    try {
      await tryToEditUser({ id, ...userState }).unwrap();
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };
  
  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const editUserInfo = async (id: string, userState: IEditUser): Promise<IZodErrorsState<IEditUser> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserRequestSchema, userState);
    if (Object.values(errs)?.length) return errs;   
    
    // - 2. Send data
    try {
      await tryToEditUser({ id, ...userState }).unwrap();
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };

  return {
    addUser,
    deleteUser,
    editMainUserInfo, // - for admin flow
    editPassUserInfo, // - for admin flow
    editUserInfo, // -for user flow
    convertIdsToUsers,

    hasRoles,
    getUserRoles,
  };
}