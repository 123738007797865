import { FC, useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component";
import { useUser } from "../../hooks/useUser";
import { IUser } from "../../model/IUser";
import { userApi } from "../../services/user.api";
import { IInput, TInputEvent } from "../form/Input";
import Label from "../form/Label";
import './UserMultiSearchField.css';

const UserMultiSearchField: FC<IInput> = ({ label, handle, value }) => {
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const { data: users, isFetching: isFetchingUsers } = userApi.useGetUsersQuery('');
  
  const { convertIdsToUsers } = useUser();

  // -- Convert selected users to string format for global state
  const convertUsersToIds = (users?: Option[]): string => (users || [])
    .map(({ value }) => value)
    .join(',');

  const convertUsersToOptions = (users?: IUser[]): Option[] => (users || [])
    .map(({ id, email, name }) => ({
      label: `${name} ${email}`,
      value: id,
    }))

  // -- Apply global state changes to local selected list
  useEffect(() => {
    setSelectedUsers(() => convertUsersToOptions(convertIdsToUsers(value, users)));
  }, [value, users]);

  return <>
    <Label size="small">
      <span className="flex gap-2">
      {label} 
      {!!selectedUsers.length && <>
        <span className="">{selectedUsers.length} selected</span>
        <span
          tabIndex={-1}
          role="button"
          className="underline"
          onClick={() => setSelectedUsers(() => [])}
        >
          clear
        </span>
      </>}
      </span>
    </Label>    
    <MultiSelect      
      options={(users || []).map(({ id, email, name }) => ({
        label: `${name} ${email}`,
        value: id,
      }))}
      value={selectedUsers}
      onChange={(e: React.SetStateAction<Option[]>) => {
        setSelectedUsers(e);
        handle && handle({ target: { value: convertUsersToIds(e as Option[]) }} as TInputEvent);
      }}
      labelledBy={label || 'Choose Option'}
      isLoading={isFetchingUsers}
      valueRenderer={(selected, _options)=> selected.length
        ? selected.map(({ label }, i) => (
          <div
            role="button"
            tabIndex={-1}
            key={`option-selected-${label}-${i}`}
            className="truncate text-xs w-[100px] border-blue-600 border-[1px] text-blue-600 bg-white rounded-3xl py-1 px-2"
          >
            {label}
          </div>
        ))
        : "Choose Sale Reps"
      }
      className="text-xs"
    />
  </>;
};

export default UserMultiSearchField;
