import { TRole } from "./app/model/IRole";

type IAccess<T extends string | number | symbol> = {
  [key in T]: TRole[];
};

// // -- Pages access
// export type TPageUrl = keyof pagesAccess
// | 'https://sales-resource.automotix.com/'


// | '/portal'
// | '/offer'

// | '/setting'
// | '/setting/:setting'

// | '/admin'

// | '/admin/discounts'

// | '/admin/managers'
// | '/admin/managers/:userId'
// | '/admin/managers/:userId/edit'
// | '/admin/managers/new'

// | '/admin/template'
// | '/admin/template/:id'

// | '/admin/goal'
// ;

export const pagesAccess = {
  'https://sales-resource.automotix.com/': ['ROLE_ADMIN', 'ROLE_USER'],


  '/portal': ['ROLE_ADMIN', 'ROLE_USER'],
  '/offer': ['ROLE_ADMIN', 'ROLE_USER'],

  '/setting': ['ROLE_ADMIN', 'ROLE_USER'],
  '/setting/:setting': ['ROLE_ADMIN', 'ROLE_USER'],  

  '/admin':                       ['ROLE_ADMIN'],

  '/admin/discounts':             ['ROLE_ADMIN'],

  '/admin/managers':              ['ROLE_ADMIN'],
  '/admin/managers/:userId':      ['ROLE_ADMIN'],
  '/admin/managers/:userId/edit': ['ROLE_ADMIN'],
  '/admin/managers/new':          ['ROLE_ADMIN'],
  
  '/admin/template':              ['ROLE_ADMIN'],
  '/admin/template/:id':          ['ROLE_ADMIN'],

  '/admin/goal':                  ['ROLE_ADMIN'],
};

export type TPageUrl = keyof typeof pagesAccess;

// -- Setting access
// export type TSettingNames = 
// | 'account' 
// | 'goals'
// ;

export const settingAccess = {
  'account': ['ROLE_ADMIN', 'ROLE_USER'],
  'goals': ['ROLE_ADMIN'],
  'otherUserStatistic': ['ROLE_ADMIN']
};

export type TSettingNames = keyof typeof settingAccess;
