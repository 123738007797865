import { FC, PropsWithChildren } from 'react';
import useMeta from '../hooks/useMeta';
import { ILayout } from './ILayout';

const CenterLayout: FC<PropsWithChildren<ILayout>> = ({
  children,
  pageTitle,
}) => {
  useMeta({ pageTitle: pageTitle || '' });
  return (
    <div className="h-full bg-pale">
      <main>       
        {children}
      </main>
    </div>
  );
};

export default CenterLayout;
