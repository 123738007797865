import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import Button from '../components/form/Button';

// -- Interfaces
// export interface IConfirmProvider {
//   children: React.ReactNode;
// }
export interface IConfirmOn {
  title?: string,
  action?: () => unknown,
  text?: string,
  cancelText?: string,
  applyText?: string,
}

export interface IConfirmContext {
  isShow: boolean;
  on: (parms: IConfirmOn) => unknown;
  onClose: () => void;
}

// -- Create context
const ConfirmContext = createContext<IConfirmContext>({
  isShow: false,
  on: ({}) => {},
  onClose: () => {},
});

export const useConfirm = () => useContext(ConfirmContext);

const ConfirmProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');
  const [action, setAction] = useState<() => unknown>(() => {});
  const [title, setTitle] = useState<string>('Confirmation needed');
  const [cancelText, setCancelText] = useState<string>();
  const [applyText, setApplyText] = useState<string>();

  const onClose = () => setIsShow(false);

  return (
    <ConfirmContext.Provider
      value={{
        isShow,
        on: ({ title, action, text, cancelText, applyText } : IConfirmOn) => {
          setBody(text || '');
          setTitle(() => title || '');
          !isShow && setIsShow(true);
          setAction(() => action || (() => {}));
          setCancelText(() => cancelText || 'Cancel');
          setApplyText(() => applyText || 'Apply');
        },
        onClose,
      }}
    >
      {children}
      {!!isShow && (
        <div
          role="button"
          tabIndex={-1}
          onClick={onClose}      
          style={{}}
          className={`        
            ${isShow ? '' : 'hidden'}
            modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-[200]
          `}
        >
          <div
            role="button"
            tabIndex={-1}
            className="bg-white rounded-2xl shadow-lg w-[300px] max-h-full overflow-y-auto"
            onClick={(e) => { e.stopPropagation(); }}
          >
            {!!title && (
              <div className="border-b px-8 py-6 flex justify-between items-center">
                <h3 className="font-semibold text-lg">{title}</h3>
                <button
                  className="close-modal"
                  onClick={onClose}
                >
                  <svg className="fill-slate-400" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" />
                  </svg>
                </button>
              </div>
            )}            
            <div className="px-10 pt-6 pb-8">{body}</div>
            <div className="px-10 pt-6 pb-8 flex gap-4 mt-6">
              <Button
                onClick={onClose}
                variant="outline"
                className="whitespace-nowrap"
              >
                {cancelText}
              </Button>
              <Button
                onClick={() => {
                  action && action();
                  onClose();
                }}
                className="whitespace-nowrap"
              >
                {applyText}
              </Button>            
            </div>
          </div>
        </div>
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
