import { FC } from "react";
import { IPart } from "../../model/IPart";
import priceFormatter from "../../utils/priceFormatter";
import arrayToNodeString from "../../utils/arrayToNodeString";

const PartInformation: FC<{ part: IPart }> = ({ part }) => (
  <div className="flex flex-col">
    <div className="text-lg text-slate-500 font-bold mb-6">
      Part Information
    </div>        
    <div className="flex gap-4">
      <div className="">
        {!!part?.section?.code && <img
            src={`https://automotix.net/images/products_small/lkq/${part.section.code.toLowerCase()}_category.jpg`}
            width={120}
            height={120}
            alt={`${part?.section?.name || ''} for ${part?.year || ''} ${part?.carMake?.name || ''} ${part?.carModel?.name || ''}`} 
          />
        }
      </div>
      <div className="text-sm font-medium">
        {arrayToNodeString(<br/>, [
          arrayToNodeString(' ', [
            part?.year,
            part?.carMake?.name,
            part?.carModel?.name,
            part?.section?.name,
          ]),
          part?.product?.partNumber
            ? `#${part?.product?.partNumber}`
            : undefined,
          part?.product?.description,
          part?.product?.interchangeNumber
            ? `Part #: ${part?.product?.interchangeNumber}`
            : undefined,
          priceFormatter(part?.product?.price)
        ])}
      </div>
    </div>
  </div>
);

export default PartInformation;
