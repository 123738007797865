type ERROR_MESSAGES_KEYS = 
| 'not-enough-data'
| 'unknown-error' 

| 'login-field-empty'
| 'login-field-no-email'
| 'password-field-empty'
| 'password-field-too-short'
| 'token-empty'
| 'signin-failed'
| 'signin-wrong-data'

| 'offer-no-product-id'
| 'offer-no-email'
| 'offer-email-unsuitable'
| 'offer-no-client-name'
| 'offer-short-client-name'
| 'offer-short-phone'
| 'offer-no-client-phone'
| 'offer-no-zip'
| 'offer-no-website'
| 'offer-no-discount'
| 'offer-no-send-email-info'
| 'offer-create-failed'
| 'offer-short-zip'

| 'user-search'
| 'user-list'
| 'user-details'
| 'user-email-unsuitable'
| 'user-no-email'
| 'user-short-password'
| 'user-create-failed'
| 'user-password-no-match'
| 'user-delete-failed'
| 'user-main-updated'
| 'user-pass-updated'
| 'user-updated-himself'
| 'user-no-current-password'

| 'customer-search'

| 'vehicle-year-loading'
| 'vehicle-makes-loading'

| 'part-all-type-loading'
| 'parts-loading'

| 'geo-state-loading'

| 'template-empty-name'
| 'template-empty-subject'
| 'template-empty-body'
| 'template-add'
| 'template-delete'
| 'template-update'
| 'template-name-exists'

| 'zip-format'
| 'zip-not-found'
| 'setting-empty-name'
| 'setting-empty-value'
| 'setting-update-error'

| 'discount-delete'
| 'discount-list'
| 'discount-amount-error'
| 'discount-limit-error'
| 'discount-add'
| 'discount-recover'
| 'clipboard-error'
;

export const ERROR_MESSAGES: Record<ERROR_MESSAGES_KEYS, string> = {
  'not-enough-data': 'Not enough data',
  'unknown-error': 'Unknown error',

  // Log In
  'login-field-empty': 'Please enter your login!',
  'login-field-no-email': 'Your login shoild be an email!',
  'password-field-empty': 'Please enter your password!',
  'password-field-too-short': 'Your password is too short!',
  'token-empty': 'You looks like a robot!',

  'signin-failed': 'Signing in failed',
  'signin-wrong-data': 'Invalid username or password',

  'offer-no-product-id': 'There is not the product information (id)',
  'offer-no-email': 'Please enter client email',
  'offer-email-unsuitable': 'Wrong email format',
  'offer-no-client-name': 'Please enter client name',
  'offer-short-client-name': 'Client name is too short',
  'offer-no-client-phone': 'Please enter client phone',
  'offer-no-zip': 'Please enter client zip',
  'offer-no-website': 'Please, choose the website',
  'offer-no-discount': 'Please, choose the discount',
  'offer-no-send-email-info': 'There is not any information about if it needs to send aa email',
  'offer-create-failed': 'Failed to create the offer',
  'offer-short-phone': 'Phone number is too short',
  'offer-short-zip': 'Zipcode is too short',

  'user-search': 'Faild to search an user', 
  'user-list': 'Faild to load a user list', 
  'user-details': 'Faild to load user details', 
  'user-email-unsuitable': 'Wrong email format',
  'user-no-email': 'Please enter client email',
  'user-short-password': 'Password is too short',
  'user-create-failed': 'Failed to create the user',
  'user-password-no-match': 'Passwords don\'t match',
  'user-delete-failed': 'Failed to delete the user',
  'user-main-updated': 'Failed to uptade the user information',
  'user-pass-updated': 'Failed to uptade the user passwords',
  'user-updated-himself': 'Your information hasn\'t been updated',
  'user-no-current-password': 'Your have to enter current password',

  'customer-search': 'Faild to search a customer',
  
  'vehicle-year-loading': 'Faild to load available vehicle years',
  'vehicle-makes-loading': 'Faild to search available vehicle makes',

  'part-all-type-loading': 'Faild to load all parts list',
  'parts-loading': 'Faild to load parts list',

  'geo-state-loading': 'Faild to load states',

  'template-empty-name': 'Template name is required',
  'template-empty-subject': 'Email subject is required',
  'template-empty-body': 'Template body is required',
  'template-add': 'Faild to add template',
  'template-delete': 'Faild to delete template',
  'template-update': 'Faild to update template',
  'template-name-exists': 'This name already exist',

  'zip-format': 'Wrong Zip format (5 numbers or 6 numbers and letters)',
  'zip-not-found': 'Zip Code not found',
  'setting-empty-name':'Name cannot be empty',
  'setting-empty-value':'Value cannot be empty',
  'setting-update-error': 'Failed to update value',

  'discount-delete':        'Failed to delete the discount',
  'discount-add':           'Failed to add the discount',
  'discount-list':          'Faild to load discounts list',
  'discount-amount-error':  'The discount amount must be a number greater than 1',
  'discount-limit-error':   'The discount limit must be a number greater than 1',
  'discount-recover':       'The discount was recovered successfully',
  'clipboard-error': 'Could not copy text'
};