import { FC, useEffect, useState } from "react";
import { IPageParams } from "../../services/IApi";
import Table, { ITableTh } from "../common/table/Table";
import { userApi } from "../../services/user.api";
import { CONFIG } from "../../config/config";
import parseISOToString from "../../utils/parseISOToString";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import Filter, { IFilter, IFilterState } from "../common/filter/Filter";
import { NavLink, useNavigate } from "react-router-dom";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { getErrorFromCatch } from "../../services/api";
import Button from "../form/Button";
import { useConfirm } from "../../hooks/useConfirm";
import { useUser } from "../../hooks/useUser";
import { INFO_MESSAGES } from "../../messages/infoMessages";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getCurrentUser } from "../../store/authSlice";
import priceFormatter from "../../utils/priceFormatter";

export type TUsersFilterFields =
| 'email'
| 'id'
| 'phone';

export type TUsersFilterState = IFilterState<Record<TUsersFilterFields, string>>;

const UserList : FC = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { deleteUser } = useUser();
  const currentUser = useTypedSelector(getCurrentUser());

  // -- Page parameters and filter states
  const [pageParams, setPageParams] = useState<IPageParams>({
    page: 0,
    size: CONFIG.user.usersPerPage,
    q: '',
    sort: '',
    sortDir: 'asc',
  });

  const [filterState, setFilterState] = useState<IFilterState<Record<TUsersFilterFields, string>>>({
    id: '',
    email: '',
    phone: '',
  }); 

  // -- Load user list
  const { data: users, isFetching, isError, error } = userApi.useGetUserListQuery({
    ...pageParams,
    ...filterState,
  });

  // -- Table header
  const thead: ITableTh[] = [
    // { title: 'Id', sortCol: 'id' },
    { title: 'Email', sortCol: 'email' },
    { title: 'Name', sortCol: 'name' },
    { title: 'Phone', sortCol: 'phone' },
    { title: 'Enabled', sortCol: 'active' },
    { title: 'IsAdmin' },
    { title: 'Created', sortCol: 'registered' },
    { title: 'Action' },
  ];

  // -- Api Error handler
  useEffect(() => {
    isError && error && toast.error(`${ERROR_MESSAGES['user-list']} ${getErrorFromCatch(error)}`);
  }, [isError]);  

  // -- Filter params
  const filterFields: IFilter<TUsersFilterState> = {
    id: { label: 'ID' },
    email: { label: 'Email' },
    phone: { label: 'Phone' },
  };


  // -- Handlers
  const deleteHandle = (userId: string) => () => {    
    confirm.on({
      text: 'Please confirm that you want to delete this user',
      action: async () => { 
        const result = await deleteUser(userId);
        if (!result)
          return toast.success(INFO_MESSAGES['user-deleted']);
  
        return typeof result === 'string'
          ? toast.error(`${ERROR_MESSAGES['user-delete-failed']} \n${result}`)              
          : false;
      }
    });
  }
  
  return (
    <Table
      options={[
        <Filter
          className="w-[250px]"
          fields={filterFields}
          filterState={filterState}
          setFilterState={(newState: TUsersFilterState) =>  setFilterState(newState)}
        />,
        <Button
          onClick={() => navigate('new')}
          size="small"
        >
          Add User
        </Button>
      ]}
      isPending={isFetching}
      pageParams={pageParams}
      pagable={users}
      updatePageParams={(newParams: Partial<IPageParams>) => 
        setPageParams((prevState) => ({
          ...prevState,
          ...newParams
        })) 
      }
      thead={thead} 
      tbody={!users?.content?.length
        ? []
        : users.content.map(({ id, name, email, phone, active, userRole, registered}) => [
            // id,
            <NavLink to={`${id}`}>{email}</NavLink>,
            name || ' ',
            phone || ' ',
            active ? 'yes' : 'no',
            userRole?.length && userRole.find(({ role }) => role === 'ROLE_ADMIN')
              ? 'yes'
              : 'no',
            parseISOToString(registered || ''),
            <div className="flex gap-2">
              <PencilSquareIcon
                className="w-[1.1rem] h-[1.1rem] fill-slate-500 hover:fill-green-700 transition-all duration-200 cursor-pointer"
                onClick={() => navigate(`${id}/edit`)}
              />  
              {currentUser?.id !== id && (
                <TrashIcon
                  className="w-[1.1rem] h-[1.1rem] fill-slate-500 hover:fill-red-700 transition-all duration-200 cursor-pointer"
                  onClick={deleteHandle(id)}
                />
              )}
            </div>,
        ])
      } 
    />
  );
};

export default UserList;
