import { FC } from "react";
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

export interface IBreadcrumb {
  name: string,
  href?: string,
}

const Breadcrumbs : FC<{
  breadcrumbs: IBreadcrumb[],
  className?: string,
}> = ({
  breadcrumbs,
  className,
}) => (
  <nav className={`flex ${className || ''}`} aria-label="Breadcrumb">
    <ol role="list" className="flex items-center space-x-4">
      <li>
        <div>
          <a href="/" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </a>
        </div>
      </li>
      {breadcrumbs.map((page, i) => (
        <li key={page.name}>
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <a
              href={page.href}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current={i === breadcrumbs.length - 1 ? 'page' : undefined}
            >
              {page.name}
            </a>
          </div>
        </li>
      ))}
    </ol>
  </nav>
);

export default Breadcrumbs;
