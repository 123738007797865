import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IPart } from "../../model/IPart";
import { IPartGroup } from "../../model/IPartGroup";
import { IPageParams } from "../../services/IApi";
import { IPagesResponse } from "../../services/IPagesResponse";
import { getErrorFromCatch } from "../../services/api";
import { partApi } from "../../services/part.api";
import { TOfferInterchange, getOfferCustomerParams, getOfferVehicleParams, setOfferVehicleParams } from "../../store/offerSlice";
import priceFormatter from "../../utils/priceFormatter";
import PartGroupFilter from "../part/PartGroupFilter";
import PartListForOffer from "./PartListForOffer";
import SelectInterchangeFromPart from "./SelectInterchangeFromPart";

export default function PartsByVehicle () {
  const dispatch = useTypedDispatch();

  // -- Get search and page parameters from global offer state 
  const vehicle = useTypedSelector(getOfferVehicleParams());
  const pageParams = vehicle.pageParams;
  // const interchangeNumber = useTypedSelector(getOfferIntechangeNumber());
  const customer = useTypedSelector(getOfferCustomerParams());

  // -- State for data
  const [groups, setGroups] = useState<IPartGroup[]>();
  const [partsPage, setPartsPage] = useState<IPagesResponse<IPart[]>>();
  const [currentPart, setCurrentPart] = useState<IPart>();

  // -- Get loading data method
  const [getParts] = partApi.useLazyGetPartsQuery();
  const [getPartGroupPlaneTree] = partApi.useLazyGetGroupsPlaneTreeQuery();
  const [isPending, setIsPending] = useState<boolean>(false);

  // --
  // -- Vehicle flow search
  useEffect(() => {
    if (vehicle.makeId
    && vehicle.modelId
    && vehicle.partTypeId
    && vehicle.year)
      (async () => {
        setIsPending(() => true);
        try {
          const [groups, parts] = await Promise.all([
            getPartGroupPlaneTree(vehicle).unwrap(),
            getParts({
              ...vehicle,
              ...(customer?.zip ? { zip: customer?.zip } : {}),
              // if there isn't zip in the global state we need to remove distance sorting
              ...(!customer?.zip && pageParams.sort === 'distance'
                ? { pageParams: { ...pageParams, sort: '' }}
                : { pageParams: pageParams }
              ),
            }).unwrap(),
          ]);
          setGroups(() => groups || []);
          setPartsPage(() => parts || {});
        } catch (err) {    
          toast.error(`${ERROR_MESSAGES['parts-loading']} ${getErrorFromCatch(err)}`, { duration: 8000 });
        } finally {          
          setIsPending(() => false); 
        }
      })()
    else {
      setGroups(() => undefined);
      setPartsPage(() => undefined);
    }          
  }, [vehicle, pageParams, customer?.zip]);

  return (
    <>
      {/* {!!isPending && (!partsPage || !Array.isArray(partsPage?.content)) && <Loading />} */}
      <PartGroupFilter
        isShownDefault={!!!vehicle.interchangeNumber}
        // isShownDefault={true}
        groupPlaneTree={(groups as IPartGroup[])}
        vehicleParams={vehicle}
        isPanding={isPending}
        setCurrentInterchange={(interchangeNumber: TOfferInterchange) =>
          dispatch(setOfferVehicleParams({
            ...vehicle,
            interchangeNumber,
          }))
        }
      />   
      <PartListForOffer
        currentPart={currentPart}
        customer={customer}
        unsetPart={() => setCurrentPart(() => undefined)}
        isPending={isPending}
        pageParams={pageParams}
        pagable={partsPage}
        updatePageParams={(newPageParams: Partial<IPageParams>) => 
          dispatch(setOfferVehicleParams ({
            ...vehicle,
            pageParams: {
              ...vehicle.pageParams,
              ...newPageParams,
            },
          }))
        }
        tbody={!partsPage || !Array.isArray(partsPage?.content)
          ? [] 
          : partsPage.content.map((part) => [
              <a
                href="#part"
                tabIndex={-1}
                onClick={() => setCurrentPart(() => part)}
                className="text-blue-600 underline"
              >
                {part?.product?.partNumber || '-'}
              </a>,   
              <SelectInterchangeFromPart title={ part?.product?.interchangeNumber || '-'} part={part}/>,             
              part?.product?.stockNumber || '-', // Stock
              part?.year || '-', // Year
              part?.carMake?.name || '-', // Make
              part?.carModel?.name || '-', // Model
              part?.section?.name || '-', // Part Type
              part?.product?.condition || '-', // Mileage
              `${part?.product?.yard?.city}, ${part?.product?.yard?.state}, ${part?.product?.yard?.zip}`, // Location
              part?.distance || '-', // Distance
              priceFormatter(part?.localDiscount) || '-', // Local Discount  
              priceFormatter(part?.product?.price) || '-', // Price' },
              priceFormatter(part?.product?.shipping) || '-', // Shipping' }, !!shipping
              // priceFormatter(+price + +shipping) || '-', // Total' }, Price + shipping
            ])}
      />
    </>
  );
}
