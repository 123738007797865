import { FC } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { TSettingNames } from '../../accessMap';
import MainLayout from '../layouts/Main.layout';
import AccountSetting from '../components/setting/Account.setting';
import BodyWithSidebar from '../layouts/content/BodyWithSidebar';

export interface ISettingSection {
  title: string,
  setting: TSettingNames,
  Component: React.ReactNode,
}

export const settingSection: ISettingSection[] = [
  {
    title: 'My Account',
    setting: 'account',
    Component: <AccountSetting />,
   }
];

const SettingPage: FC = () => {
  // -- Get current setting from url
  let { setting } = useParams();

  let curSectionIndx = settingSection.findIndex((section) => section?.setting === setting);
  curSectionIndx = curSectionIndx <= 0 ? 0 : curSectionIndx;

  return (
    <MainLayout
      pageTitle="Settings"
      title="Settings"
    >
      <BodyWithSidebar
        title={settingSection[curSectionIndx]?.title}
        sidebar={settingSection.map(({ title, setting }, i) => (
          <NavLink
            key={setting}
            to={`/setting/${setting}`}
            className={`
              block rounded-lg px-4 py-2 w-full
              ${curSectionIndx === i ? 'bg-primary text-white' : ''}
            `}
          >
            {title}
          </NavLink>
        ))}
      >
        {settingSection[curSectionIndx]?.Component}
      </BodyWithSidebar>
    </MainLayout>
  );
};

export default SettingPage;
