import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from '../services/api';
import authSlice, { IauthInitState } from './authSlice';
import offerSlice, { IOfferInitState } from './offerSlice';
import quoteSlice, { IQuoteInitState } from './quoteSlice';
import statisticSlice, { IStatisticInitState } from './statisticSlice';

export interface IInitReduxState {
  authSlice?: IauthInitState;
  quoteSlice?: IQuoteInitState;
  offerSlice?: IOfferInitState;
  statisticSlice?: IStatisticInitState;
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  authSlice,
  quoteSlice,
  offerSlice,
  statisticSlice,
});

// Redux store with initial state (for testing)
export const createReduxStore = (preloadedState?: IInitReduxState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    preloadedState: preloadedState || {},
  });

export const store = createReduxStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
