import { toast } from 'react-hot-toast';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import { ILoginState } from '../pages/Login.page';
import { getErrorFromCatch } from '../services/api';
import { authApi } from '../services/auth.api';
import StorageService from '../services/storage.service';
import {
  removeUser,
  setUser
} from '../store/authSlice';
import { useTypedDispatch } from './useTypedDispatch';

export function useAuth(afterSuccess?: () => void) {
  const dispatch = useTypedDispatch();

  const [tryLogin, { isLoading: isLoginLoading }] = authApi.useLoginMutation();
  const [getMyself] = authApi.useLazyMeQuery();

  const setMyself = async () => {
    const { data } = await getMyself({});  

    return data
      ? dispatch(setUser(data))
      : dispatch(removeUser());
  };

  const unSetMyself = () => {
    dispatch(removeUser());
    storeTokens();
  };

  const handleLogin = async ({ login, password, token }: ILoginState ): Promise<boolean> => {
    try {
      const data = await tryLogin({ login, password, token }).unwrap();
      storeTokens(data?.accessToken, data?.refreshToken);
      await setMyself();
      return true;
    } catch (err) {
      const errMsg = getErrorFromCatch(err);
      toast.error(`${ERROR_MESSAGES['signin-failed']} ${errMsg}`, { duration: 8000 });
      return false;
    }    
  };

  const handleLogout = () => {
    unSetMyself();
  };

  const storeTokens = (access = '', refresh = ''): void => {
    StorageService.setToken('access', access);
    StorageService.setToken('refresh', refresh);
  };

  return {
    setMyself,
    unSetMyself,

    handleLogin,
    handleLogout,
  };
}
