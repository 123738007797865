import { FC, PropsWithChildren } from 'react';
import AdminNav from '../components/nav/AdminNav';
import { ILayout } from './ILayout';
import MainLayout from './Main.layout';

const AdminPanelLayout: FC<PropsWithChildren<ILayout>> = (props) => <MainLayout  
  {...props}
  topMainNav={<AdminNav />}
/>

export default AdminPanelLayout;
