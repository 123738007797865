import { FC, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IEditUser } from "../../model/IUser";
import { getCurrentUser } from "../../store/authSlice";
import Card from "../common/card/Card";
import Button from "../form/Button";
import Input, { TInputEvent } from "../form/Input";
import { useUser } from "../../hooks/useUser";
import toast from "react-hot-toast";
import { INFO_MESSAGES } from "../../messages/infoMessages";
import { ERROR_MESSAGES } from "../../messages/errorMessages";

const AccountSetting: FC = () => {
  const { editUserInfo } = useUser();

  // -- Get current user
  const user = useTypedSelector(getCurrentUser());

  // -- States
  const [accountState, setAccountState] = useState<IEditUser>({
    name: user?.name || '',
    phone: user?.phone || '',
    currentPassword: '',
    password: '',
    passwordConfirm: '',
  });
  const [errAccountState, setErrAccountState] = useState<Partial<Record<keyof IEditUser, string>>>();
  const [isPending, setIsPending] = useState<boolean>(() => false);

  const handle = (key: keyof IEditUser) => (e: TInputEvent) => 
    setAccountState((prevState) => ({
      ...prevState,
      [key]: e?.target?.value,
    }))

  const handleUpdate = () => {
    setIsPending(() => true);

    user?.id && (async () => {
      const result = await editUserInfo(user.id, accountState);     
      
      if (!result) {
        setErrAccountState(() => undefined);
        return toast.success(INFO_MESSAGES['user-updated-himself']);
      }

      return typeof result === 'string'
        ? toast.error(`${ERROR_MESSAGES['user-pass-updated']} \n${result}`)
        : setErrAccountState(() => result);
    })();

    setIsPending(() => false);
  }

  return (
      <Card>
        <div className="grid grid-cols-12 gap-x-10 gap-y-4">
          {/* <div>
            <Label>Email</Label>
            {user?.email}
          </div> */}
          <Input
            label="Email"
            name="email"
            value={user?.email || ''} 
            disabled
            className='col-span-12'
          />
          <Input
            label="Full Name"
            name="name"
            placeholder='Your full name'
            value={accountState.name}
            handle={handle('name')}
            error={errAccountState?.name}
            className='col-span-6'
          />
          {/* <Input
            label="Phone number"
            name="phone"
            placeholder='Your phone number'
            value={accountState.phone}
            handle={handle('phone')}
            error={errAccountState?.phone}
            className='col-span-6'
          /> */}
          <Input
            label="Phone number"
            name="phone"
            value={user?.phone || ''} 
            disabled
            className='col-span-6'
          />
          <Input
            label="Current Password"
            name="currentPassword"
            type="password"
            value={accountState.currentPassword}
            handle={handle('currentPassword')}
            error={errAccountState?.currentPassword}
            className='col-span-12'
          />
          <Input
            label="New Password"
            name="password"
            type="password"
            value={accountState.password}
            handle={handle('password')}
            error={errAccountState?.password}
            className='col-span-6'
          />
          <Input
            label="New Password Confirm"
            name="passwordConfirm"
            type="password"
            value={accountState.passwordConfirm}
            handle={handle('passwordConfirm')}
            error={errAccountState?.passwordConfirm}
            className='col-span-6'
          />
        </div>
        <div className="flex justify-end mt-10 gap-4">
          <Button
            onClick={handleUpdate}
            isNotFullWidth
          >
            Update
          </Button>
        </div>
      </Card>
  );
}

export default AccountSetting;
