import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOfferCustomer, getOfferCustomerParams, setOfferCustomerParams, setOfferCustomerWebsite, setOfferCustomerZip } from "../../store/offerSlice";
import ClientSearchField from "../client/ClientSearchField";
import Input, { TInputEventHandle } from "../form/Input";
import { ZipField } from "../geo/ZipField";
import WebSiteSearchField from "../website/WebSiteSearchField";

// --
// -- Fill customer state of global offer state
// --
export default function EnterCustoremInfo() {
  const dispatch = useTypedDispatch();

  // -- Get global offer customer state 
  const offerCustomerState = useTypedSelector(getOfferCustomerParams());

  const handleField = (key: keyof IOfferCustomer): TInputEventHandle => (e) => {    
    dispatch(setOfferCustomerParams({
      ...offerCustomerState,
      [key]: e?.target?.value || '',
    }));
  }

  return (
    <div className="grid gap-2">
      <ClientSearchField
        label="Email"
        name="email"
        handle={handleField('email')}
        value={offerCustomerState.email}
        selectHandler={({
          // - Rows of chosen client (when onClick)
          email,
          name,
          zip,
          phone1,
          city,
          address,
          state,
          company,
        }) => 
          dispatch(setOfferCustomerParams({
            ...offerCustomerState,
            email,
            name,
            zip: zip || '',
            phone: phone1 || '',
            city: city || '',
            address: address || '',
            state: state || '',
            company: company || '',
          }))
        }
      />
      <WebSiteSearchField
        label="Website"
        name="website"
        handle={(e) => dispatch(setOfferCustomerWebsite(e?.target?.value || ''))}
        value={offerCustomerState.website}
      />
      <ZipField
        name="zip"
        label="Zipcode (required)"
        value={offerCustomerState.zip}
        handle={(e) => dispatch(setOfferCustomerZip(e?.target?.value || ''))}
      />
      {/* <WebSiteSearchField
        label="Website"
        name="website"
        handle={handleField('website')}
        value={offerCustomerState.website}
      /> */}
      <Input
        name="name"
        label="Customer Name"
        placeholder="Name"
        handle={handleField('name')}
        value={offerCustomerState.name}
      />
      <Input
        name="phone"
        label="Phone Number"
        placeholder="1-123-456-7890"
        handle={handleField('phone')}
        value={offerCustomerState.phone}
      />
    </div>
  );
}
