import { FC } from "react";
import { IOfferPriceInfo } from "../../model/IOfferPriceInfo";
import priceFormatter from "../../utils/priceFormatter";

const OfferCostTable : FC<{ info: IOfferPriceInfo }> = ({
  info: {
    partPrice, 
    shipping, 
    tax, 
    total,
  }
}) => (
  <div className=" border rounded-xxl shadow-md">
    <table className="min-w-full divide-y divide-gray-300">
      <tbody className="divide-y divide-gray-200 bg-white">
        <tr>
          <td className="whitespace-nowrap pl-4 py-2 w-[80px] text-sm text-gray-900">
            {partPrice ? priceFormatter(partPrice) : '-'}
          </td>
          <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-900">
            Part Price
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap pl-4 py-2 w-[80px] text-sm text-gray-900">
            {shipping ? priceFormatter(shipping) : '-'}
          </td>
          <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-900">
            Shipping
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap pl-4 py-2 w-[80px] text-sm text-gray-900">
            {tax ? priceFormatter(tax) : '-'}
          </td>
          <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-900">
            Sales Tax
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap pl-4 py-2 w-[80px] text-sm font-bold text-gray-900">
            {total ? priceFormatter(total) : '-'}
          </td>
          <td className="whitespace-nowrap px-1 py-2 text-sm font-bold text-gray-900">Total</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default OfferCostTable;
