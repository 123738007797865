/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import { FC } from "react";
import Modal from "../common/modal/Modal";
import { IOfferState } from "../../model/IOffer";
import Button from "../form/Button";
import { IPart } from "../../model/IPart";
import priceFormatter from "../../utils/priceFormatter";
import OfferCostTable from "./OfferCostTable";
import { IOfferPriceInfo } from "../../model/IOfferPriceInfo";
import ClientSearchField from "../client/ClientSearchField";
import { IZodErrorsState } from "../../utils/checkFormStateByZod";
import Input, { TInputEventHandle } from "../form/Input";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { IOfferCustomer, setOfferCustomerParams } from "../../store/offerSlice";
import { ZipField } from "../geo/ZipField";
import StateSelect from "../geo/StateSelect";
import WebSiteSearchField from "../website/WebSiteSearchField";
import SelectTemplate from "../template/SelectTemplate";
import DiscountInput from "../discount/DiscountInput";
import PartInformation from "../part/PartInformation";

const processTemplateText = (templateText?:string, offerState?:IOfferState) : string => {
  const regex = /\{customer_name\}/gi;
  const text = templateText?.replaceAll(regex, offerState?.customerName||'');
  return text || '';
}

const OfferFormTpl: FC<{  
  disabledFields?: string[],
  offerState: IOfferState,
  setOfferState: (value: React.SetStateAction<IOfferState>) => void,
  offerErrState?: Partial<IZodErrorsState<IOfferState>> | undefined,
  part: IPart,
  isSuccess: boolean,
  handleClose?: () => unknown,
  handleField: (key: keyof IOfferState) => TInputEventHandle,
  isPending: boolean,
  offerPriceInfo?: IOfferPriceInfo,
  offerCustomerState?: IOfferCustomer,
  onSendOffer: () => void,
  skipModal?: boolean,
}> = ({
  disabledFields,
  offerState,
  setOfferState,
  offerErrState,
  isSuccess,
  handleClose,
  handleField,
  isPending,
  part,
  offerPriceInfo,
  offerCustomerState,
  onSendOffer,
  skipModal = false,
}) => {
  const dispatch = useTypedDispatch();

  disabledFields
  return isSuccess
    ? (
      <Modal
        style={{ maxWidth: '450px' }}
        onClose={handleClose}
        skip={skipModal}
      >
        <div className="grid justify-center gap-8 mx-10 my-4">
          <div className="text-3xl text-center font-bold text-neutral-900">Offer Created!</div>
          <div className="mx-auto w-[100px] h-[100px] rounded-full bg-green-300 flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M4.6967 9.6967C6.10322 8.29018 8.01088 7.5 10 7.5H50C51.9891 7.5 53.8968 8.29018 55.3033 9.6967C56.7098 11.1032 57.5 13.0109 57.5 15V32.5C57.5 33.8807 56.3807 35 55 35C53.6193 35 52.5 33.8807 52.5 32.5V15C52.5 14.337 52.2366 13.7011 51.7678 13.2322C51.2989 12.7634 50.663 12.5 50 12.5H10C9.33696 12.5 8.70107 12.7634 8.23223 13.2322C7.76339 13.7011 7.5 14.337 7.5 15V45C7.5 46.3693 8.63071 47.5 10 47.5H30C31.3807 47.5 32.5 48.6193 32.5 50C32.5 51.3807 31.3807 52.5 30 52.5H10C5.86929 52.5 2.5 49.1307 2.5 45V15C2.5 13.0109 3.29018 11.1032 4.6967 9.6967Z" fill="#166534"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M2.89029 16.1592C3.63081 14.9938 5.1758 14.6495 6.34114 15.39L28.7582 29.6349C29.1309 29.867 29.5612 29.99 30.0003 29.99C30.4394 29.99 30.8697 29.867 31.2424 29.6349C31.2443 29.6338 31.2461 29.6326 31.248 29.6315L53.6595 15.39C54.8248 14.6495 56.3698 14.9938 57.1103 16.1592C57.8509 17.3245 57.5065 18.8695 56.3411 19.61L33.9027 33.8686C32.733 34.6014 31.3806 34.99 30.0003 34.99C28.62 34.99 27.2677 34.6014 26.098 33.8685L26.0845 33.8601L3.6595 19.61C2.49416 18.8695 2.14978 17.3245 2.89029 16.1592Z" fill="#166534"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M56.7678 40.7322C57.7441 41.7085 57.7441 43.2915 56.7678 44.2678L46.7678 54.2678C45.7915 55.2441 44.2085 55.2441 43.2322 54.2678L38.2322 49.2678C37.2559 48.2915 37.2559 46.7085 38.2322 45.7322C39.2085 44.7559 40.7915 44.7559 41.7678 45.7322L45 48.9645L53.2322 40.7322C54.2085 39.7559 55.7915 39.7559 56.7678 40.7322Z" fill="#166534"/>
            </svg>
          </div>
          <div className="text-center text-gray-800">
            Offer has been generated and sent to<br/>
            <strong>{offerState?.email}</strong>
          </div>
          <div className="">
            <Button onClick={handleClose} >
              Return To Sales Portal
            </Button>
          </div>
        </div>
      </Modal>
    ) : (
      <Modal
        onClose={handleClose}
        style={{ maxWidth: '857px' }}
        skip={skipModal}       
      >
        <div className="text-3xl font-bold mt-2">Create Offer</div>

        <div className={`flex w-full ${isPending ? 'animate-pulse' : ''}`}>
          <div className="mt-8 w-full grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-12">
            <div className="col-span-8">
              <PartInformation part={part} />
            </div>            
            <div className="flex flex-col col-span-4">
              <div className="text-lg text-slate-500 font-medium mb-6"></div>
              <div className="text-lg font-medium">
                {!!offerPriceInfo && <OfferCostTable info={offerPriceInfo} />}
                {!offerState?.zip && !!!offerPriceInfo && (
                  <div className="text-red-700 text-center text-xs pt-2">
                    Valid zip code required
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col col-span-12">
              <div className="text-lg text-slate-500 font-medium">Contact Information</div>
              <div className="w-full">
                <div className="my-2 px-6 py-4 w-full rounded-lg grid grid-cols-1 gap-x-12 gap-y-4 sm:grid-cols-6 bg-slate-100">
                  <div className="sm:col-span-3">
                    {!!offerCustomerState && !disabledFields?.includes('email')
                      ? <ClientSearchField
                          label="Customer Email (required)"
                          name="email"
                          handle={handleField('email')}
                          value={offerState.email}
                          error={offerErrState?.['email']}
                          selectHandler={({
                            // - Rows of chosen client (when onClick)
                            email,
                            name,
                            zip,
                            phone1,
                            city,
                            address,
                            state,
                            company,
                          }) => 
                            dispatch(setOfferCustomerParams({
                              ...offerCustomerState,
                              email,
                              name,
                              // zip: zip || '',
                              phone: phone1 || '',
                              city: city || '',
                              address: address || '',
                              state: state || '',
                              company: company || '',
                            }))
                          }
                        /> 
                      : <Input
                          label="Customer Email (required)"
                          name="email"
                          value={offerState.email}
                          disabled
                         />       
                      }                              
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      label="Customer Name (required)"
                      name="customerName"
                      value={offerState['customerName']}                      
                      disabled={isPending || disabledFields?.includes('customerName')}
                      handle={handleField('customerName')}
                      error={offerErrState?.['customerName']}               
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      label="Phone Number"
                      name="phone"
                      value={offerState['phone']}                      
                      disabled={isPending || disabledFields?.includes('phone')}
                      handle={handleField('phone')}
                      error={offerErrState?.['phone']} 
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {!!offerCustomerState && !disabledFields?.includes('zip')
                      ? <ZipField
                          label="Zipcode"
                          name="zip"
                          value={offerState['zip']}
                          defaultValue={offerCustomerState.zip}
                          disabled={isPending}
                          handle={handleField('zip')}
                          error={offerErrState?.['zip']} 
                          setCity={(city) => setOfferState((prevState) => ({
                            ...prevState,
                            city,
                          }))} 
                          setStateCode={(setStateCode) => setOfferState((prevState) => ({
                            ...prevState,
                            state: setStateCode,
                          }))}
                        />
                      : <Input
                          label="Zipcode"
                          name="zip"
                          value={offerState['zip']}                      
                          disabled={isPending || disabledFields?.includes('zip')}
                          handle={handleField('zip')}
                          error={offerErrState?.['zip']}               
                        />
                    }
                  </div>
                  <div className="sm:col-span-3">
                    {!disabledFields?.includes('state')
                      ? <StateSelect
                          label="State"
                          name="state"
                          value={offerState['state']}                      
                          disabled={isPending}
                          handle={handleField('state')}
                          error={offerErrState?.['state']} 
                        />
                      : <Input
                          label="State"
                          name="state"
                          value={offerState['state']}
                          disabled 
                        />
                    }
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      label="City"
                      name="city"
                      value={offerState['city']}                       
                      disabled={isPending || disabledFields?.includes('city')}
                      handle={handleField('city')}
                      error={offerErrState?.['city']} 
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      label="Street Address"
                      name="address"
                      value={offerState['address']}                      
                      disabled={isPending || disabledFields?.includes('address')}
                      handle={handleField('address')}
                      error={offerErrState?.['address']} 
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      label="Company"
                      name="company"
                      value={offerState['company']}
                      disabled={isPending || disabledFields?.includes('company')}
                      handle={handleField('company')}
                      error={offerErrState?.['company']} 
                    />
                  </div>
                  <div className="sm:col-span-3">
                  <WebSiteSearchField
                    label="Website (required)"
                    name="website"
                    disabled={isPending || disabledFields?.includes('company')}
                    handle={handleField('website')}
                    value={offerState['website']}
                    error={offerErrState?.['website']} 
                  />                    
                  </div>
                  <div className="sm:col-span-3">
                    <SelectTemplate
                      label="Template"
                      name="template"
                      disabled={isPending}
                      onSelect={(e) => setOfferState((prevState) => ({
                        ...prevState,
                        comments: processTemplateText(e, offerState),
                      }))}
                    />                    
                  </div>
                  <div className="sm:col-span-3">  
                    <DiscountInput
                      label="Discount"
                      name="discountId"
                      price={Number(part?.product?.price) || 0}
                      updateState={setOfferState}
                      keyState="discountId"                      
                      disabled={isPending || disabledFields?.includes('discountId')}
                      value={offerState['discountId'] || 0}
                      error={offerErrState?.['discountId']} 
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Text"
                      name="body"
                      type="redactor"
                      value={offerState['comments'] || ''} 
                      className='col-span-12 h-[200px]'
                      setState={(comments: string) => setOfferState({
                        ...offerState,
                        comments,
                      })}
                      disabled={isPending || disabledFields?.includes('comments')}
                      error={offerErrState?.['comments']}
                    />
                  </div>
                  <div className="sm:col-span-6 mt-6">
                    <div className="flex gap-2 justify-center">
                      <Button                        
                        onClick={onSendOffer}                  
                        disabled={isPending}
                        style={{ maxWidth: '200px' }}
                      >
                        Send Offer
                      </Button>
                    </div>          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
}

export default OfferFormTpl;
