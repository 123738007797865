import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IApiError } from "../../services/api";
import { geoApi } from "../../services/geo.api";
import Input, { IInput } from "../form/Input";

const StateSelect : FC<IInput> = ({ ...inputOptions }) => {
  const { data: states, isError, error, isFetching } = geoApi.useGetStatesQuery({});

  useEffect(() => {
    error && toast.error(`${(error as IApiError)?.status}: ${ERROR_MESSAGES['geo-state-loading']}`);
  }, [isError]); 

  return (
    <Input
      {...inputOptions}
      options={Array.isArray(states)
        ? [{
              title: 'Choose state',
              value: '',
            },
            ...states.map(({ shortName, name }) => ({
              title: String(name),
              value: shortName,
            }))
          ]      
        : []
      }
      disabled={isFetching}
    />
  );
}

export default StateSelect;