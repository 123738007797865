import { FC } from 'react';
import DiscountList from '../components/discount/DiscountList';
import AdminPanelLayout from '../layouts/AdminPanel.layout';
import GoalsSetting from '../components/setting/Golas.setting';

const GoalPage: FC = () => (
  <AdminPanelLayout
    pageTitle="Goals"
    title="Goals"
  >
    <div className="flex flex-wrap gap-12">
      <section className="w-full">
        <GoalsSetting />
      </section>  
    </div>      
  </AdminPanelLayout>
);

export default GoalPage;
