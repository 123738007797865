import { FC, useEffect } from "react";
import Input, { IInput } from "../form/Input";
import { vehicleApi } from "../../services/vehicle.api";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IApiError } from "../../services/api";

const VehicleYearSelect : FC<IInput> = ({ ...inputOptions }) => {
  const { data: years, isError, error, isFetching } = vehicleApi.useGetYearsQuery({});

  useEffect(() => {
    error && toast.error(`${(error as IApiError)?.status}: ${ERROR_MESSAGES['vehicle-year-loading']}`);
  }, [isError]);

  return (
    <Input
      {...inputOptions}
      options={Array.isArray(years)
        ? [ {
              title: 'Choose an year',
              value: '',
            },
            ...years.map((year) => ({
              value: year,
            }))
          ]      
        : []
      }
      disabled={isFetching}
    />
  );
}

export default VehicleYearSelect;