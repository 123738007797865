import { FC, PropsWithChildren } from "react";

const BodyWithSidebar: FC<PropsWithChildren<{
  sidebar: React.ReactNode,
  title?: string,
}>> = ({
  children,
  sidebar,
  title, 
}) => (
  <div className="flex flex-wrap gap-12">
    <section className="w-full grid grid-cols-12 gap-20">
      <div className="col-span-3 pt-4">
        {sidebar}
      </div>
      <div className="col-span-9 pt-4">
        {!!title && (
          <div className="text-xl font-semibold ml-2 mb-4">
            {title}
          </div>          
        )}
        {children}
      </div>
    </section>  
  </div>      
);

export default BodyWithSidebar;
