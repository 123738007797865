export default function LogoMini({
  size = 'medium',
  className,
} : {
  size?: 'small' | 'medium' | 'large',
  className?: string,
}) {
  return (
      <div className={`
        ${className || ''}
        inline-flex relative items-center    
      `}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={{ small: 25, medium: 34, large: 40 }[size]}
          height={{ small: 25, medium: 34, large: 40 }[size]}
          viewBox="0 0 40 39"
          fill="none"
        >
          <path d="M23.3831 29.9993C20.211 28.8211 16.9482 28.6398 13.9574 29.4555C11.8728 29.9993 9.87892 31.0869 8.06627 32.4464L6.7974 33.534L6.43488 33.8965L7.25056 34.6215C7.34119 34.6215 7.3412 34.7122 7.43184 34.8028C7.6131 34.9841 7.79438 35.0747 8.06627 35.256L8.61005 35.6185L9.15384 35.1653C9.24448 35.0747 9.42574 34.8934 9.60701 34.8028C11.2384 33.4433 13.051 32.537 14.9543 32.0838C17.4014 31.4494 20.0297 31.54 22.6581 32.537C24.8332 33.3527 29.2742 34.3496 34.6215 32.0838L34.8028 31.9932L35.0747 31.7213C35.0747 31.6307 35.1653 31.6307 35.256 31.54C35.7091 30.9962 36.1623 30.4525 36.5248 29.818L38.3375 27.0084L35.4372 28.6398C31.1775 30.9962 27.2803 31.4494 23.3831 29.9993Z" fill="black"/>
          <path d="M10.9665 20.211C13.4136 19.5766 16.0419 19.6672 18.6703 20.6642C21.5705 21.7517 29.0024 23.2925 37.3405 15.77L38.7 14.4105L38.7906 14.3199L39.1532 13.9574L38.9719 13.5042C38.8813 13.1417 38.7 12.6885 38.5187 12.2354L38.0656 10.9665L37.1592 11.8728C37.0686 12.0541 36.8874 12.1447 36.7061 12.326C32.7183 16.4044 26.5553 20.5735 19.4859 18.0358C16.3138 16.8576 13.0511 16.6763 10.0602 17.492C7.15996 18.3077 4.62225 19.8485 2.26581 22.0236V21.933L0.906325 23.3831C0.906325 23.4738 0.815709 23.4738 0.725077 23.5644L0.453163 23.8363L0.543801 24.1988C0.634433 24.652 0.815692 25.1051 0.906325 25.5583L1.45012 27.0084L2.35645 25.8302C2.44708 25.6489 2.62834 25.5583 2.71897 25.3771C4.62225 23.655 7.25059 21.208 10.9665 20.211Z" fill="black"/>
          <path d="M2.1752 18.5796C4.25974 16.4045 6.61616 15.045 9.15387 14.3199C11.6009 13.6855 14.2293 13.7761 16.8576 14.7731C25.0145 17.7639 32.0839 12.8698 35.1654 10.2414L36.3436 9.06323L36.7967 8.70068L36.4342 8.15691C36.1623 7.88501 35.9811 7.52248 35.7092 7.15995L35.1654 6.43488L34.5309 7.06931C34.4403 7.15995 34.259 7.3412 34.1684 7.43184C30.3618 11.0571 24.4707 14.7731 17.7639 12.2354C14.5918 11.0571 11.3291 10.8759 8.33818 11.6916C6.43491 12.2354 4.62225 13.1417 2.80961 14.3199L0.906323 15.8607C0.725059 16.0419 0.634426 16.1326 0.453162 16.3138L0.271914 16.4951V16.767C0.181281 17.4014 0.18127 18.0358 0.0906378 18.5796L0 20.8454L1.54076 19.214C1.72203 19.0328 1.9033 18.7609 2.1752 18.5796Z" fill="black"/>
          <path d="M26.0115 36.1623L25.8302 36.7061L26.0115 36.1623C25.7396 36.0717 25.5583 35.981 25.2864 35.8904C22.1143 34.7122 18.8515 34.5309 15.8607 35.3466C15.2262 35.5279 14.5918 35.7091 13.9574 35.981L11.7822 36.978L11.2384 37.2499L13.0511 37.8843C13.5949 38.0656 14.1386 38.2468 14.6824 38.4281L14.9543 38.5187L15.2262 38.4281C15.4981 38.3375 15.77 38.1562 16.0419 38.1562C16.2232 38.1562 16.4045 38.0656 16.5857 37.9749C18.7609 37.4312 21.1173 37.4311 23.655 38.2468C23.9269 38.3375 24.1082 38.4281 24.3801 38.5187L24.8333 38.7L25.1052 38.6094C25.7396 38.4281 26.4646 38.2468 27.0084 37.9749L29.5461 37.0686L26.9178 36.4342C26.6459 36.3436 26.2834 36.2529 26.0115 36.1623Z" fill="black"/>
          <path d="M39.8783 17.4014L38.5188 18.7609C38.3375 18.9421 38.1563 19.1234 37.975 19.3047C34.2591 22.8393 28.2773 26.6459 21.5705 24.1082C18.3984 22.9299 15.1356 22.7487 12.1448 23.5644C9.33518 24.3801 6.79745 25.8302 4.53165 28.0054L3.35344 29.2742L2.99091 29.7274L3.35344 30.2712C3.62533 30.6337 3.80661 30.9056 3.98787 31.2681L4.53165 32.0838L5.16608 31.2681C5.25672 31.1775 5.43797 30.9962 5.52861 30.9056C7.70378 28.5492 10.0602 27.099 12.7792 26.374C15.2263 25.7396 17.8546 25.8302 20.4829 26.8271C22.7487 27.6428 25.1052 27.9147 27.4616 27.6429C31.6307 27.1897 35.7998 24.8332 38.8813 22.2049C39.0626 22.0236 39.2438 21.933 39.4251 21.7518L39.697 21.4798V21.208C39.697 20.6642 39.7876 20.0297 39.7876 19.4859L39.8783 17.4014Z" fill="black"/>
          <path d="M5.43794 9.06323L5.16606 8.51943L5.43794 9.06323C5.98174 8.79133 6.61618 8.61005 7.15998 8.42879C9.60705 7.79436 12.2354 7.88499 14.8637 8.88195C16.5857 9.51638 18.3077 9.78827 20.211 9.78827C24.0176 9.78827 27.8241 8.42879 31.3588 5.80046L32.7183 4.71286L33.1714 4.35033L32.3558 3.7159C32.0839 3.444 31.7213 3.17211 31.3588 2.99085L30.815 2.62833L30.3618 2.99085C30.1806 3.08148 30.0899 3.26274 29.9087 3.35337C25.1052 6.97867 20.3017 7.97563 15.77 6.25362C12.5073 5.0754 9.2445 4.8035 5.98174 5.80046C5.70984 5.89109 5.43795 5.98171 5.07542 6.07234L4.89417 6.16298L4.62226 6.43487C4.62226 6.5255 4.53164 6.52551 4.44101 6.61614C4.07848 7.15994 3.6253 7.61311 3.26277 8.1569L1.54076 10.7852L4.35037 9.33511C4.80353 9.33511 5.16605 9.15386 5.43794 9.06323Z" fill="black"/>
          <path d="M12.8698 2.90022L13.1417 2.99086C14.3199 3.44402 16.1326 3.89718 18.3984 3.89718C20.7548 3.89718 23.0206 3.35338 25.2864 2.44706L28.096 1.08757L26.1928 0.453162C25.649 0.271897 25.1052 0.0906323 24.4707 0H24.1988L23.927 0.0906102C23.6551 0.181242 23.3831 0.362529 23.1112 0.453162C20.3923 1.45012 17.6733 1.63138 15.045 0.906323C14.7731 0.815691 14.5012 0.725042 14.2293 0.63441L13.9574 0.543772L13.6855 0.63441C13.1417 0.815674 12.5979 0.99695 12.0541 1.26885L9.96956 2.17517L12.1448 2.80958C12.326 2.71895 12.5979 2.80959 12.8698 2.90022Z" fill="black"/>
        </svg>        
      </div>

  );
}