import { useState, type CSSProperties, type PropsWithChildren } from "react";
import styles from './DropDown.module.scss';

export default function DropDown({
  children,
  btn,
  direction = 'right',
  size = 'medium',
  noOutline = false,
  style,
  isShown: isShownDefault = false,
} : PropsWithChildren<{
  btn: React.ReactNode, 
  direction?: 'right' | 'left',
  size?: 'small' | 'medium' | 'large',
  noOutline?: boolean,
  style?: CSSProperties,
  isShown?: boolean,
}>) {
  const [isShown, setIsShown] = useState<boolean>(isShownDefault);
  document.addEventListener("click", () => setIsShown(() => false));

  return (
    <div className={`${styles.dropdown}`}>
      <div
        className={`
          ${isShown ? styles.shown : ''}
          ${styles.dropbtn} ${styles[size]} ${noOutline ? styles.noOutline : ''}
        `}
        onClick={(e) => {
          e.stopPropagation();
          setIsShown((prevState) => !prevState);
        }}
      >
        {btn}
      </div>
      <div
        className={`
          ${isShown ? styles.shown : ''}
          ${styles.dropdownContent} ${styles[direction]} ${styles[size]}
        `}
        onClick={(e) => e.stopPropagation()}
        {...(style ? { style } : {} )}
      >
        {children}
      </div>
    </div>
  );
}