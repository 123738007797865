import { z } from "zod";
import { ERROR_MESSAGES } from "../messages/errorMessages";
import { IOfferState } from "../model/IOffer";
import { offerApi } from "../services/offer.api";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { getErrorFromCatch } from "../services/api";
import { IReOffer } from "../model/IReOffer";

export function useOffer() {
  // -- Data checking schemes
  const offerRequestSchema = z.object({
    email: z
      .string()
      .email(ERROR_MESSAGES['offer-email-unsuitable'])
      .min(1, ERROR_MESSAGES['offer-no-email']),
    website: z
      .string()
      .min(4, ERROR_MESSAGES['offer-no-website']),
    discountId: z
      .number({ required_error: ERROR_MESSAGES['offer-no-discount']})    
      // .transform((val) => val === 0 ? null : val),
      .nullable(),
    productId: z    
      .number()
      .positive(ERROR_MESSAGES['offer-no-product-id']),
    customerName: z
      .string()
      .min(3, ERROR_MESSAGES['offer-short-client-name'])
      .min(1, ERROR_MESSAGES['offer-no-client-name']),    
    // zip: z
    //   .string()
    //   .min(3, ERROR_MESSAGES['offer-short-zip'])
    //   .min(1, ERROR_MESSAGES['offer-no-zip']),
    sendEmail: z
      .boolean({ required_error: ERROR_MESSAGES['offer-no-send-email-info'] }),
  } as Record<keyof IOfferState, any>);

  const reOfferRequestSchema = z.object({
    discountId: z
      .number({ required_error: ERROR_MESSAGES['offer-no-discount']})    
      .nullable(),    
    sendEmail: z
      .boolean({ required_error: ERROR_MESSAGES['offer-no-send-email-info'] }),
  } as Record<keyof IOfferState, any>);

  // -- Methods
  const [tryToSendOffer] = offerApi.useAddOfferMutation();
  const [tryToReSendOffer] = offerApi.useReOfferMutation();

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const sendOffer = async (offerState: IOfferState): Promise<IZodErrorsState<IOfferState> | string | false> => {    
    // 1. Check data
    const errs = checkFormStateByZod(offerRequestSchema, offerState);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToSendOffer({
        ...offerState,
        discountId: offerState?.discountId || null, 
      }).unwrap();
      return false;
    } catch (err) {     
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const reSendOffer = async (offerState: IReOffer): Promise<IZodErrorsState<IReOffer> | string | false> => {    
    // 1. Check data
    const errs = checkFormStateByZod(reOfferRequestSchema, offerState);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToReSendOffer({
        ...offerState,
        discountId: offerState?.discountId || null, 
      }).unwrap();
      return false;
    } catch (err) {     
      return getErrorFromCatch(err);
    }
  };

  return {
    sendOffer,
    reSendOffer,
  };
}