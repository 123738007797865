import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '../layouts/Main.layout';
import { userApi } from '../services/user.api';
import Loading from '../components/common/loading/Loading';
import Empty from '../components/common/empty/Empty';
import HeadlineSection from '../components/common/headline/HeadlineSection';
import parseISOToString from '../utils/parseISOToString';
import toast from 'react-hot-toast';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import { getErrorFromCatch } from '../services/api';

const UserDetailPage: FC = () => {
  // -- Get user Id from URl params
  let { userId } = useParams();

  // -- Load user details using user id
  const { data: user, isFetching, isError, error } = userApi.useGetUserQuery(userId || '');
  
  // -- Api Error handler
  useEffect(() => {
    isError && error && toast.error(`${ERROR_MESSAGES['user-details']} ${getErrorFromCatch(error)}`);
  }, [isError]);  

  return (
    (!!isFetching && <Loading />)
    || (!user && <Empty />)
    || (
      <MainLayout
        pageTitle={user?.email}
        title={`${user?.email}${user?.name ? ` (${user.name})` : ``}`}
        breadcrumbs={[
          { name: 'Managers', href: '/managers' },
          { name: `${user?.email}${user?.name ? ` (${user.name})` : ''}` },
        ]}
      >
        <div className="flex flex-wrap gap-12">
          <section className="w-full">
            <HeadlineSection>Last Activity</HeadlineSection>
            {!user?.ip && !user?.lastLoginTime
              ? 'There is not any activity yet'
              : (
                <>
                  {user?.ip ? <div>IP: {user.ip}</div> : ''}
                  {user?.lastLoginTime ? <div>{parseISOToString(user.lastLoginTime)}</div> : ''}
                </>
              )              
            }            
          </section>  
        </div>      
      </MainLayout>
  ));
}
export default UserDetailPage;
