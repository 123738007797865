/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useOffer } from "../../hooks/useOffer";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { INFO_MESSAGES } from "../../messages/infoMessages";
import { IOfferState } from "../../model/IOffer";
import { IQuote } from "../../model/IQuote";
import { IZodErrorsState } from "../../utils/checkFormStateByZod";
import { TInputEventHandle } from "../form/Input";
import OfferFormTpl from "./OfferFormTpl";
import { useDiscount } from "../../hooks/useDiscount";

const ReOfferForm : FC<{
  quote: IQuote,
  skipModal?: boolean,
  handleClose?: () => unknown
}> = ({
  quote,
  skipModal = false,
  handleClose,
}) => {
  const { reSendOffer } = useOffer();
  const { activeDiscounts, getDiscountByAmount } = useDiscount();

  // // -- Offer state
  const [isPending, setIsPending] = useState<boolean>(() => false);
  const [isSuccess, setIsSuccess] = useState<boolean>(() => false);
  const [offerErrState, setOfferErrState] = useState<Partial<IZodErrorsState<IOfferState>>>();
  const [offerState, setOfferState] = useState<IOfferState>({
    productId: 0,
    email: quote?.client?.email || '',
    customerName: quote?.client?.name || '',
    zip: quote?.yardZip || '',
    name: '',
    website: quote?.website || '',
    company: quote?.client?.company || '',
    state: quote?.client?.shippingAddress?.[0]?.state || '',
    city: quote?.client?.shippingAddress?.[0]?.city || '',
    address: quote?.client?.shippingAddress?.[0]?.address || '',
    phone: quote?.client?.phone1 || quote?.client?.phone2 || '',
    comments: '', 
    discountId: 0, // quote?.discount
    sendEmail: true,
  });

  // -- Set discount id using amount
  useEffect(() => {
    if (activeDiscounts?.length) {
      const discount = getDiscountByAmount(quote?.discount, activeDiscounts);
      discount && setOfferState((prevState) => ({
        ...prevState,
        discountId: discount?.id || 0,
      }));
    } 
  }, [activeDiscounts]);

  const handleField = (key: keyof IOfferState): TInputEventHandle => (e) => 
    offerState && setOfferState({
        ...offerState,
        [key]: e?.target?.value || '',
    });

  const onSendOffer = () => {
    setIsPending(() => true);

    (async () => {
      const result = await reSendOffer({
        ...offerState,
        id: quote.id,
      });
      
      setIsPending(() => false);
      if (!result) {
        toast.success(INFO_MESSAGES['offer-create']);
        return setIsSuccess(() => true);
      }

      return typeof result === 'string'
        ? toast.error(`${ERROR_MESSAGES['offer-create-failed']} ${result}`)        
        : setOfferErrState(() => result);
    })();
  }

  return (
    <OfferFormTpl
      skipModal={skipModal}
      disabledFields={[
        'productId',
        'email',
        'customerName',
        'zip',
        'name',
        'website',
        'company',
        'state',
        'city',
        'address',
        'phone',
      ]}
      offerState={offerState}
      setOfferState={setOfferState}
      offerErrState={offerErrState}
      part={{
        year: quote?.carYear,
        section: {
          name: quote?.productName,
          code: quote?.sectionCode,
        },      
        carMake: {
          name: quote?.carMakeXkey,
        },
        carModel: {
          name: quote?.carModelXkey,
        },
        product: {
          partNumber: quote?.productNumber,
          description: quote?.productDescription,
          interchangeNumber: quote?.productInterchange,
          price: quote?.productRawPrice || 0,
        } 
      }}
      isSuccess={isSuccess}
      handleClose={handleClose ?? undefined}
      handleField={handleField}
      isPending={isPending}
      offerPriceInfo={{
        partPrice: Number(quote?.productRawPrice) || undefined,
        shipping: Number(quote?.productRawShipping) || undefined, 
        total: Number(quote?.productRawShipping+quote?.productRawPrice)
      }}
      onSendOffer={onSendOffer}
    />
  );
}

export default ReOfferForm;