type tokenName = 'access' | 'refresh';

export default class StorageService {
  static getToken = (name: tokenName): string => StorageService.getItem(`${name}Token`);

  static setToken = (name: tokenName, token: string): void =>
    StorageService.setItem(`${name}Token`, token);

  static removeToken = (name: tokenName): void => StorageService.removeItem(`${name}Token`);

  static getItem = (name: string): string => localStorage.getItem(name) ?? '';

  static setItem = (name: string, value: string): void => localStorage.setItem(name, value);

  static removeItem = (name: string): void => localStorage.removeItem(name);
}
