import { IQuote } from '../model/IQuote';
import { TQuoteFilterState } from '../store/quoteSlice';
import { IPageParams } from './IApi';
import { IPagesResponse } from './IPagesResponse';
import api from './api';

export const quoteApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuotes: build.query<IPagesResponse<IQuote[]>, IPageParams & TQuoteFilterState>({
      query: ({ sort, sortDir, ...params }) => ({
        url: 'quotes',
        params: {
          ...params,
          sort: sort ? `${sort},${sortDir}` : 'created,desc',
        },
      }),
    }),
  }),
});
