import { FC } from 'react'

export interface ITab {
  name: string,
  icon?: React.ReactNode,
  current: boolean,
  onClick: (name: string) => unknown,
}

const tabs: ITab[] = [
  { name: 'My Account', onClick: () => {}, icon: <>1</>, current: false },
  { name: 'Company', onClick: () => {}, icon: <>2</>, current: false },
  { name: 'Team Members', onClick: () => {}, icon: <>3</>, current: true },
  { name: 'Billing', onClick: () => {}, icon: <>4</>, current: false },
]

const Tabs : FC<{
  tabs: ITab[],
  style?: React.CSSProperties,
  className?: string,
}> = ({
  tabs,
  style,
  className,
}) => (
  <div
    {...(style ? { style } : {})}
    className={`${className || ''}`}
  >
    <div className="sm:hidden">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        id="tabs"
        name="tabs"
        className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
        defaultValue={tabs.find(({ current }) => current)?.name || tabs[0]?.name}
      >
        {tabs.map((tab) => (
          <option key={tab.name}>{tab.name}</option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map(({ name, current, icon, onClick }) => (
            <span
              role="button"
              tabIndex={-1}
              key={name}
              onClick={() => onClick && onClick(name)}
              className={`
                ${current
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }
                group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium
                gap-2 cursor-pointer
                
              `}
              aria-current={current ? 'page' : undefined}
            >
              {icon}
              {/* <tab.icon
                className={classNames(
                  tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              /> */}
              {name}
            </span>
          ))}
        </nav>
      </div>
    </div>
  </div>
);

export default Tabs;
