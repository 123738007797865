import { FC } from "react";
import { offerApi } from "../../services/offer.api";
import Input, { IInput } from "../form/Input";

interface IUpdateState<T> {
  updateState: React.SetStateAction<T>,
  keyState: keyof T,
}

const DiscountInput : FC<IInput & IUpdateState<any> & { price?: number }> = ({
  updateState,
  keyState,
  value,
  price = 0,
  ...options
}) => {  
  // -- Get discount information
  const { data: discounts } = offerApi.useGetActiveDiscountsQuery({}); 
  
  return <Input
    {...options}
    options={
      [ 
        { 
          title: '$0',
          value: 0,
        },
        ...(Array.isArray(discounts) ? discounts : [])
          .filter(({ limitAmount }) => +limitAmount <= +price)
          .map(({ id, amount }) => ({
            title: `$${amount}`,
            value: id,
          })
        )
      ]
    }
    // options={CONFIG.discounts.map((i) => ({
    //   title: `${~~(i * 100)}%`,
    //   value: i,
    // }))}
    value={String(value || 0)}
    setState={(newValue) => updateState((prevState: any) => ({
      ...prevState,
      [keyState]: Number(newValue) || 0,
      }))   
    }
    disabled={!price}
  />;
}

export default DiscountInput;
