import { z } from "zod";
import { ERROR_MESSAGES } from "../messages/errorMessages";
import { IDiscount } from "../model/IDiscount";
import { getErrorFromCatch } from "../services/api";
import { offerApi } from "../services/offer.api";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { IDiscountRow } from "../components/discount/DiscountList";

export function useDiscount() {

  // -- Discount checking scheme
  const newDiscountRequestSchema = z.object({
    amount: z
      .number()
      .min(1, ERROR_MESSAGES['discount-amount-error']),
    limitAmount: z
      .number()
      .min(1, ERROR_MESSAGES['discount-limit-error']),
  });

  // // -- Methods
  const [tryToAddDiscount] = offerApi.useAddDiscountMutation();
  const [tryToUpdateDiscount] = offerApi.useUpdateDiscountMutation();
  const { data: activeDiscounts } = offerApi.useGetActiveDiscountsQuery({});
  // const [tryToEditDiscount] = offerApi.useEditUserMutation();

  const addDiscount = async (discount: IDiscountRow): Promise<IZodErrorsState<IDiscountRow> | string | false> => {  
    // 1. Check data
    const errs = checkFormStateByZod(newDiscountRequestSchema, discount);
    if (Object.values(errs)?.length) return errs;  
    
    // - 2. Send data
    try {
      await tryToAddDiscount(discount).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  };

  const updateDiscount = async (discount: { id: number } & Partial<IDiscount>) => {
    try {
      // await tryToUpdateDiscount({ ...discount, active: !!discount?.deleted }).unwrap();
      await tryToUpdateDiscount(discount).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  }

  const getDiscountByAmount = (amount: number, discounts: IDiscount[]): IDiscount | undefined => discounts
    .find(({ amount: amountOriginal }) => +amountOriginal === amount)

  return {
    addDiscount,
    updateDiscount,
    activeDiscounts,
    getDiscountByAmount,
  };
}