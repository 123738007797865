import { FC } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getCurrentUser } from "../../store/authSlice";
import getIinitialsName from "../../utils/getIinitialsName";
import DropMenu from "../common/dropmenu/DropMenu";

export const UserMenu: FC = () => {
  const { handleLogout } = useAuth();
  const user = useTypedSelector(getCurrentUser());

  return (
    <DropMenu
      links={[
        { name: 'Sales Portal', to: '/portal' },
        { name: 'Customer Order Offer', to: '/offer' },
        { name: 'Custom Templates', to: '/admin/template' },
        { name: 'Settings', to: '/setting' },
        { isSeparator: true },
        { name: 'Log out', onClick: handleLogout },
      ]}
    >
      <div className="flex gap-4 m-2 max-w-[250px]">
        <div className="flex items-center">
          <div className="flex w-[30px] h-[30px] rounded-full bg-gray-400 text-white items-center justify-center text-xs font-medium">
            {getIinitialsName(user?.name)}
          </div>
        </div>
        <div className="grid">
          <div className="text-neutral-900 text-sm font-medium">{user?.name}</div>
          <div className="text-gray-700 text-xs">{user?.email}</div>
        </div>
        <div className="flex items-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="black" />
          </svg>
        </div>
      </div>

    </DropMenu>
  );
};
