import { FC, useEffect } from "react";
import Input, { IInput, TInputEvent } from "../form/Input";
import { vehicleApi } from "../../services/vehicle.api";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { IApiError } from "../../services/api";

const VehicleMakeSelect : FC<IInput & { year: string }> = ({
  year,
  value,
  handle,
  ...inputOptions
}) => {
  const { data: makes, isError, error, isFetching, isSuccess } = vehicleApi.useGetMakesQuery(year);

  useEffect(() => {
    error && toast.error(`${(error as IApiError)?.status}: ${ERROR_MESSAGES['vehicle-makes-loading']}`);
  }, [isError]);

  useEffect(() => {
    isSuccess
    && Array.isArray(makes)
    && value
    && !makes.find(({ id }) => +id === +value)
    && handle
    && handle({ target: { value: '' }} as TInputEvent);    
  }, [makes]);

  return (
    <Input
      {...inputOptions}
      options={Array.isArray(makes)
        ? [ {
              title: 'Choose a make',
              value: '',
            },
            ...makes.map(({ id, name }) => ({
              title: name,
              value: +id,
            }))
          ]
        : []
      }
      disabled={isFetching}
      value={isFetching ? '' : value}
      handle={handle}
    />
  );
}

export default VehicleMakeSelect;
