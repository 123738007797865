import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { defaultOfferPartsPageParams, getOfferVehicleParams } from "../../store/offerSlice";
import { partApi } from "../../services/part.api";
import { IPart } from "../../model/IPart";
import { ERROR_MESSAGES } from "../../messages/errorMessages";
import { getErrorFromCatch } from "../../services/api";
import toast from "react-hot-toast";

const VehiclePart : FC = () => {
  // -- Get search and page parameters from global offer state
  const vehicle = useTypedSelector(getOfferVehicleParams());
  // const { interchangeNumber, ...vehicle } = useTypedSelector(getOfferVehicleParams());

  // -- Get loading data method
  const [getParts] = partApi.useLazyGetPartsQuery();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [typicalPart, setTypicalPart] = useState<IPart>();

  // --
  // -- Vehicle flow search
  useEffect(() => {
    if (vehicle.makeId
    && vehicle.modelId
    && vehicle.partTypeId
    && vehicle.year)
      (async () => {
        setIsPending(() => true);
        try {
          const parts = await getParts({
            ...vehicle,
            pageParams: defaultOfferPartsPageParams,
          }).unwrap();
          setTypicalPart(() => parts?.content?.[0] || undefined);
          setIsPending(() => false); 
        } catch (err) { 
          toast.error(`${ERROR_MESSAGES['parts-loading']} ${getErrorFromCatch(err)}`, { duration: 8000 });
        } finally {
          setIsPending(() => false);
        }
      })()
    else {
      setTypicalPart(() => undefined);
    }          
  }, [
    vehicle.makeId,
    vehicle.modelId,
    vehicle.partTypeId,
    vehicle.year,
    vehicle.interchangeNumber,
  ]);

  return (
    <>
      {typicalPart && Object.values(typicalPart)?.length
        ? (
          <div className="flex text-sm font-medium gap-4 items-center">      
            {typicalPart?.section?.code && (
              <img
                src={`https://automotix.net/images/products_small/lkq/${typicalPart.section.code.toLowerCase()}_category.jpg`}
                alt={typicalPart?.section?.category}
                width={100}
                height={100}
              />
            )}
            <div>
              {typicalPart?.year || ''} {typicalPart?.carMake?.name || ''} {typicalPart?.carModel?.name || ''} {typicalPart?.section?.name || ''}<br/>
              {(vehicle.interchangeNumber && typicalPart?.product?.description) || ''}
            </div>
          </div>
          )
        : <div className="">No data</div>
      }      
    </>
  );
}

export default VehiclePart;