import { IPageParams } from "../../../services/IApi";
import { IApiPagable } from "../../../services/IPagesResponse";
import randomIntFromInterval from "../../../utils/randomIntFromInterval";
import EmptyTable from "../empty/EmptyTable";
import TableSkeloton from "../skeletons/TableSkeloton";
import TablePagination from "./TablePagination";
import TableThead from "./TableThead";

export type TUpdatePageParams = (newParams: Partial<IPageParams>) => void;

export interface ITableTh {
  title?: string,
  sortCol?: string,
}

export interface ITableThead {
  thead: ITableTh[],
  isPending?: boolean,
  pageParams?: IPageParams,
  updatePageParams?: TUpdatePageParams,
}

export default function Table({
  size = 'medium',
  isPending = false,
  isDisablePaginate = false,
  thead,
  tbody,
  options,
  pageParams,
  updatePageParams,
  pagable,
  
} : {
  size?: 'small' | 'medium',
  isPending?: boolean,  
  thead?: ITableTh[],
  tbody?: Array<Array<React.ReactNode|string|number>>,
  options?: Array<React.ReactNode|string>,   

  pageParams?: IPageParams, // Params of current pagable state
  updatePageParams?: TUpdatePageParams, // Method for updating a current pagable state
  pagable?: IApiPagable, // Current pagable info from the API
  isDisablePaginate?: boolean, // Pagination works only with pagable, pageParams, updatePageParams
}) {  

  return (
    <div className="max-w-7xl">
      {!!options?.length &&
        <div className="flex justify-between">
          {options.map(( option ) => <div key={Math.random()}>{option}</div>)}
        </div>
      }
      <div className="mt-4 flow-root px-4 sm:px-6 lg:px-8 ">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {/* <TableSkeloton rows={5} cols={thead?.length} />  */}
            {!tbody?.length
              ? isPending
                  ? <TableSkeloton rows={randomIntFromInterval(3, 8)} cols={thead?.length}  />
                  : <EmptyTable />
              : (
                <table className="min-w-full border-separate border-spacing-0 shadow-md">
                  {!!thead?.length && (
                    <TableThead
                      isPending={isPending}
                      thead={thead}
                      pageParams={pageParams}
                      updatePageParams ={updatePageParams}
                    />
                  )}
                  <tbody className={`${isPending ? 'opacity-40 animate-pulse' : ''}`}>
                    {tbody.map((row, rowIndx) => !!row?.length && (
                      <tr key={`row_${rowIndx}`}>
                        {row.map((cell, cellIndx) => (
                          <td
                            key={`row_${rowIndx}_${cellIndx}`}
                            className={`
                              whitespace-pre-line border-b bg-white border-gray-200 font-medium text-black
                              ${{
                                'small':  'py-1 pl-1 pr-1 text-xs sm:pl-2 lg:pl-3',
                                'medium': 'py-4 pl-4 pr-3 text-sm sm:pl-6 lg:pl-8',
                              }[size]}                              
                            `}
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>                    
                    ))}
                  </tbody>
              </table>
              )}
              {!isDisablePaginate && updatePageParams && pagable && (
                <TablePagination
                  pagable={pagable}
                  updatePageParams={updatePageParams}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}