import { useEffect, useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { customerApi } from "../../services/customer.api";
import { IOfferVehicle, getOfferCustomerParams, getOfferVehicleParams, setOfferVehicleParams } from "../../store/offerSlice";
import validateEmail from "../../utils/validateEmail";
import Input, { TInputEventHandle } from "../form/Input";
import PartTypeSelect from "../part/PartTypeSelect";
import VehicleMakeSelect from "../vehicle/VehicleMakeSelect";
import VehicleModelSelect from "../vehicle/VehicleModelSelect";
import VehicleYearSelect from "../vehicle/VehicleYearSelect";

// --
// -- Fill vehicle state of global offer state
// --
export default function EnterVehicleInfo() {
  const dispatch = useTypedDispatch();

  // -- Get global offer customer state 
  const offerVehicleState = useTypedSelector(getOfferVehicleParams());
  const offerCustomerState = useTypedSelector(getOfferCustomerParams());

  // -- Updating global offer state methods
  const udpateStateRows = (rows: Partial<Record<keyof IOfferVehicle, string>>) =>
    dispatch(setOfferVehicleParams({
      ...offerVehicleState,
      ...rows,
      pageParams: offerVehicleState.pageParams,
      interchangeNumber: undefined,
    }));

  const handleField = (key: keyof IOfferVehicle): TInputEventHandle => (e) =>
    udpateStateRows({ [key]: e?.target?.value || '' });

  // -- Remove unsuitable dependent values from global offer state
  useEffect(() => {
    !offerVehicleState.year && udpateStateRows({ makeId: undefined })
  }, [offerVehicleState.year]);
  useEffect(() => {
    !offerVehicleState.makeId && udpateStateRows({ modelId: undefined })
  }, [offerVehicleState.makeId]);

  // -- Update last queries list where customer email changes
  const [currLastQueryIndx, setCurrLastQueryIndx] = useState<number>(0);

  const { data: lastQueries, isLoading: lastQueriesLoading } = customerApi.useLastQueriesQuery(
    String(offerCustomerState?.email),
    { skip: !validateEmail(offerCustomerState?.email) }
  );

  // -- Apply selected last query
  useEffect(() => {
    const selectedQuery = currLastQueryIndx && lastQueries?.[currLastQueryIndx - 1];

    selectedQuery && udpateStateRows({
      year: String(selectedQuery?.viYear || ''),
      makeId: String(selectedQuery?.makeId || ''),
      modelId: String(selectedQuery?.modelId || ''),
      partTypeId: String(selectedQuery?.partTypeId || ''),
    })
  }, [currLastQueryIndx]);

  return (
    <div className="grid gap-2">
      <VehicleYearSelect
        label="Year (required)"
        name="year"
        handle={handleField('year')}
        value={offerVehicleState.year}
      />
      {offerVehicleState.year
        ? <VehicleMakeSelect
            label="Make (required)"
            name="makeId"
            year={offerVehicleState.year}
            handle={handleField('makeId')}
            value={offerVehicleState.makeId}          
          />
        : <Input
            label="Make (required)"
            name="makeId"
            disabled
          />
      }
      {offerVehicleState.makeId && offerVehicleState.year
        ? <VehicleModelSelect
            label="Model (required)"
            name="modelId"
            makeId={offerVehicleState.makeId}
            year={offerVehicleState.year}
            handle={handleField('modelId')}
            value={offerVehicleState.modelId}          
          />
        : <Input
            label="Model (required)"
            name="modelId"
            disabled
          />
      }
      <PartTypeSelect
        label="Part Type (required)"
        name="partTypeId"
        handle={handleField('partTypeId')}
        value={offerVehicleState.partTypeId}
      />
      <Input
        label="Last Queries"
        name="lastqueries"
        value={currLastQueryIndx}
        disabled={lastQueriesLoading || !!!lastQueries?.length}
        options={[
          {
            title: lastQueries?.length
              ? 'Choose last query'
              : 'There are no queries',
            value: 0
          },
          ...(lastQueries || []).map(({ viYear, viMake, viModel, name }, i) => ({
              title: `${viYear} ${viMake} ${viModel} ${name}`,
              value: i + 1,
            })
          )
        ]}
        handle={(e) => setCurrLastQueryIndx(() => +e?.target?.value > 0 ? +e?.target?.value : 0) }
      />
    </div>
  );
}
