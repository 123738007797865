import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loading from './app/components/common/loading/Loading';
import { useAuth } from './app/hooks/useAuth';
import { useTypedSelector } from './app/hooks/useTypedSelector';
import { getCurrentUser } from './app/store/authSlice';
import { siteMap } from './siteMap';
import { useAccess } from './app/hooks/useAccess';

function App() {
  const { setMyself } = useAuth();
  const user = useTypedSelector(getCurrentUser());
  const { getPageAccess } = useAccess();
  
  // 1. Get myself
  useEffect(() => {
    setMyself();    
  }, []);

  return user === undefined
    ? <Loading isfullScreen />
    : <RouterProvider
        router={createBrowserRouter(siteMap(getPageAccess))}
        fallbackElement={<> </>}
    />
}

export default App;
