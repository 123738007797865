/* eslint-disable no-mixed-operators */

import { Mutex } from 'async-mutex';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { removeUser } from '../store/authSlice';
import StorageService from './storage.service';
import ILogin from './ILogin';

const mutex = new Mutex();

export interface IApiError {
  status: number;
  data: {
    code: number;
    error: string;
  };
}

export const transformResponse = (response: any) => response.content;

export const getErrorFromCatch = (err: unknown) => {
  // if the respond has plane text type
  if ((err as { originalStatus: number })?.originalStatus === 200) return false; 

  return isFetchBaseQueryError(err) && (err?.data as { error: string })?.error
  || isErrorWithMessage(err) && err?.message
  || (err as { data: { message: string }})?.data?.message
  || 'unknown error';
}


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders: (headers) => {
    const accessToken = StorageService.getToken('access');
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
    headers.set('Sec-Fetch-Site', 'same-origin');

    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, IApiError>;

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, IApiError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  if (result.error && (result.error.status === 401 || result.error.status === 410)) {
    // try to get a new token
    const refreshTokenStored = StorageService.getToken('refresh');
    if (refreshTokenStored) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult: QueryReturnValue<unknown, IApiError, object> = await baseQuery(
            {
              url: 'oauth/refresh/',
              method: 'POST',
              body: { refresh: refreshTokenStored },
            },
            api,
            extraOptions,
          );

          if (refreshResult && !refreshResult?.error) {
            const { data } = refreshResult;
            const { accessToken, refreshToken } = (data as ILogin)
            StorageService.setToken('access', String(accessToken));
            StorageService.setToken('refresh', String(refreshToken));
            result = await baseQuery(args, api, extraOptions);
          } else api.dispatch(removeUser());
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    // else unSetMyself();
  }
  return result as QueryReturnValue<unknown, IApiError, {}>;
};

const api = createApi({
  reducerPath: 'motixcrm',
  baseQuery: baseQueryWithReauth,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  tagTypes: [
    'Users',
    'Templates',
    'AdminSettings',
    'Discounts',
  ],
});

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export default api;
