// import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { IUser } from '../model/IUser';
import { ILoginState } from "../pages/Login.page"
import ILogin from './ILogin';
import api, { transformResponse } from './api';
// import IApi from '../models/IApi';
// import ILogin from '../models/ILogin';
// import IUser from '../models/IUser';
// import IFileMeta from '../models/IFileMeta';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILogin, ILoginState>({
      query: ({ login, password, token }) => ({
        url: 'oauth/login',
        method: 'POST',
        body: { login, password, token },
      }),
    }),

    logout: build.mutation<string, unknown>({
      query: () => ({
        method: 'POST',
        url: '/oauth/logout',
      }),
      // invalidatesTags: ['Rules'],
    }),

    me: build.query<IUser, unknown>({
      query: () => ({
        url: '/users/me',
      }),
    }),
  }),
});
