// --
// -- Get plane groups tree, output it
// -- and change interchangeNumber state if click on a branch
// --

import { FC, useEffect, useState } from "react";
import { IPartGroup } from "../../model/IPartGroup";
import { IOfferVehicle, TOfferInterchange } from "../../store/offerSlice";
import Modal from "../common/modal/Modal";
import Button from "../form/Button";
import Loading from "../common/loading/Loading";

const PartGroupFilter : FC<{
  groupPlaneTree: IPartGroup[],
  vehicleParams: IOfferVehicle,  
  setCurrentInterchange?: (interchange: TOfferInterchange) => unknown,
  isShownDefault?: boolean,
  isPanding?: boolean,
}> = ({
  groupPlaneTree,
  vehicleParams,
  setCurrentInterchange,
  isShownDefault = false,
  isPanding = false,
}) => {
  
  const [isShown, setIsShown] = useState<boolean>(() => isShownDefault);

  // -- If there is only one tree branch - hide it
  useEffect(() => {
    groupPlaneTree?.length === 1 && setIsShown(() => false);
  }, [groupPlaneTree]);

  // -- If main search parameters changes - show new filter list
  useEffect(() => {
    +groupPlaneTree?.length > 1 && setIsShown(() => true);
  }, [
    vehicleParams.year,
    vehicleParams.partTypeId,
    vehicleParams.makeId,
    vehicleParams.modelId
  ]);

  // -- If there isn't tree then output nothing
  if (!groupPlaneTree || !groupPlaneTree?.length) return null;

  const getPathToGroup = (groupPlane: IPartGroup[], interchangeNum: string): string[] => {
    const path: string[] = [];
    
    const currentIndex = groupPlane.findIndex(({ interchangeNumber }) => interchangeNum === interchangeNumber);
    if (currentIndex === -1 || !groupPlane?.[currentIndex] || !groupPlane[currentIndex]?.treeLevel) return path;
    
    for (
      let level = +groupPlane[currentIndex]?.treeLevel, i = currentIndex - 1 ;
      level >= 1 && i >= 0 ;
      i -= 1
    ) {
      if (+groupPlane[i]?.treeLevel >= level) continue;
      path.unshift(groupPlane[i]?.application);
      level -= 1;
    }

    return [
      ...path,
      ...(groupPlane[currentIndex]?.application ? [groupPlane[currentIndex]?.application] : [])
    ];
  } 

  return (
    <div className="flex gap-2">
      <div className="flex gap-2 text-xs items-center">
        <button
          className="bg-primary text-white px-3 py-2 rounded-xl hover:bg-primary_hover"
          onClick={() => setIsShown(() => true)}
        >
          Select Sub Option ({ groupPlaneTree.reduce((acc, { interchangeNumber }) => interchangeNumber ? acc += 1 : acc, 0) })   
        </button> 
        {!!vehicleParams?.interchangeNumber && 
            getPathToGroup(groupPlaneTree, vehicleParams.interchangeNumber).map((path, i) => (
              <div key={path}>
                {!!(i != 0) && <span className="text-sm pl-0 pr-2">/</span>}
                <span className="px-3 py-2 bg-slate-200 rounded-xl">
                  {path}
                </span>
              </div>
            ))
        }
      </div>
      
      <Modal
        isShown={isShown}
        // isShown={false}
        setIsShown={setIsShown}
        title="Select Sub Option"
        style={{ maxWidth: '550px' }}
      > 
        {isPanding
          ? <Loading />
          : (
            <>
              <div className="flex">
                <div className="w-1 border-r-2 border-gray-300 border-dashed my-4" />
                <div className="flex flex-col">
                
                  {isPanding && <Loading />
                  || groupPlaneTree.map(({ application, interchangeNumber = '', treeLevel = 1 }, i) => (
                    <div
                      key={Math.random()}
                      className="flex items-center gap-2 "
                      style={{
                        fontSize: `${(2.5 / (+treeLevel * 0.5 + 1) <= 1) ? 1 : (2.1 / (+treeLevel * 0.5 + 1))}rem`,
                        marginTop: `${treeLevel === 1 && i ? '1rem' : ''}`
                      }}
                    > 
                      <div
                        style={{ width: `${+treeLevel}rem` }}
                        className="h-1 border-b-[2.5px] border-gray-300 border-dashed"
                      />
                        <button
                          className={interchangeNumber
                            ? vehicleParams?.interchangeNumber === interchangeNumber
                              ? 'text-gray-400 text-left cursor-default'
                              : 'text-primary underline underline-offset-2 text-left'
                            : ''
                          }                    
                          onClick={() => {
                            if (!interchangeNumber || interchangeNumber === vehicleParams?.interchangeNumber) return false;
                            setCurrentInterchange && setCurrentInterchange(interchangeNumber);
                            setIsShown(() => false);
                          }}
                        >
                          {application || 'unknown'}
                        </button>                
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex gap-6 mt-6">
                <Button
                  onClick={() => {
                    setCurrentInterchange && setCurrentInterchange('')
                    setIsShown(() => false);
                  }}
                >
                  Select No Sub Option
                </Button>
                <Button
                  onClick={() => setIsShown(() => false)}
                >
                  Close
                </Button>
              </div>
            </>
          )
        }
      </Modal>
    </div>
  );  
}

export default PartGroupFilter;
