import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import App from './App';
import ModalProvider from './app/hooks/useConfirm';
import { store } from './app/store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ModalProvider>
      <Toaster />
      <App />
    </ModalProvider>
  </Provider>,
);
