import { FC, useEffect, useState } from "react";
import { useAdminSettings } from "../../hooks/useAdminSettings";
import { IUpdateAdminSettingsRequest } from "../../model/IAdminSettings";
import { adminSettingsApi } from "../../services/adminsettings.api";
import Card from "../common/card/Card";
import Button from "../form/Button";
import Input, { TInputEvent } from "../form/Input";
import toast from 'react-hot-toast';
import { INFO_MESSAGES } from "../../messages/infoMessages";
import { ERROR_MESSAGES } from "../../messages/errorMessages";

const GoalsSetting: FC = () => {
  const { addSetting, updateSetting } = useAdminSettings();
  const { data: settingsList, refetch } = adminSettingsApi.useGetAdminSettingsQuery({});

  // -- States
  const [values, setValues] = useState<IUpdateAdminSettingsRequest[]>([]);
  const [isPending, setIsPending] = useState<boolean>(() => false);

  const handleChange = (name:  string) => (e: TInputEvent) => {
    const nextValues = values.map(setting => {
      if (setting.name === name) {
        return {
          ...setting,
          value: e?.target?.value
        };
      } else {
        return setting
      }
    });
    setValues(nextValues);
  }

  useEffect(() => {
    if  (settingsList && settingsList.length>0){
      setValues([...settingsList]);
    }
  },[settingsList]);

  const reset = ()=>{
    refetch();
    (settingsList && settingsList.length>0)?setValues([...settingsList]):setValues([]);
  }

  const handleUpdate = (name:  string) => async ()  => {
    setIsPending(() => true);
    const setting = values.find(c=>c.name===name);
    if (setting){
      const result = await updateSetting(setting);     
      setIsPending(() => false);

      if (!result) {
        return toast.success(INFO_MESSAGES['setting-update']);
      } 
      
      return toast.error(`${ERROR_MESSAGES['setting-update-error']} \n${result?result:ERROR_MESSAGES['unknown-error']}`);
    }        

    setIsPending(() => false);
  }

  return (
      <Card>
        <div>
          {[...(values || [])].map(({ name,value }, i) => (
            <div className="flex items-end mb-4" key={name}>
              <Input
                label={name}
                name={name}
                value={value} 
                handle={handleChange(name)}
              />
            {(settingsList?.find(c=>c.name===name))?.value!==value &&  
               <Button
                onClick={handleUpdate(name)}
                isNotFullWidth
                className="ml-2"
              >
                Update
              </Button>
            }
            </div>
          ))}

        </div>
        <Button
          onClick={reset}
          isNotFullWidth
        >
          Reset
        </Button>
      </Card>
  );
}

export default GoalsSetting;
