import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import { z } from 'zod';
import Button from '../components/form/Button';
import Input, { TInputEvent } from '../components/form/Input';
import Logo from '../components/logo/Logo';
import { useAuth } from '../hooks/useAuth';
import CenterLayout from '../layouts/Center.layout';
import { ERROR_MESSAGES } from '../messages/errorMessages';
import checkFormStateByZod from '../utils/checkFormStateByZod';

export interface ILoginState {
  login: string,
  password: string,
  token: string,
}

// -- Determine the form schema
const UserSchema = z.object({
  login: z
    .string({ required_error: ERROR_MESSAGES['login-field-empty'] })
    .email(ERROR_MESSAGES['login-field-no-email']),
  password: z
    .string({ required_error: ERROR_MESSAGES['password-field-empty']})
    .min(3, ERROR_MESSAGES['password-field-too-short']),
  token: z
    .string({required_error: ERROR_MESSAGES['token-empty']}),
});

const initState: ILoginState = {
  login: '',
  password: '',
  token: '',
};

const LoginPage: FC<{ redirect?: string }> = ({ redirect }) => {
  const [loginState, setLoginState] = useState<ILoginState>(() => initState);
  const [loginErrState, setLoginErrState] = useState<Partial<ILoginState>>(() => initState);
  const [isPending, setIsPending] = useState<boolean>(() => false);
  const [isPendingToken, setIsPendingToken] = useState<boolean>(() => false);
  const { handleLogin } = useAuth();
  const navigate = useNavigate();

  const handle = (key: keyof ILoginState) => (e: TInputEvent): void =>
    setLoginState((prevState) => ({
      ...prevState,
      [key]: e?.target?.value || '',
    }));

  // -- Generate and fill recaptcha token
  useEffect(() => {
    !isPending && (async () => {      
      setIsPendingToken(() => true);
      const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_KEY || '');
      const token = await recaptcha.execute(process.env.REACT_APP_LOGIN_ACTION || '');
      setLoginState((prevState) => ({
        ...prevState,
        token,
      }));
      setIsPendingToken(() => false);
    })();
  }, [isPending]);

  //
  const onLogIn = () => {
    // - 1. Check Errors or empty errors state
    const errs = checkFormStateByZod(UserSchema, loginState);
    if (Object.values(errs)?.length) {
      setLoginErrState(() => errs);
      toast.error(Object.values(errs).join('/n'));
      return false;
    }    
    setLoginErrState(() => errs);
    
    // - 2. Send data
    setIsPending(() => true);
    handleLogin(loginState).then((isSuccess) => {      
      if (isSuccess) {
        navigate(redirect || '/');
        return;
      }
      setLoginErrState(() => ({ login: ERROR_MESSAGES['signin-failed']}))
      setIsPending(() => false);
    });   
  }

  return (
    <CenterLayout
      isNoHeader      
      pageTitle="Sign In"
    >
      <div className="flex min-h-[100vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
          <Logo size="large" />
        </div>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[400px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6">        
              <Input
                label="Username"
                name="login"
                size="large"
                type="text"
                value={loginState.login}
                handle={handle('login')}
                disabled={isPending || isPendingToken}
                error={loginErrState?.login}
              />
              <Input
                label="Password"
                name="password"
                size="large"
                type="password"
                value={loginState.password}
                handle={handle('password')}
                error={loginErrState?.password}
                disabled={isPending || isPendingToken}
              />          
              <div className="flex items-center justify-between">
                {/* <div className="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary" />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">Remember me</label>
                </div> */}

                {/* <div className="text-sm leading-6">
                  <a href="#" className="text-primary hover:text-primary_hover">Forgot password?</a>
                </div> */}
              </div>
              <div>
                <Button
                  size="large"
                  onClick={onLogIn}
                  disabled={isPending || isPendingToken}
                >
                  Sign in
                </Button>              
              </div>
              <input type="hidden" name="token" value={loginState.token} />
            </form>
          </div>
        </div>
        <div className="grid justify-center text-xs text-slate-400 mt-10">
          <div className='text-center'>This site is protected by reCAPTCHA and the Google</div>
          <div className='text-center'><a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</div>         
        </div>        
      </div>
    </CenterLayout>
  );
}

export default LoginPage;
