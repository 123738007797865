export default function parseISOToString(iso: string): string {
  if (!iso) return '';

  const b = iso.split(/\D+/);
  const d = new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3], +b[4], +b[5], +b[6]));
  
  return Intl.DateTimeFormat('en-US', {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Chicago",
  }).format(d).toLowerCase();
}