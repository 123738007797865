import { FC, PropsWithChildren, useState } from "react";
import { NavLink } from "react-router-dom";

export interface IDropMenuLink {
  isSeparator?: boolean,
  name?: string,
  to?: string,
  onClick?: () => unknown,
  isCurrent?: boolean,
}

const DropMenu : FC<PropsWithChildren<{
  isShown?: boolean,
  links: IDropMenuLink[],
}>> = ({
  isShown: isShownDefault = false,
  children,
  links,
}) => {
  const [isShown, setIsShown] = useState<boolean>(isShownDefault);

  document.addEventListener("click", () => setIsShown(() => false));

  return (
    <div className="relative">
      <div
        role="button"
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation();
          setIsShown((prev) => !prev);
        }}
      >
        {children}
      </div>
      <div className={`
        absolute left-1/2 z-20 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 
        transition ease-out duration-200
        ${isShown ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1 hidden'}

      `}>
        <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
          {links.map(({ name, to, onClick, isSeparator = false }) => 
            (isSeparator && <div
                key={Math.random()}
                className="bg-slate-200 h-[1px] w-full my-1"
              />)
            || (to && <NavLink
                  key={`${to}-${name}`}
                  to={to}
                  {...(onClick ? { onClick } : {})}
                  className="block p-2 hover:text-primary hover:bg-slate-50 font-medium"
                >
                  {name || '-'}
                </NavLink>)
            || <span
                  key={Math.random()}
                  role="button"
                  tabIndex={-1}
                  {...(onClick ? { onClick } : {})}
                  className="block p-2 hover:text-primary hover:bg-slate-50 font-medium"
                >
                  {name || '-'}
                </span>
          )}
        </div>
      </div>    
    </div>
    
  );
}

export default DropMenu;
