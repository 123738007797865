import { FC } from "react";
import LogoMini from "../../logo/LogoMini";

const Loading : FC<{ isfullScreen?: boolean }>  = ({ isfullScreen }) => (
  <div className={`
    animate-pulse flex justify-center items-center w-full
    ${isfullScreen ? 'min-h-screen' : 'min-h-full' }
  `}>
    <div className="flex gap-2 ">
      <LogoMini
        size="small"
        className="animate-spin"
      />
      <div className="text-gray-600 text-sm">Loading</div>
    </div>

  </div>
);

export default Loading;