import { FC } from "react";

export interface FilterOptionsRowItem {
  label: string,
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => unknown,
}

const FilterOptionsRow: FC<{
  options?: FilterOptionsRowItem[],
  unsetAll?: FilterOptionsRowItem,
}> = ({
  options,
  unsetAll, 
}) => (
  <div className="flex gap-2 text-xs items-center">
    {!!unsetAll && (
      <div
        role="button"
        tabIndex={-1}
        className="flex items-center border-blue-600 border-[1px] text-blue-600 bg-white rounded-3xl py-2 px-3"
        onClick={(e) => unsetAll.onClick && unsetAll.onClick(e)}
      >
        {unsetAll.label}
      </div>)}
    {!!options?.length && options.map(({ label, onClick }, i) => (
      <div
        key={`${label}_${i}`}
        role="button"
        tabIndex={-1}
        className="flex items-center border-blue-600 border-[1px] text-white bg-blue-600 rounded-3xl py-2 px-3"
        onClick={(e) => onClick && onClick(e)}
      >
        {label}
      </div>
    ))}
  </div>
);


export default FilterOptionsRow;
