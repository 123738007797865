import randomIntFromInterval from "../../../utils/randomIntFromInterval";

export default function SearchInputListSkeloton({ rows = 3 } : { rows?: number }) {

  const string = (leftWidth = randomIntFromInterval(15, 85)): React.ReactNode => (
    <>
      <div style={{ width: `${leftWidth}%` }} className={` bg-gray-300 h-3 rounded-md`}> </div>
      <div style={{ width: `${100 - leftWidth}%` }} className={` bg-gray-300 h-3 rounded-md`}> </div>
    </>
  )

  return (
    <div className="w-full h-24 border-0 rounded-md mx-auto">
      <div className="flex flex-col gap-1 px-2 animate-pulse items-center h-full">        
        {[...Array(3)].map(() => <div key={Math.random()} className="flex gap-3 mx-6 my-2 w-full">{string()}</div>)}
      </div>
    </div>
  );
}