import { IMake } from '../model/IMake';
import api from './api';

export const vehicleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getYears: build.query<number[], {}>({
      query: () => ({
          url: 'inventory/vehicles/years'
        }),
      transformResponse: (response: string[]) => Array.isArray(response)
        ? response.map(Number)
        : response
    }),

    getMakes: build.query<IMake[], string>({
      query: (year) => ({
          url: `inventory/vehicles/byyear/${year}`
        }),
    }),

    getModels: build.query<IMake[], { year: string, makeId: string }>({
      query: ({ year, makeId }) => ({
          url: `inventory/vehicles/byyear/${year}/${makeId}`
        }),
    }),
    
  }),
});
