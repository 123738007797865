import { z } from "zod";
import { ERROR_MESSAGES } from "../messages/errorMessages";
import { getErrorFromCatch } from "../services/api";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { IAdminSettingsRequest, IUpdateAdminSettingsRequest } from "../model/IAdminSettings";
import { adminSettingsApi } from "../services/adminsettings.api";

export function useAdminSettings() {

  const schema = z.object({
    name: z
      .string()
      .min(1, ERROR_MESSAGES['setting-empty-name']),
    value: z
      .string()
      .min(1, ERROR_MESSAGES['setting-empty-value'])    
  } as Record<keyof IAdminSettingsRequest, any>);

  const [tryToAddSetting] = adminSettingsApi.useAddAdminSettingMutation();
  const [tryToUpdateSetting] = adminSettingsApi.useUpdateAdminSettingMutation();


  const updateSetting= async (settingstate: IUpdateAdminSettingsRequest): Promise<IZodErrorsState<IAdminSettingsRequest> | string | false> => {
    const errs = checkFormStateByZod(schema, settingstate);
    if (Object.values(errs)?.length) return errs;  

    try {
      await tryToUpdateSetting(settingstate).unwrap();
      return false;
    } catch (err) {      
      return getErrorFromCatch(err);
    }
  };

  const addSetting = async (settingstate: IAdminSettingsRequest): Promise<IZodErrorsState<IAdminSettingsRequest> | string | false> => {
    const errs = checkFormStateByZod(schema, settingstate);
    if (Object.values(errs)?.length) return errs;  
    try {
      await tryToAddSetting(settingstate).unwrap();
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };


  return {
    addSetting,
    updateSetting,
  };
}