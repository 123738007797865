import { useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOfferStockNumber, getOfferStockNumber, setOfferStockNumber } from "../../store/offerSlice";
import Button from "../form/Button";
import Input from "../form/Input";

// --
// -- Fill the interchange number of global offer state
// --
export default function EnterStock() {
  const dispatch = useTypedDispatch();

  const offerStock = useTypedSelector(getOfferStockNumber());
  const [localStockNumber, setLocalStockNumber] = 
    useState<IOfferStockNumber['stockNumber']>(() => offerStock.stockNumber || '');

  return (
    <div className="grid gap-2">
      <Input
        label="Stock/Part Number"
        name="stockNumber"
        value={localStockNumber}
        handle={(e) => setLocalStockNumber(() => e.target.value)}
      />
      <Button
        className="mt-2"
        onClick={() => 
          dispatch(setOfferStockNumber({
            ...offerStock,
            stockNumber: localStockNumber,
          }))
        }
      >
        Search
      </Button>
    </div>
  );
}
