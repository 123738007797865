import React from "react";

export default function arrayToNodeString (separator: React.ReactNode, arr: (React.ReactNode | undefined)[]): React.ReactNode {
  const clearedArr = arr.filter((item) => !!item);
  
  if (typeof separator === 'string')
    return clearedArr.join(separator);

  const keySalt = Math.random();
    
  return (
    <>
      {clearedArr.map((item, i) => i + 1 !== clearedArr.length
        ? <React.Fragment key={keySalt + i}>{item}{separator}</React.Fragment>
        : item
      )}
    </>
  );
}