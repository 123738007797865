import React, { FC, useEffect, useMemo } from "react";
import Input, { IInput, IInputOption, TInputEvent } from "../form/Input";

const WebSiteSearchField: FC<IInput> = (props) => {
  const currentOptions = useMemo(() => [
    // { value: '', title: 'Choose the site' },
    { value: 'test.automotix.net' },
    { value: 'automotix.net' },
    { value: 'allusedparts.com' },
  ] as IInputOption[], []);

  const { options, value, handle, ...otherProps } = props;

  useEffect(() => {
    !!!value && handle && handle({ target: { value : 'automotix.net' }} as TInputEvent);
  }, [])

  return <Input
    {...otherProps}
    options={currentOptions}
    value={value}
    handle={handle}
  />
};

export default WebSiteSearchField;
