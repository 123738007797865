import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IFilterState } from '../components/common/filter/Filter';
import { IPageParams } from '../services/IApi';
import { CONFIG } from '../config/config';
import { addDays, format } from 'date-fns';
import convertDateFromDateStr from '../utils/convertDateFromDateStr';
import convertDateStrFromDate from '../utils/convertDateStrFromDate';

export type TQuoteFilterFields =
| 'clientEmail'
| 'id'
| 'salesReps'
| 'clientPhone'
| 'websiteList'
| 'dateRange'
| 'createdFrom'
| 'createdTill';

export type TQuoteFilterState = IFilterState<Record<TQuoteFilterFields, string>>;

export interface IQuoteInitState {
  pageParams: IPageParams,
  filterParams: TQuoteFilterState,
}

// --
// -- 'yyyy-MM-dd:yyyy-MM-dd' -> [yyyy-MM-dd, yyyy-MM-dd + 1]
// --
const getFromAndTillByRange = (dateRange: string): [string, string] => {
  const dates = dateRange.split(':');
  const from = dates?.[0] || '';

  let till = '';
  const tillDate = convertDateFromDateStr(dates?.[1]);
  if (tillDate) {
    const tillDateIncrement = addDays(tillDate, 1);    
    till = convertDateStrFromDate(tillDateIncrement);
  }

  return [from, till];
}

const quoteSlice = createSlice({
  name: 'quoteSlice',
  
  initialState: {
    pageParams: {
      page: 0,
      size: CONFIG.quotes.quotesPerPage,
      q: '',
      sort: '',
      sortDir: 'asc',
    },
    filterParams: {
      clientEmail: '',
      id: '',
      salesReps: '',
      clientPhone: '',
      websiteList: '',
      dateRange: '', // 'yyyy-MM-dd:yyyy-MM-dd'
      createdFrom: '',
      createdTill: '',
    }
  } as {
    pageParams: IPageParams,
    filterParams: TQuoteFilterState,
  },

  reducers: {
    setQuotePageParams: (state: IQuoteInitState, action: PayloadAction<IPageParams>) => {
      state.pageParams = action.payload;
    },
    setQuoteFilterParams: (state: IQuoteInitState, action: PayloadAction<TQuoteFilterState>) => {
      state.filterParams = action.payload;
      // -- Get createdFrom and createdTill from dateRange 
      [state.filterParams.createdFrom = '', state.filterParams.createdTill = ''] = getFromAndTillByRange(action.payload.dateRange);
    },
  },
});

// Reducers
export default quoteSlice.reducer;
export const {
  setQuotePageParams,
  setQuoteFilterParams,
} = quoteSlice.actions;

// Selectors
export const getQuotePageParams = () => (state: RootState) => state.quoteSlice.pageParams;
export const getQuoteFilterParams = () => (state: RootState) => state.quoteSlice.filterParams;

export const getQuoteFilterCount = () => (state: RootState): number => Object.entries(state.quoteSlice.filterParams)
  .filter(([option, value]) => !!value.trim() && !['cretedTill', 'cretedFrom'].includes(option))
  .length;

